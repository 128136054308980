/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, ListItem, ListItemText, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';

import constants from 'helpers/constants';
import {sensorName} from 'helpers/sensor_names';
import type {Check} from 'types';

const PREFIX = 'Row';

const classes = {
  row: `${PREFIX}-row`,
  avatar: `${PREFIX}-avatar`,
  leftTextContainer: `${PREFIX}-leftTextContainer`,
  rightTextContainer: `${PREFIX}-rightTextContainer`,
  foundIconContainer: `${PREFIX}-foundIconContainer`,
  badge: `${PREFIX}-badge`,
  yellowBadge: `${PREFIX}-yellowBadge`,
  redBadge: `${PREFIX}-redBadge`,
  yellowNew: `${PREFIX}-yellowNew`,
  redNew: `${PREFIX}-redNew`,
  grayNew: `${PREFIX}-grayNew`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  successText: `${PREFIX}-successText`,
  foundText: `${PREFIX}-foundText`,
  newText: `${PREFIX}-newText`,
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.row}`]: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4),
    position: 'relative',
  },

  [`& .${classes.avatar}`]: {
    minWidth: 16,
  },

  [`& .${classes.leftTextContainer}`]: {
    minWidth: 'unset',
  },

  [`& .${classes.rightTextContainer}`]: {
    flexGrow: 0,
    minWidth: theme.spacing(19),
  },

  [`& .${classes.foundIconContainer}`]: {
    flexGrow: 0,
    minWidth: 'unset',
  },

  [`& .${classes.badge}`]: {
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: theme.spacing(1),
  },

  [`& .${classes.yellowBadge}`]: {
    backgroundColor: '#EFAE54',
  },

  [`& .${classes.redBadge}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.yellowNew}`]: {
    color: '#EFAE54',
  },

  [`& .${classes.redNew}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.grayNew}`]: {
    color: 'gray',
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: '22px',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },

  [`& .${classes.text}`]: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '17px',
  },

  [`& .${classes.successText}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.foundText}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.newText}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.backdrop}`]: {
    backgroundColor: theme.backdrop.main,
    position: 'absolute',
    zIndex: 1,
  },
}));

interface Props {
  check: Check;
  onClick: (check: Check) => void;
}

const Row = (props: Props) => {
  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const checkClassName = props.check.sensor.isError
    ? classes.redBadge
    : classes.yellowBadge;

  const newClassName =
    props.check.new > 0
      ? props.check.sensor.isError
        ? classes.redNew
        : classes.yellowNew
      : classes.grayNew;

  const newText = (check: Check) => {
    const count = check.new;

    if (check.sensor.isError) {
      return t('plurals.new_errors', {count});
    }

    return t('plurals.new_warnings', {count});
  };

  const foundText = (check: Check) => {
    return `${check.found}`;
  };

  const foundIconSrc = (() => {
    if (props.check.found === 0) {
      return new URL('./images/success_icon.png', import.meta.url).href;
    }

    if (props.check.sensor.isError) {
      return new URL('./images/error_icon.png', import.meta.url).href;
    }

    return new URL('./images/warning_icon.png', import.meta.url).href;
  })();

  return (
    <StyledBox position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <Box className={clsx(classes.badge, checkClassName)}>
          {constants.ZWSP}
        </Box>

        <ListItemText
          className={classes.leftTextContainer}
          disableTypography
          primary={
            <Typography className={classes.title}>
              {t(sensorName(props.check.sensor.name))}
            </Typography>
          }
        />

        <ListItemText
          className={classes.rightTextContainer}
          disableTypography
          primary={
            <Typography
              align='right'
              className={clsx(classes.text, classes.foundText)}
            >
              {foundText(props.check)}
            </Typography>
          }
          secondary={
            <Typography
              align='right'
              className={clsx(classes.text, newClassName)}
            >
              {newText(props.check)}
            </Typography>
          }
        />

        <ListItemText
          className={classes.foundIconContainer}
          disableTypography
          primary={
            <Box
              sx={{
                ml: 4,
              }}
            >
              <img alt='Found Icon' height='16' src={foundIconSrc} width='16' />
            </Box>
          }
        />
      </ListItem>
    </StyledBox>
  );
};

export default Row;
