/** @format */

import * as R from 'ramda';
import {getDaysInMonth} from 'date-fns';

import adTypeHelpers from 'helpers/adTypeHelpers';
import {snakeToCamel} from 'helpers/stringHelpers';

import type {AdType, AdTypeMediaplan, Platform, PlatformMediaplan} from 'types';

type maybeAdTypeMediaplan = AdTypeMediaplan | null;

const adTypeMediaplan = (platformPlan: PlatformMediaplan | null, adType: AdType, platform: Platform, date: Date): maybeAdTypeMediaplan => {
  if (platformPlan == null) {
    return null;
  }

  let platforms = adTypeHelpers.platformsFromAdType(adType);
  if (platform !== 'ALL') {
    platforms = R.filter(v => v === platform, platforms);
  }

  const withData = R.filter(v => !!platformPlan[snakeToCamel(v.toLowerCase())], platforms)
  if (withData.length === 0) {
    return null;
  }

  const days = getDaysInMonth(date);

  const monthly = R.sum(withData.map(v => platformPlan[snakeToCamel(v.toLowerCase())] || 0));
  const dayly = monthly / days;

  return {monthly, dayly};
};

export default {
  adTypeMediaplan,
};
