/** @format */
import {useMemo} from 'react';

import {styled} from '@mui/material/styles';

import clsx from 'clsx';
import {Box} from '@mui/material';
import {ErrorOutline, HelpOutline, WatchLater} from '@mui/icons-material';
import {PieChart, Pie, ResponsiveContainer} from 'recharts';

import {
  GOOD_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/mediaplanBreakpoints';

const PREFIX = 'PlanFactPie';

const classes = {
  container: `${PREFIX}-container`,
  iconContainer: `${PREFIX}-iconContainer`,
  icon: `${PREFIX}-icon`,
  warningIcon: `${PREFIX}-warningIcon`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.container}`]: {
    position: 'relative',
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.icon}`]: {
    width: 30,
    height: 30,
    color: '#4B4B4B',
  },

  [`& .${classes.warningIcon}`]: {
    color: '#CE5959',
  },
}));

type Props = {
  plan?: number;
  fact?: number;
  hasWarning?: boolean;
  isInProgress?: boolean;
};

const getColorByPercentage = (value: number) => {
  if (value >= GOOD_BREAKPOINT) {
    return '#677C41';
  }

  if (value >= UNOPTIMAL_BREAKPOINT) {
    return '#AA7F53';
  }

  return '#CE8080';
};

const PlanFactPie = (props: Props) => {
  const {plan = 1, fact = 1, hasWarning, isInProgress} = props;

  const hasNoData = props.plan === undefined;

  const diff = plan - fact;

  const chartColor = useMemo(() => {
    const percentage = (diff / plan) * 100;

    return getColorByPercentage(percentage);
  }, [diff, plan]);

  const dataBad = [
    {uv: plan, fill: '#484848', stroke: '#484848', opacity: 0.3},
    {
      uv: Math.abs(diff),
      fill: '#CE5959',
      stroke: '#CE5959',
      opacity: 1,
      cornerRadius: 24,
    },
  ];
  const dataGood = [
    {uv: fact, fill: '#484848', stroke: '#484848', opacity: 0.3},
    {
      uv: Math.abs(diff),
      fill: chartColor,
      stroke: chartColor,
      opacity: 1,
      cornerRadius: 24,
    },
  ];

  const isGood = diff >= 0;
  const data = isGood ? dataGood : dataBad;

  const statusIcon = () => {
    if (hasWarning) {
      return (
        <div className={classes.iconContainer}>
          <ErrorOutline className={clsx(classes.icon, classes.warningIcon)} />
        </div>
      );
    }

    if (isInProgress) {
      return (
        <div className={classes.iconContainer}>
          <WatchLater className={classes.icon} />
        </div>
      );
    }

    if (hasNoData) {
      return (
        <div className={classes.iconContainer}>
          <HelpOutline className={classes.icon} />
        </div>
      );
    }

    return null;
  };

  return (
    <StyledBox className={classes.container}>
      {statusIcon()}
      <ResponsiveContainer width={90} height={90}>
        <PieChart width={90} height={90}>
          <Pie
            begin={20}
            startAngle={isGood ? 90 : -270}
            endAngle={isGood ? -270 : 90}
            data={data}
            innerRadius={37}
            outerRadius={45}
            legendType='none'
            dataKey='uv'
            radius={60}
          />
        </PieChart>
      </ResponsiveContainer>
    </StyledBox>
  );
};

export default PlanFactPie;
