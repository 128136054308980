/** @format */

import {ChangeEvent, useState} from 'react';

import {Box, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import OutlinedLargeButton from 'components/buttons/OutlinedLargeButton';

import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useLocation, useNavigate} from 'react-router-dom';

import ArrowBack from 'components/navigation/ArrowBack';
import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';
import {useAuthContext} from 'contexts/AuthContext';

const CODE_LENGTH = 6;

const VERIFY_CODE = gql`
  mutation VerifyCode($code: String!) {
    verifyCode(code: $code) {
      token
      user {
        id
        uuid
        phone
        roles
        insertedAt
        profile {
          name
        }
        firstCompany {
          id
          uuid
          name
          tokens {
            id
            provider
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.primary,
    },
    body: {
      ...theme.typography.body1,
      color: '#B3B3B3',
    },
    verifyButton: {
      color: '#fff',
      background: '#121212',
      '&:hover': {
        background: '#121212',
      },
    },
  }),
);

const VerifyCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const authContext = useAuthContext();

  const classes = useStyles();

  const {t} = useTranslation();

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [verifyCode, {loading: verifyCodeLoading}] = useMutation(VERIFY_CODE);

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleContinue = async () => {
    try {
      const {data} = await verifyCode({variables: {code}});
      await authContext.signIn(data.verifyCode);
      const {from} = location.state || {from: {pathname: '/home/main'}};
      navigate(from, {replace: true});
    } catch (e) {
      setCodeError((e as Error).message);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box mb={4}>
        <ArrowBack onClick={handleClickBack}>
          <Trans>Авторизация</Trans>
        </ArrowBack>
      </Box>
      <Box mt={4}>
        <Typography variant='h1' className={classes.text}>
          <Trans>Подтверждение аккаунта</Trans>
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography className={classes.body}>
          <Trans>
            Введите 6-ти значный код из приложения для двухфакторной
            аутентификации.
          </Trans>
        </Typography>
      </Box>

      <Box mt={6}>
        <LegacyTextFieldFilled
          error={Boolean(codeError)}
          helperText={codeError}
          inputProps={{maxLength: CODE_LENGTH}}
          myProps={{placeholderFocused: ''}}
          placeholder={t('Код аутентификации', 'Код аутентификации')}
          value={code}
          onChange={handleChangeCode}
        />
      </Box>

      <Box mt={9}>
        <OutlinedLargeButton
          disabled={verifyCodeLoading || !code || code.length !== CODE_LENGTH}
          onClick={handleContinue}
          fullWidth
          className={classes.verifyButton}
        >
          <Trans>Продолжить</Trans>
        </OutlinedLargeButton>
      </Box>
    </>
  );
};

export default VerifyCode;
