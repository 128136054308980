/** @format */

import {useTranslation} from 'react-i18next';

import DateGraph from 'components/graphs/DateGraph';
import numberHelpers from 'helpers/numberHelpers';
import type {PlatformGraphStat} from 'types';

type Props = {
  platformGraphStats: PlatformGraphStat[];
  planValue?: number;
};

const SpendGraph = (props: Props) => {
  const {i18n} = useTranslation();
  const currency = () => {
    if (props.platformGraphStats.length === 0) {
      return '';
    }
    return props.platformGraphStats[0].currency;
  };

  const formatter = numberHelpers.buildFormatter(currency(), i18n.language);
  const formatYValue = (value: number) => formatter.format(value);

  const graphData = props.platformGraphStats.map(v => ({
    dateValue: v.date,
    yValue: v.spent,
  }));

  return (
    <DateGraph
      data={graphData}
      formatYValue={formatYValue}
      inverted={false}
      showAnomalies
      variant='SLIM'
      planValue={props.planValue}
    />
  );
};

export default SpendGraph;
