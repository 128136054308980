/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import ConnectedProviders from 'components/icons/ConnectedProviders';
import Plate from './Plate';
import {AdType} from 'types';

interface companyInterface {
  id: string
  tokens: any
}

interface Props {
  company: companyInterface
  adType: AdType
  currentCompany: companyInterface
  currentAdType: AdType
  onClick: () => void
}

const useStyles = makeStyles(theme => ({
  plateText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '17px',
  },
  selectedPlateText: {
    color: '#333333',
  },
  scoreText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '16px',
    marginTop: theme.spacing(1),
  },
  selectedScoreText: {
    color: '#333333',
  }
}));

const ConnectedPlate = (props: Props) => {
  const classes = useStyles();

  const isCurrentCompany = props.company.id === props.currentCompany.id;
  const isCurrentAdType = props.currentAdType === props.adType;
  const isCurrent = isCurrentCompany && isCurrentAdType;

  return (
    <Plate
      selected={isCurrent}
      onClick={props.onClick}
    >
      <Box
        alignItems='center'
        display='flex'
        flexGrow={1}
        justifyContent='space-between'
      >
        <Typography
          className={clsx(classes.plateText, {
            [classes.selectedPlateText]: isCurrent,
          })}
        >
          {props.adType === 'CONTEXT' ? (
            <Trans>Контекст</Trans>
          ) : (
            <Trans>Таргет</Trans>
          )}
        </Typography>

        <ConnectedProviders
          adTypes={[props.adType]}
          disabled={!isCurrent}
          size={20}
          tokens={props.company.tokens}
        />
      </Box>
    </Plate>
  );
};

export default ConnectedPlate;
