/** @format */

import {useEffect, useMemo, useState} from 'react';

import {styled} from '@mui/material/styles';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, CircularProgress, Typography, useMediaQuery} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {InsertChartOutlined, Tune} from '@mui/icons-material';
import {Trans, useTranslation} from 'react-i18next';
import {add, format, getDaysInMonth} from 'date-fns';
import {gql, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {clsx} from 'clsx';

import PlatformTabs from 'components/tabs/PlatformTabs';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import mediaplanHelpers from 'helpers/mediaplanHelpers';
import statsHelpers from 'helpers/statsHelpers';
import type {
  ExtendedPlatform,
  Platform,
  PlatformType,
  Stat,
  WarningsType,
} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';
import PlanFactPie from 'components/graphs/PlanFactPie';
import {snakeToCamel} from 'helpers/stringHelpers';

import Spent from './Spent';
import Error from './Error';
import Footer from './Footer';
import Graphs from './Graphs';
import InvalidToken from './InvalidToken';
import No2FA from './No2FA';
import PeriodStats from './PeriodStats';

const PREFIX = 'Dashboard';

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
  statsPlate: `${PREFIX}-statsPlate`,
  icon: `${PREFIX}-icon`,
  redButton: `${PREFIX}-redButton`,
  progress: `${PREFIX}-progress`,
  fullWidth: `${PREFIX}-fullWidth`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.loadingContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    // Setting height is required for onboarding scrolling to work correctly
    [theme.breakpoints.down('sm')]: {
      height: 237,
    },
    [theme.breakpoints.up('sm')]: {
      height: 233,
    },
  },

  [`& .${classes.statsPlate}`]: {
    backgroundColor: '#202020',
    borderRadius: 8,
  },

  [`& .${classes.icon}`]: {
    width: 20,
  },

  [`& .${classes.redButton}`]: {
    color: '#CE8080',
    borderColor: '#CE8080',
  },

  [`& .${classes.progress}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  },
}));

const SEARCH_NETWORK_PLATFORMS = ['GOOGLE', 'YANDEX'];

const GET_CURRENT_USER = gql`
  query GetCurrentUser($date: Date!) {
    currentUser {
      currentCompany {
        id
        stats(date: $date) {
          currency
          platform
          extendedPlatform
          state
          monthSpent
          spent
          impressions
          clicks
          cpc
          ctr
        }
        mediaplan {
          yandex
          google
          vkontakte
          facebook
          vkontakteAds
        }
      }
    }
  }
`;

type Props = {
  showExtendedStats?: boolean;
  showGraphs?: boolean;
  showSearchNetwork?: boolean;
};

const Dashboard = (props: Props) => {
  const {
    showSearchNetwork = false,
    showExtendedStats = false,
    showGraphs = false,
  } = props;

  const navigate = useNavigate();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const {t} = useTranslation();

  const {currentAdType} = useCompanyContext();

  const [activePlatform, setActivePlatform] = useState<Platform>('ALL');
  const [activeExtendedPlatform, setActiveExtendedPlatform] =
    useState<ExtendedPlatform>('ALL');
  const [activePlatformType, setActivePlatformType] =
    useState<PlatformType>('ALL');

  const yesterday = add(new Date(), {days: -1});
  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {date: format(yesterday, 'yyyy-MM-dd')},
  });

  const allStats = statsHelpers.filterStatsForDashboard(
    data?.currentUser?.currentCompany?.stats || [],
    currentAdType || 'CONTEXT',
  );

  const uniquePlatforms = R.uniq(allStats.map(v => v.platform)).filter(
    v => v !== 'ALL' && v !== 'FACEBOOK',
  );

  const allPlatforms: Platform[] = useMemo(
    () =>
      uniquePlatforms.length === 1
        ? uniquePlatforms
        : [...uniquePlatforms, 'ALL'],
    [uniquePlatforms],
  );

  useEffect(() => {
    if (allPlatforms.length === 1) {
      setActivePlatform(allPlatforms[0]);

      const newActiveExtendedPlatform = allStats.find(
        item => item.platform === allPlatforms[0],
      )?.extendedPlatform;

      if (newActiveExtendedPlatform) {
        setActiveExtendedPlatform(newActiveExtendedPlatform);
      }
    }
  }, [allPlatforms, allStats]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const platformStat = allStats.find(
    v => v.extendedPlatform === activeExtendedPlatform,
  ) as Stat;

  const mediaplan = mediaplanHelpers.adTypeMediaplan(
    data.currentUser.currentCompany.mediaplan,
    currentAdType,
    activePlatform,
    yesterday,
  );

  const days = getDaysInMonth(yesterday);

  const mediaplanData = data.currentUser.currentCompany.mediaplan;

  const platformsWarnings: WarningsType = mediaplanData
    ? Object.entries(mediaplanData).reduce((acc, [key, value]) => {
        const statsData = allStats.find(
          ({platform}) => snakeToCamel(platform.toLowerCase()) === key,
        );

        if (!statsData || !value) {
          return acc;
        }

        const monthValue = value as number;
        const dayValue = monthValue / days;

        const monthDiff = monthValue - (statsData?.monthSpent || 0);
        const dayDiff = dayValue - (statsData?.spent || 0);

        const hasWarnings = monthDiff < 0 || dayDiff < 0;

        return {
          ...acc,
          [key]: {
            hasWarnings,
            monthValue,
            dayValue,
          },
        };
      }, {})
    : {};

  console.log(platformsWarnings);

  const allStatsData = allStats.find(({platform}) => platform === 'ALL');

  const allPlatformsWarnings = Object.entries(platformsWarnings).reduce(
    (acc, [_, value]) => {
      const monthValue = acc.monthValue + value.monthValue;
      const dayValue = acc.dayValue + value.dayValue;

      const hasMonthWarning = monthValue - (allStatsData?.monthSpent || 0) < 0;
      const hasDayWarning = dayValue - (allStatsData?.spent || 0) < 0;

      return {
        monthValue,
        dayValue,
        hasWarnings: hasMonthWarning || hasDayWarning,
      };
    },
    {
      hasWarnings: false,
      monthValue: 0,
      dayValue: 0,
    },
  );

  const warningsData: WarningsType = {
    ...platformsWarnings,
    all: allPlatformsWarnings,
  };

  const handleChangePlatform = (platform: Platform) => {
    setActivePlatform(platform);
    setActiveExtendedPlatform(platform);
    setActivePlatformType('ALL');
  };

  const renderPlatformTypes = () => {
    if (!SEARCH_NETWORK_PLATFORMS.includes(activePlatform)) return null;

    const renderButton = (platformType: PlatformType, index: number) => {
      const handleClick = () => {
        if (platformType === 'ALL') {
          setActiveExtendedPlatform(activePlatform);
        } else {
          const newExtendedPlatform =
            `${activePlatform}_${platformType}` as ExtendedPlatform;
          setActiveExtendedPlatform(newExtendedPlatform);
        }

        setActivePlatformType(platformType);
      };

      const platformTypeHumanNames = {
        ALL: t('Все', 'Все'),
        SEARCH: t('Поиск', 'Поиск'),
        NETWORK: t('Сети', 'Сети'),
      };

      if (platformType === activePlatformType) {
        return (
          <Box
            key={index}
            sx={{
              px: 1,
            }}
          >
            <PrimarySmallButton onClick={handleClick}>
              {platformTypeHumanNames[platformType]}
            </PrimarySmallButton>
          </Box>
        );
      }

      return (
        <Box
          key={index}
          sx={{
            px: 1,
          }}
        >
          <TransparentSmallButton onClick={handleClick}>
            {platformTypeHumanNames[platformType]}
          </TransparentSmallButton>
        </Box>
      );
    };

    return (
      <Box
        sx={{
          display: 'flex',
          mx: -1,
        }}
      >
        {(['ALL', 'SEARCH', 'NETWORK'] as PlatformType[]).map((v, i) =>
          renderButton(v, i),
        )}
      </Box>
    );
  };

  const isNotReady = platformStat.state === 'NOT_READY';

  const renderContent = () => {
    if (platformStat.state === 'INVALID_TOKEN') {
      return <InvalidToken />;
    }

    if (isNotReady) {
      return (
        <div className={classes.progress}>
          <PlanFactPie isInProgress />
        </div>
      );
    }

    if (platformStat.state === 'NO_ACTIVE_ACCOUNTS') {
      return (
        <Error
          text={
            <Trans>
              Упс, у вас нет активных аккаунтов в кабинете Яндекс Директ /
              Google Ads / ВКонтакте.
              <br />
              AdSensor проверяет только аккаунты с запущенными рекламными
              кампаниями. Проверьте, верный ли кабинет вы подключили или
              добавьте в AdSensor другой.
            </Trans>
          }
        />
      );
    }

    if (platformStat.state === 'NO_2FA') {
      return <No2FA />;
    }

    if (platformStat.currency === 'mixed') {
      return (
        <Box>
          <Typography>
            <Trans>
              Подключенны аккаунты с разными валютами.
              <br />
              Для того чтобы увидеть статистику оставьте аккаунты только в одной
              валюте.
            </Trans>
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Spent
          platformStat={platformStat}
          mediaplan={
            activeExtendedPlatform === activePlatform ? mediaplan : null
          }
          date={yesterday}
        />
      </Box>
    );
  };

  const handleClickMediaplan = () => {
    navigate(
      {
        pathname: '/home/settings/mediaplan',
        search: `?companyId=${data.currentUser.currentCompany.id}&adType=${currentAdType}`,
      },
      {
        state: {background: '/home/settings'},
      },
    );
  };

  const handleClickBusinessMetrics = () => {
    navigate('/home/main/business-metrics');
  };

  return (
    <StyledBox
      sx={{
        mt: 7,
      }}
    >
      <Box
        className={classes.statsPlate}
        sx={{
          px: 4,
          pb: 4,
          pt: 4,
        }}
      >
        <PlatformTabs
          activePlatform={activePlatform}
          allPlatforms={allPlatforms}
          warnings={warningsData}
          onChange={handleChangePlatform}
        />

        {showSearchNetwork && (
          <Box
            sx={{
              mt: 6,
            }}
          >
            {renderPlatformTypes()}
          </Box>
        )}

        <Box
          sx={{
            mt: 4.5,
          }}
        >
          {renderContent()}
        </Box>
        <Box
          sx={{
            flexDirection: isTablet ? 'column' : 'row',
            display: 'flex',
            justifyContent: 'space-between',
            mt: 5.5,
            rowGap: 16,
          }}
        >
          <TransparentSmallButton
            className={clsx(
              !mediaplan && classes.redButton,
              isNotReady && classes.fullWidth,
            )}
            onClick={handleClickMediaplan}
          >
            <Tune className={classes.icon} />
            <Box
              sx={{
                ml: 2,
              }}
            >
              <Trans>Параметры медиаплана</Trans>
            </Box>
          </TransparentSmallButton>
          {!showExtendedStats && !isNotReady && (
            <TransparentSmallButton onClick={handleClickBusinessMetrics}>
              <InsertChartOutlined className={classes.icon} />

              <Box
                sx={{
                  ml: 2,
                }}
              >
                <Trans>Бизнес показатели</Trans>
              </Box>
            </TransparentSmallButton>
          )}
        </Box>
      </Box>
      {platformStat.state === 'READY' && platformStat.currency === 'RUB' && (
        <Footer />
      )}
      {showGraphs && (
        <Box
          sx={{
            mt: 10,
          }}
        >
          <Graphs
            extendedPlatform={activeExtendedPlatform}
            mediaplan={
              activeExtendedPlatform === activePlatform ? mediaplan : null
            }
          />
        </Box>
      )}
      {showExtendedStats && (
        <Box
          sx={{
            mt: 10,
          }}
        >
          <PeriodStats extendedPlatform={activeExtendedPlatform} />
        </Box>
      )}
    </StyledBox>
  );
};

export default Dashboard;
