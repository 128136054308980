/** @format */
import CloseIcon from '@mui/icons-material/Close';
import {Box, Hidden, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';

import Hr from 'components/SidebarMenu/Hr';
import MenuItemImage from 'components/SidebarMenu/MenuItemImage';
import MenuItemLink from 'components/SidebarMenu/MenuItemLink';
import callGreen from './images/call_green.png';
import callGrey from './images/call_grey.png';
import externalLink from './images/external_link.png';
import logoDesktopImg from 'images/logo_desktop.png';
import logoMobileImg from 'images/logo_mobile.png';
import messageGreen from './images/message_green.png';
import messageGrey from './images/message_grey.png';
import plusGreen from './images/plus_green.png';
import plusGrey from './images/plus_grey.png';
import routerHelpers from 'helpers/routerHelpers';
import youtubeLogo from './images/youtube.png';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface Props {
  showVideoPreview: boolean;
  onClickPreview: () => void;
  onToggleDrawer?: () => void;
}

const Sidebar = (props: Props) => {
  const {t} = useTranslation();
  const {mixpanel} = useMixpanelContext();

  const {currentCompany} = useCompanyContext();

  const renderHeader = () => {
    return (<>
      <Hidden mdUp>
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
        >
          <img alt='AdSensor logo' height='26' src={logoMobileImg} width='115'/>
          <IconButton color='inherit' edge='start' onClick={props.onToggleDrawer} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box display='flex' justifyContent='center' mt={5}>
          <img alt='AdSensor logo' height='30' src={logoDesktopImg} width='136'/>
        </Box>
      </Hidden>
    </>);
  };

  const sendDataLayerLinkEvent = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.dataLayer) {
      const link = event.currentTarget.href.replace('https://', '');
      window.dataLayer.push({event: `app_link_${link}`});
    }
  };

  const handleClickSelfConnect = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onToggleDrawer) {
      props.onToggleDrawer();
    }
    sendDataLayerLinkEvent(event);
    mixpanel.track('wizard_open_self_connect');
  };

  const handleClickInviteGuest = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onToggleDrawer) {
      props.onToggleDrawer();
    }
    sendDataLayerLinkEvent(event);
    mixpanel.track('wizard_open_invite_guest');
  };

  const handleClickNeedHelp = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onToggleDrawer) {
      props.onToggleDrawer();
    }
    sendDataLayerLinkEvent(event);
    mixpanel.track('wizard_open_need_help');
  };

  const handleClickHowTo = (event: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataLayerLinkEvent(event);
    mixpanel.track('wizard_open_external_how_to');
  };

  const handleClickSensors = (event: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataLayerLinkEvent(event);
    mixpanel.track('wizard_open_external_sensors');
  };

  const handleClickVideo = () => {
    if (props.onToggleDrawer) {
      props.onToggleDrawer();
    }
    if (window.dataLayer) {
      window.dataLayer.push({event: 'app_click_video'});
    }

    props.onClickPreview();
    mixpanel.track('wizard_open_video');
  };

  return (
    <Box display='flex' flexDirection='column' pl={3} pr={3} pb={5}>
      {renderHeader()}
      <Box mt={18} display='flex' flexDirection='column'>
        {currentCompany && (
          <>
            <MenuItemLink
              text={t('Самостоятельное подключение')}
              to={{
                pathname: '/wizard/connect',
                search: `?companyId=${currentCompany.id}`
              }}
              activeImg={plusGreen}
              inactiveImg={plusGrey}
              onClick={handleClickSelfConnect}
            />
            <MenuItemLink
              text={t('Запрос доступов у специалиста')}
              to={{
                pathname: '/wizard/invite_guest',
                search: `?companyId=${currentCompany.id}`
              }}
              activeImg={messageGreen}
              inactiveImg={messageGrey}
              onClick={handleClickInviteGuest}
            />
          </>
        )}
        <MenuItemLink
          text={t('Нужна помощь')}
          to={{pathname: '/wizard/help'}}
          activeImg={callGreen}
          inactiveImg={callGrey}
          onClick={handleClickNeedHelp}
        />
        {routerHelpers.IsRuVersion() && (
          <>
            <Box mx={3}>
              <Hr />
            </Box>
            <MenuItemLink
              text={t('Справочные\nматериалы')}
              to={{pathname: 'https://adsensor.ru/instructions'}}
              openBlank
              activeImg={externalLink}
              inactiveImg={externalLink}
              onClick={handleClickHowTo}
            />
            <MenuItemLink
              text={t('Для специалистов')}
              to={{pathname: 'https://adsensor.ru/all-sensors'}}
              openBlank
              activeImg={externalLink}
              inactiveImg={externalLink}
              onClick={handleClickSensors}
            />
          </>
        )}
        {props.showVideoPreview && (
          <>
            <Box mx={3}>
              <Hr />
            </Box>
            <MenuItemImage
              text={t('С чего начать знакомство с AdSensor')}
              imageSrc={youtubeLogo}
              onClick={handleClickVideo}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
