/** @format */

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Row from './Row';
import RowNew from './RowNew';

const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    borderRadius: '50%',
    height: theme.spacing(18),
    overflow: 'hidden',
    width: theme.spacing(18),
  },
  avatarIcon: {
    backgroundColor: '#FFF',
    fontSize: 118,
  },
  list: {
    width: theme.spacing(72),
  },
  listItem: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    color: 'white',
    height: 56,
    marginBottom: theme.spacing(4),
  },
  logout: {
    cursor: 'pointer',
  },
  logoutIcon: {
    marginRight: -5,
  },

  headerTitle: {
    fontSize: '28px',
    fontWeight: 700,
  },
  listNew: {
    width: '100%',
  },
}));

const settingsLinksList = [
  {
    href: '/home/settings/profile',
    mixpanelEventName: 'open_profile_page',
    icon: <AccountCircleOutlinedIcon />,
    title: <Trans>Настройки аккаунта</Trans>,
  },
  {
    href: '/home/settings/connect',
    mixpanelEventName: 'open_connect_page',
    icon: <AppsIcon />,
    title: <Trans>Управление проектами</Trans>,
  },
  {
    href: '/home/settings/connected_users',
    mixpanelEventName: 'open_connected_users_page',
    icon: <SecurityOutlinedIcon />,
    title: <Trans>Управление доступами</Trans>,
  },
  {
    href: '/home/settings/specialists',
    mixpanelEventName: 'open_specialists_page',
    icon: <NotificationsNoneOutlinedIcon />,
    title: <Trans>Настройка уведомлений</Trans>,
  },
];

const Index = () => {
  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();

  const {isNewVersionEnabled} = useFlags();

  const classes = useStyles();

  const [signOut, {error: signOutError}] = useMutation(SIGN_OUT);

  if (signOutError) {
    return <Alert severity='error'>{signOutError.message}</Alert>;
  }

  const handleLogout = async () => {
    mixpanel.track('click_sign_out_button');

    try {
      await signOut();
      await authContext.signOut();
      // NOTE: temporary replace to explicit page reload for avoiding switch theme bug
      // history.push('/');
      location.replace('/');
    } catch (e) {
      console.error(e);
    }
  };

  return isNewVersionEnabled ? (
    <Box mt={8}>
      <Typography className={classes.headerTitle}>
        <Trans>Настройки</Trans>
      </Typography>

      <Box mt={6}>
        <List className={classes.listNew} disablePadding>
          {settingsLinksList.map((v, index) => (
            <RowNew
              key={index}
              link={v.href}
              mixpanelEventName={v.mixpanelEventName}
              icon={v.icon}
            >
              {v.title}
            </RowNew>
          ))}
        </List>
      </Box>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box>
        <Avatar alt='Avatar' className={classes.avatar}>
          <AccountCircleIcon className={classes.avatarIcon} color='disabled' />
        </Avatar>
      </Box>

      <Box mt={10}>
        <List className={classes.list} disablePadding>
          <Row
            link='/home/settings/profile'
            mixpanelEventName='open_profile_page'
          >
            <Trans>Персональные данные</Trans>
          </Row>
          <Row
            link='/home/settings/two-factor-auth'
            mixpanelEventName='open_2fa_page'
          >
            <Trans>Безопасность</Trans>
          </Row>
          <Row
            link='/home/settings/connect'
            mixpanelEventName='open_connect_page'
          >
            <Trans>Управление проектами</Trans>
          </Row>
          <Row
            link='/home/settings/connected_users'
            mixpanelEventName='open_connected_users_page'
          >
            <Trans>Управление доступами</Trans>
          </Row>
          <Row
            link='/home/settings/specialists'
            mixpanelEventName='open_specialists_page'
          >
            <Trans>Настройка уведомлений</Trans>
          </Row>
          {false && (
            <Row
              link='/home/settings/subscription'
              mixpanelEventName='open_subscription_page'
            >
              <Trans>Подписка</Trans>
            </Row>
          )}
          {false && (
            <Row
              link='/home/settings/payment-methods'
              mixpanelEventName='open_payment_methods_page'
            >
              <Trans>Платёжные данные</Trans>
            </Row>
          )}
          {false && (
            <Row
              link='/home/settings/exceptions'
              mixpanelEventName='open_exceptions_page'
            >
              <Trans>Исключения</Trans>
            </Row>
          )}

          <ListItem
            className={clsx([classes.listItem, classes.logout])}
            onClick={handleLogout}
          >
            <ListItemText primaryTypographyProps={{variant: 'body2'}}>
              <Trans>Выйти из профиля</Trans>
            </ListItemText>
            <img
              alt='Logout'
              className={classes.logoutIcon}
              src={new URL('./images/logout.svg', import.meta.url)}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Index;
