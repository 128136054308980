/** @format */

import {ChangeEvent} from 'react';

import {styled} from '@mui/material/styles';

import clsx from 'clsx';
import {Box, MenuItem, Select} from '@mui/material';

const PREFIX = 'SecondarySelect';

const classes = {
  select: `${PREFIX}-select`,
  menuItem: `${PREFIX}-menuItem`,
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.select}`]: {
    flexGrow: 1,
    width: (props: Props) => props.width,
    color: theme.palette.text.primary,
    '& .MuiSelect-select': {
      minHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      border: '1px solid #DADADA',
      backgroundColor: theme.palette.secondary.main,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    '& .MuiSelect-icon': {
      right: 6,
    },
  },

  [`& .${classes.menuItem}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
    '&.Mui-selected': {
      backgroundColor: '#E0E0E0',
    },
  },

  [`& .${classes.icon}`]: {
    position: 'absolute',
    left: '16px',
    top: '14px',
    bottom: 0,
    margin: 'auto',
    height: '20px',
  },
}));

type Option = {
  value: string;
  text: string;
};

type Props = {
  disabled?: boolean;
  options: Option[];
  selectClassName?: string;
  icon?: React.ReactNode;
  value: string;
  placeholder?: string | null;
  width?: number;
  onSelect: (value: string) => void;
  'aria-label'?: string;
};

const SecondarySelect = (props: Props) => {
  const {disabled = false} = props;

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  return (
    <StyledBox display='flex' position='relative'>
      {props.icon && <Box className={classes.icon}>{props.icon} </Box>}
      <Select
        className={clsx(classes.select, props.selectClassName)}
        disableUnderline
        disabled={disabled}
        value={props.value}
        placeholder={props.placeholder || undefined}
        aria-label={props['aria-label']}
        // @ts-ignore
        onChange={handleChange}
      >
        {props.options.map((v, i) => (
          <MenuItem
            key={i}
            className={classes.menuItem}
            value={v.value}
            data-testid={'option'}
          >
            {v.text}
          </MenuItem>
        ))}
      </Select>
    </StyledBox>
  );
};

export default SecondarySelect;
