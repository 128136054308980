/** @format */

import * as R from 'ramda';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import miscHelpers from 'helpers/miscHelpers';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
  },
  body: {
    color: '#CCCCCC',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
  },
}));

const Info = props => {
  const classes = useStyles();

  if (R.isEmpty(props.totalStats)) return null;

  const renderRow = text => {
    return (
      <Box alignItems='flex-start' display='flex'>
        <Box mr={2}>
          <img
            alt='Checkmark'
            height='8'
            src={new URL('./images/checkmark.png', import.meta.url)}
            width='7'
          />
        </Box>
        <Typography className={classes.body}>{text}</Typography>
      </Box>
    );
  };

  return (
    <Box mt={4}>
      <Typography className={classes.title} variant='body2'>
        {props.title}
      </Typography>

      <Box display='flex' flexDirection='column' mt={3}>
        {renderRow(
          `${props.companiesCount} ${miscHelpers.inflectNoun(
            props.companiesCount,
            'проект',
            'проекта',
            'проектов',
          )}`,
        )}
        {renderRow(
          `${props.totalStats.accounts} ${miscHelpers.inflectNoun(
            props.totalStats.accounts,
            'аккаунт',
            'аккаунта',
            'аккаунтов',
          )}`,
        )}
        {renderRow(
          `${props.totalStats.campaigns} ${miscHelpers.inflectNoun(
            props.totalStats.campaigns,
            'кампанию',
            'кампании',
            'кампаний',
          )}`,
        )}
        {renderRow(
          `${props.totalStats.ads} ${miscHelpers.inflectNoun(
            props.totalStats.ads,
            'объявление',
            'объявления',
            'объявлений',
          )}`,
        )}
      </Box>
    </Box>
  );
};

export default Info;
