/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, ButtonBase, Grid} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'NativeMenuNew';

const classes = {
  gridItem: `${PREFIX}-gridItem`,
  item: `${PREFIX}-item`,
  activeItem: `${PREFIX}-activeItem`,
  menuItemLogo: `${PREFIX}-menuItemLogo`,
  menuItemActiveLogo: `${PREFIX}-menuItemActiveLogo`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.gridItem}`]: {
    [theme.breakpoints.up('md')]: {
      '& > div:last-of-type': {
        paddingRight: 0,
      },
    },
  },

  [`& .${classes.item}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '17px',
  },

  [`& .${classes.activeItem}`]: {
    color: '#AE5658',
  },

  [`& .${classes.menuItemLogo}`]: {
    fill: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },

  [`& .${classes.menuItemActiveLogo}`]: {
    fill: '#AE5658',
  },
}));

export type Item = {
  path: string;
  icon?: React.ReactChild;
  onClick?: () => void;
  name: string;
  text: string;
};

interface Props {
  className?: string;
  activeItem?: Item;
  leftItems?: Item[];
  rightItems: Item[];
}

const NativeMenuNew = (props: Props) => {
  const {mixpanel} = useMixpanelContext();

  const handleClick = (name: string, onClick?: () => void) => () => {
    const lcName = name.toLowerCase();
    mixpanel.track(`click_${lcName}_top_menu`);

    onClick && onClick();
  };

  const renderItems = (items: Item[], alignRight = false) => (
    <Grid
      container
      spacing={8}
      className={clsx(props.className, classes.gridItem)}
      sx={{
        justifyContent: alignRight ? 'flex-end' : 'flex-start',
      }}
    >
      {items.map((v, i) => {
        const isActiveItem = props.activeItem
          ? props.activeItem.name === v.name
          : false;

        const className = clsx(classes.item, {
          [classes.activeItem]: isActiveItem,
        });

        return (
          <Grid key={i} item>
            <ButtonBase
              className={className}
              component={RouterLink}
              disableRipple
              to={v.path}
              onClick={handleClick(v.name, v.onClick)}
            >
              {v.icon && (
                <span
                  className={clsx(
                    classes.menuItemLogo,
                    isActiveItem && classes.menuItemActiveLogo,
                  )}
                >
                  {v.icon}
                </span>
              )}
              {v.text}
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <StyledBox display='flex' justifyContent='space-between' width={'100%'}>
      {props.leftItems && renderItems(props.leftItems)}
      {props.rightItems && renderItems(props.rightItems, true)}
    </StyledBox>
  );
};

export default NativeMenuNew;
