/** @format */

import Alert from '@mui/material/Alert';
import {styled} from '@mui/material/styles';
import {Box, Grid, Typography} from '@mui/material';
import {gql, useMutation, useQuery} from '@apollo/client';

import Loading from 'components/loading/Loading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import numberHelpers from 'helpers/numberHelpers';
import paymentHelpers from 'helpers/paymentHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'ConfirmChangePlan';

const classes = {
  title: `${PREFIX}-title`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: '23px',
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser($planId: ID!) {
    currentUser {
      subscription {
        endDate
      }
      planInfo(planId: $planId) {
        itemPrice
      }
    }
  }
`;

const CHANGE_PLAN = gql`
  mutation ChangePlan($planId: ID!, $includeCurrentPlan: Boolean!) {
    changePlan(planId: $planId, includeCurrentPlan: $includeCurrentPlan) {
      billId
    }
  }
`;

const ConfirmChangePlan = () => {
  const searchParams = routerHelpers.useSearchParams();
  const planId = searchParams.get('planId');
  const planName = searchParams.get('planName');

  const {mixpanel} = useMixpanelContext();

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {planId},
  });
  const [changePlan, {loading: changePlanLoading}] = useMutation(CHANGE_PLAN);

  if (loading) return <Loading />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {subscription, planInfo} = data.currentUser;

  const header = `Подключение тарифа «${planName}»`;

  const renderButton = () => {
    const handleConfirm = async () => {
      mixpanel.track('click_confirm_change_plan_button', {
        plan_id: planId,
        plan_name: planName,
        plan_price: planInfo.itemPrice,
      });

      const includeCurrentPlan = paymentHelpers.paySoon(subscription);
      const {data: changePlanData} = await changePlan({
        variables: {planId, includeCurrentPlan},
      });
      const {billId} = changePlanData.changePlan;

      window.location.href = `https://yalper.adsensor.ru/bills/${billId}/make_payment`;
    };

    return (
      <Box
        sx={{
          mt: 16,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={changePlanLoading}
              fullWidth
              onClick={handleConfirm}
            >
              Оплатить
            </PrimaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Root>
      <ModalPage header={header}>
        <Box
          sx={{
            mt: 8,
          }}
        >
          <Typography className={classes.title}>
            {`К оплате: ${numberHelpers.formatRUB(planInfo.itemPrice)}`}
          </Typography>
        </Box>
        {renderButton()}
      </ModalPage>
    </Root>
  );
};

export default ConfirmChangePlan;
