/** @format */

import {Box} from '@mui/material';

const Hr = () => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid #8E8E8E',
        my: 4,
      }}
    />
  );
};

export default Hr;
