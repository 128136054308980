/** @format */

import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import routerHelpers from 'helpers/routerHelpers';

import ruLocale from 'locales/ru/translation.json';
import enLocale from 'locales/en/translation.json';
import esLocale from 'locales/es/translation.json';

const locale = routerHelpers.IsRuVersion() ? 'ru' : 'en';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !import.meta.env.PROD,
    lng: locale,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ru: {translation: ruLocale},
      en: {translation: enLocale},
      es: {translation: esLocale},
    },
    saveMissing: true
  });

  i18n.on('missingKey', function(lngs, namespace, key, _res) {
    Sentry.captureMessage(`i18next missingKey ${key} for language ${lngs}`);
  });

export default i18n;
