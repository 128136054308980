/** @format */

interface Props {
  x?: number
  y?: number
  payload?: any
  formatDateValue: (date: string) => string
}

const CustomXTick = (props: Props) => {
  return (
    <text
      dx={-10}
      dy={10}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatDateValue(props.payload.value)}
    </text>
  );
};

export default CustomXTick;
