/** @format */

import type {CheckboxProps} from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import {Checkbox} from '@mui/material';

const PREFIX = 'GreenCheckbox';

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
};

const StyledCheckbox = styled(Checkbox)(() => ({
  color: '#73A011',
  '&$checked': {
    color: '#73A011',
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0,
    },
    '& .MuiIconButton-label:after': {
      backgroundColor: '#FFFFFF',
      content: '""',
      height: 15,
      left: 4,
      position: 'absolute',
      top: 4,
      width: 15,
      zIndex: -1,
    },
  },
}));

const GreenCheckbox = (props: CheckboxProps) => {
  return (
    <StyledCheckbox
      classes={{root: classes.root, checked: classes.checked}}
      color='default'
      {...props}
    />
  );
};

export default GreenCheckbox;
