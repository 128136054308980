/** @format */

import {useMemo} from 'react';

import * as R from 'ramda';
import clsx from 'clsx';

import {Box, Typography, useMediaQuery} from '@mui/material';
import {Trans} from 'react-i18next';
import {Theme, useTheme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {add, format} from 'date-fns';
// prettier-ignore
import {ru} from 'date-fns/locale';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import statsHelpers from 'helpers/statsHelpers';

import TransparentSmallButton from 'components/buttons/TransparentSmallButton';

import type {GraphStat, PlatformGraphStat} from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allIndicatorsButton: {
      height: '52px',
      padding: theme.spacing(4, 8),
      borderRadius: '8px',
      background: '#202020',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      color: '#F1F1F1',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
      borderColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '52px',
        padding: theme.spacing(4),
      },
    },
    allIndicatorsIconButton: {
      fontSize: '16px',
      color: '#F1F1F1',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    headerTitle: {
      color: '#F1F1F1',
      fontSize: '24px',
      fontWeight: 700,
    },
    controlsContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(4),
      },
    },
    indicatorPlate: {
      width: '24%',
      padding: theme.spacing(4),
      minHeight: '79px',
      borderRadius: '8px',
      borderBottom: '1px solid transparent',
      background: '#202020',
      [theme.breakpoints.down('md')]: {
        width: '49%',
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginRight: 0,
      },
    },
    indicatorInfo: {
      marginLeft: theme.spacing(4),
    },
    indicatorTitle: {
      color: '#F1F1F1',
      fontSize: '20px',
      fontWeight: 400,
    },
    indicatorValue: {
      display: 'flex',
      alignItems: 'center',
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      marginTop: theme.spacing(2),
    },
    indicatorValueIcon: {
      marginLeft: theme.spacing(2),
    },
    indicatorDate: {
      height: '52px',
      marginRight: theme.spacing(4),
      whiteSpace: 'nowrap',
      padding: theme.spacing(4, 8),
      fontSize: '14px',
      fontWeight: 400,
      borderRadius: '8px',
      border: '1px solid #202020',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    },
    indicatorsWrapper: {
      [theme.breakpoints.down('sm')]: {
        marginRight: -theme.spacing(2),
      },
    },
    borderGreen: {borderColor: '#677C41'},
    borderRed: {borderColor: '#AE5658'},
    borderNeutral: {borderColor: '#8B8B8B'},
  }),
);

const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      currentCompany {
        graphStats(startDate: $startDate, endDate: $endDate) {
          currency
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
      }
    }
  }
`;

type IndicatorType = {
  iconSrc: string;
  title: string;
  value: number;
  borderColorClassName: string;
  status: 'up' | 'down' | 'neutral';
};

const Indicators = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const now = new Date();
  const startDate = add(now, {days: -1});

  const {data} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(now, 'yyyy-MM-dd'),
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const platformGraphStats = useMemo(() => {
    if (!data) return [];

    const {graphStats} = data.currentUser.currentCompany;
    const totalStats = statsHelpers.sumGraphStats(graphStats);
    const allStats: GraphStat[] = graphStats.concat(totalStats);

    return R.pipe(
      R.filter<GraphStat>(v => v.platform === 'ALL'),
      R.map<GraphStat, PlatformGraphStat>(v => ({
        date: v.date,
        spent: v.spent,
        impressions: v.impressions,
        clicks: v.clicks,
        cpc: v.cpc,
        ctr: v.ctr,
        currency: v.currency,
      })),
    )(allStats);
  }, [data]);

  const defaultPlatformGraphStat = {
    date: 0,
    spent: 0,
    impressions: 0,
    clicks: 0,
    cpc: 0,
    ctr: 0,
    currency: 0,
  };

  const yesterdayPlatformGraphStats =
    platformGraphStats.find(v => v.date === format(startDate, 'yyyy-MM-dd')) ||
    defaultPlatformGraphStat;
  const todayPlatformGraphStats =
    platformGraphStats.find(v => v.date === format(now, 'yyyy-MM-dd')) ||
    defaultPlatformGraphStat;

  const generateIndicatorStatus = (
    yesterdayValue: number,
    todayValue: number,
  ) => {
    if (todayValue > yesterdayValue) return 'up';
    if (todayValue < yesterdayValue) return 'down';
    return 'neutral';
  };

  const generateBorderClassName = (
    yesterdayValue: number,
    todayValue: number,
  ) => {
    if (todayValue > yesterdayValue) return classes.borderGreen;
    if (todayValue < yesterdayValue) return classes.borderRed;
    return classes.borderNeutral;
  };

  const indicators: IndicatorType[] = [
    {
      iconSrc: new URL('./images/visibility.svg', import.meta.url).href,
      title: 'Показы',
      value: todayPlatformGraphStats.impressions,
      borderColorClassName: generateBorderClassName(
        yesterdayPlatformGraphStats.impressions,
        todayPlatformGraphStats.impressions,
      ),
      status: generateIndicatorStatus(
        yesterdayPlatformGraphStats.impressions,
        todayPlatformGraphStats.impressions,
      ),
    },
    {
      iconSrc: new URL('./images/adjust.svg', import.meta.url).href,
      title: 'Клики',
      value: todayPlatformGraphStats.clicks,
      borderColorClassName: generateBorderClassName(
        yesterdayPlatformGraphStats.clicks,
        todayPlatformGraphStats.clicks,
      ),
      status: generateIndicatorStatus(
        yesterdayPlatformGraphStats.clicks,
        todayPlatformGraphStats.clicks,
      ),
    },
    {
      iconSrc: new URL('./images/currency_ruble.svg', import.meta.url).href,
      title: 'Цена клика',
      value: todayPlatformGraphStats.cpc,
      borderColorClassName: generateBorderClassName(
        yesterdayPlatformGraphStats.cpc,
        todayPlatformGraphStats.cpc,
      ),
      status: generateIndicatorStatus(
        yesterdayPlatformGraphStats.cpc,
        todayPlatformGraphStats.cpc,
      ),
    },
    {
      iconSrc: new URL('./images/percent.svg', import.meta.url).href,
      title: 'CTR',
      value: todayPlatformGraphStats.ctr,
      borderColorClassName: generateBorderClassName(
        yesterdayPlatformGraphStats.ctr,
        todayPlatformGraphStats.ctr,
      ),
      status: generateIndicatorStatus(
        yesterdayPlatformGraphStats.ctr,
        todayPlatformGraphStats.ctr,
      ),
    },
  ];

  const handleClickBusinessMetrics = () => {
    navigate('/home/main/business-metrics');
  };

  return (
    <Box mt={8}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <Typography className={classes.headerTitle}>
          <Trans>Показатели</Trans>
        </Typography>

        <Box
          display={'flex'}
          alignItems={'center'}
          className={classes.controlsContainer}
        >
          <Box className={classes.indicatorDate}>
            <Trans>{format(now, 'dd MMMM yyyy', {locale: ru})}</Trans>
          </Box>
          <TransparentSmallButton
            className={classes.allIndicatorsButton}
            onClick={handleClickBusinessMetrics}
          >
            <AssessmentOutlinedIcon
              className={classes.allIndicatorsIconButton}
            />
            {!isMobile && <Trans>Все</Trans>}
          </TransparentSmallButton>
        </Box>
      </Box>

      <Box
        mt={6}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        className={classes.indicatorsWrapper}
      >
        {indicators.map((v, index) => (
          <Box
            key={index}
            className={clsx(classes.indicatorPlate, v.borderColorClassName)}
            display={'flex'}
            alignItems={'center'}
          >
            <img alt='visibility' src={v.iconSrc} />

            <Box className={classes.indicatorInfo}>
              <Box className={classes.indicatorTitle}>
                <Trans>{v.title}</Trans>
              </Box>
              <Box className={classes.indicatorValue}>
                {v.value}
                {v.status === 'up' && (
                  <img
                    className={classes.indicatorValueIcon}
                    alt='arrow'
                    src={
                      new URL('./images/arrow_upward.svg', import.meta.url).href
                    }
                  />
                )}
                {v.status === 'down' && (
                  <img
                    className={classes.indicatorValueIcon}
                    alt='arrow'
                    src={
                      new URL('./images/arrow_downward.svg', import.meta.url)
                        .href
                    }
                  />
                )}
                {v.status === 'neutral' && (
                  <img
                    className={classes.indicatorValueIcon}
                    alt='arrow'
                    src={
                      new URL('./images/horizontal_rule.svg', import.meta.url)
                        .href
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Indicators;
