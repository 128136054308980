/** @format */

import {Routes, Route} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import BaseLayout from 'layouts/BaseLayout';

import Company from './Company';

const Share = () => {
  return (
    <BaseLayout>
      <Routes>
        <Route
          path={ROUTES.SHARE_UUID}
          element={
            <PrivateRoute>
              <Company />
            </PrivateRoute>
          }
        />
      </Routes>
    </BaseLayout>
  );
};

export default Share;
