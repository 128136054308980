/** @format */

import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
const PREFIX = 'Footer';

const classes = {
  text: `${PREFIX}-text`,
  link: `${PREFIX}-link`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.text}`]: {
    color: theme.palette.action.hover,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
}));

const Footer = () => {
  return (
    <StyledBox mt={2}>
      <Typography className={classes.text}>
        <Trans>Cуммы с учётом НДС</Trans>
      </Typography>
    </StyledBox>
  );
};

export default Footer;
