/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';
import {clsx} from 'clsx';

import {Link as RouterLink} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import * as R from 'ramda';
import {Box, Typography, InputAdornment, Button} from '@mui/material';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import SearchIcon from '@mui/icons-material/Search';
import {gql, useQuery} from '@apollo/client';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useLocation} from 'react-router-dom';

import {useCompanyContext} from 'contexts/CompanyContext';
import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import LegacyTextField from 'components/inputs/LegacyTextField';
import WideLayout from 'layouts/WideLayout';

import Plate from './Plate';
import {Category} from '@mui/icons-material';

const PREFIX = 'HomeGrouping';

const classes = {
  headerTitle: `${PREFIX}-headerTitle`,
  projectTitle: `${PREFIX}-projectTitle`,
  editButton: `${PREFIX}-editButton`,
  editButtonIcon: `${PREFIX}-editButtonIcon`,
  addButtonContainer: `${PREFIX}-addButtonContainer`,
  searchInput: `${PREFIX}-searchInput`,
  addButton: `${PREFIX}-addButton`,
  addButtonIcon: `${PREFIX}-addButtonIcon`,
  sortButton: `${PREFIX}-sortButton`,
  sortButtonIcon: `${PREFIX}-sortButtonIcon`,
  sortButtonIconRotated: `${PREFIX}-sortButtonIconRotated`,
  groupButtonContainer: `${PREFIX}-groupButtonContainer`,
  groupButton: `${PREFIX}-groupButton`,
  groupButtonIcon: `${PREFIX}-groupButtonIcon`,
  filtersButton: `${PREFIX}-filtersButton`,
  filtersButtonIcon: `${PREFIX}-filtersButtonIcon`,
  groupTitle: `${PREFIX}-groupTitle`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.headerTitle}`]: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '30px',
  },

  [`& .${classes.projectTitle}`]: {
    fontStyle: 'normal',
    letterSpacing: '0.225px',
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
  },

  [`& .${classes.groupTitle}`]: {
    color: '#F1F1F1',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },

  [`& .${classes.editButton}`]: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    backgroundColor: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.sortButton}`]: {
    marginLeft: theme.spacing(4),
    height: '48px',
    borderRadius: '8px',
    padding: theme.spacing(4, 2),
    backgroundColor: '#202020',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.sortButtonIcon}`]: {
    marginRight: theme.spacing(5),
    fontSize: '16px',
    color: '#8B8B8B',
  },
  [`& .${classes.sortButtonIconRotated}`]: {
    transform: 'rotate(180deg)',
  },

  [`& .${classes.groupButtonContainer}`]: {
    marginLeft: theme.spacing(8),
    height: '48px',
    padding: theme.spacing(0, 0, 0, 4),
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '8px',
    border: '1px solid #383838',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.groupButton}`]: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 2),
    height: '100%',
    borderRadius: '8px',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: '#2B2B2B',
  },
  [`& .${classes.groupButtonIcon}`]: {
    marginLeft: theme.spacing(2),
    color: '#F1F1F1',
    fontSize: '16px',
  },

  [`& .${classes.editButtonIcon}`]: {
    fontSize: '16px',
    color: '#fff',
  },

  [`& .${classes.filtersButton}`]: {
    height: '48px',
    marginLeft: theme.spacing(4),
    padding: theme.spacing(3, 4),
    borderRadius: '24px',
    border: '1px solid  #383838',
    background: 'transparent',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.filtersButtonIcon}`]: {
    marginLeft: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '16px',
  },

  [`& .${classes.searchInput}`]: {
    width: 'auto',
    marginLeft: theme.spacing(4),
    '& fieldset': {
      borderColor: '#202020',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.addButton}`]: {
    minWidth: 'auto',
    height: '48px',
    marginLeft: theme.spacing(4),
    fontSize: '14px',
    fontWeight: 400,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.addButtonIcon}`]: {
    fontSize: '18px',
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#202020',
    background: '#E3E3E3',
    '& .MuiMenu-list': {
      '&:hover': {background: '#E3E3E3'},
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(3, 2, 3, 8),
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {},
      '&:hover': {background: '#F1F1F1'},
      '&:first-of-type': {
        padding: theme.spacing(3, 2),
        borderBottom: '1px solid #8B8B8B',
      },
      '&:last-of-type': {
        padding: theme.spacing(3, 2),
        borderTop: '1px solid #8B8B8B',
      },
    },
  },
}));

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      categories {
        id
        name
        tags {
          id
          name
        }
      }
      companies {
        id
        uuid
        name
        isLaunched
        lastCheckCollectDate
        tags {
          id
          name
          category {
            id
            name
          }
        }
        lastBudgetScores {
          adType
          value
        }
        lastChecks {
          found
          new
        }
        tokens {
          id
          lastError
          provider
          isValid
        }
      }
    }
  }
`;

const getUniqueObjects = (arr, key) => {
  const seen = new Set();

  return arr.filter(item => {
    const value = item[key];

    if (seen.has(value)) {
      return false;
    }

    seen.add(value);

    return true;
  });
};

const HomeGrouping = () => {
  const {authToken} = useAuthContext();
  const {mixpanel} = useMixpanelContext();
  const {switchAdType, switchCompany} = useCompanyContext();

  const navigate = useNavigate();

  const {t} = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [companiesWithCategory, setСompaniesWithCategory] = useState([]);
  const [companiesWithoutCategory, setCompaniesWithoutCategory] = useState([]);

  const [categories, setCategories] = useState([]);
  const [chosenFiltering, setChosenFiltering] = useState<
    null | number | 'default'
  >('default');
  const [search, setSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const {data: companiesData} = useQuery(GET_CURRENT_USER, {skip: !authToken});

  const handleClickAddNew = () => {
    mixpanel.track('click_home_new_add_company_button');
  };

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  const handleAdClick = (adType: string, company: any) => {
    switchAdType(adType);
    switchCompany(company);

    navigate('/home/main/status');
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (!companiesData) return;
    if (!companiesData.currentUser) return;

    if (search === '') setCompanies(companiesData.currentUser.companies);

    const lowerCaseSearch = search.toLowerCase();
    const items = R.pipe(
      R.filter(v => v.name.toLowerCase().indexOf(lowerCaseSearch) >= 0),
      R.sortBy(v => v.name),
    )(companiesData.currentUser.companies);

    setCompanies(items);

    setCategories(companiesData.currentUser.categories);
  }, [companiesData, search]);

  const handleCategoryChoose = (v: number | string) => {
    if (v === 'default') {
      setChosenFiltering('default');
    } else if (v === 'settings') {
      navigate('/home/settings/grouping');
    } else {
      setChosenFiltering(v);
    }

    handleMenuClose();
  };

  useEffect(() => {
    if (chosenFiltering) {
      const tagsList = companies
        .map(company => company.tags)
        .flat()
        .filter(tag => {
          return tag.category.id === chosenFiltering;
        });

      const tags = getUniqueObjects(tagsList, 'id').map(tag => tag.id);

      setСompaniesWithCategory(
        tags.reduce((acc, cur) => {
          return [
            ...acc,
            {[cur]: companies.filter(v => v.tags.some(t => t.id === cur))},
          ];
        }, []),
      );

      setCompaniesWithoutCategory(
        companies.filter(
          v => !v.tags.some(tag => tags.includes(String(tag.id))),
        ),
      );
    }
  }, [chosenFiltering]);

  return (
    <Root>
      <WideLayout>
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.headerTitle}>
            <Trans>Проекты</Trans>
          </Typography>
          <Box className={classes.groupButtonContainer}>
            <Trans>Группировать</Trans>

            <Button className={classes.groupButton} onClick={handleMenuOpen}>
              <Trans>
                {chosenFiltering === 'default'
                  ? 'Не группировать'
                  : categories.find(v => v.id === chosenFiltering)?.name}
              </Trans>

              <KeyboardArrowDownIcon className={classes.groupButtonIcon} />
            </Button>

            <StyledMenu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => handleCategoryChoose('default')}
                disableRipple
              >
                <ToggleOffIcon />

                <Trans>Не группировать</Trans>
              </MenuItem>
              {categories.map(v => (
                <MenuItem
                  onClick={() => handleCategoryChoose(v.id)}
                  disableRipple
                >
                  <Trans>{v.name}</Trans>
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => handleCategoryChoose('settings')}
                disableRipple
              >
                <SettingsIcon />

                <Trans>Настройки</Trans>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* <Button className={classes.filtersButton} onClick={() => {}}>
            <Trans>Фильтры</Trans>

            <AddIcon className={classes.filtersButtonIcon} />
          </Button> */}
          <LegacyTextField
            placeholder={t('Поиск', 'Поиск')}
            inputProps={{
              name: 'search',
              type: 'search',
            }}
            onChange={handleChangeSearch}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon htmlColor='#8B8B8B' />
                </InputAdornment>
              ),
            }}
          />
          {/* <Button className={classes.sortButton} onClick={handleSort}>
            <ArrowUpwardIcon
              className={clsx(
                classes.sortButtonIcon,
                isSorted && classes.sortButtonIconRotated,
              )}
            />
            <Trans>По алфавиту</Trans>
          </Button> */}

          <PrimaryMediumButton
            component={RouterLink}
            to={{pathname: '/home/settings/wizard/company'}}
            variant='contained'
            onClick={handleClickAddNew}
            className={classes.addButton}
          >
            <Box
              sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineRounded className={classes.addButtonIcon} />
            </Box>
            <Trans>Добавить проект</Trans>
          </PrimaryMediumButton>
        </Box>

        <Box sx={{mt: 8}}>
          {chosenFiltering ? (
            <Box>
              <Typography className={classes.groupTitle}>
                {chosenFiltering
                  ? categories.find(v => v.id === chosenFiltering)?.name
                  : ''}
              </Typography>

              <Box
                sx={{
                  mt: 4,
                }}
              >
                {companiesWithCategory.map(company => {
                  const keys = Object.keys(company);
                  const tags = categories.map(v => v.tags).flat();

                  return keys.map(key => {
                    const tag = tags.find(tag => tag.id === key);

                    return (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mt: 8,
                        }}
                      >
                        <Typography className={classes.groupTitle}>
                          {tag.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          {company[key].map(v => (
                            <Plate
                              key={v.id}
                              company={v}
                              handleAdClick={handleAdClick}
                            />
                          ))}
                        </Box>
                      </Box>
                    );
                  });
                })}
              </Box>
            </Box>
          ) : null}
          <Box
            sx={{
              mt: 4,
            }}
          >
            <Typography className={classes.groupTitle}>
              <Trans>
                {chosenFiltering === 'default'
                  ? 'Не группировать'
                  : 'Без группировки'}
              </Trans>
            </Typography>

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {chosenFiltering === 'default'
                ? companies.map(company => (
                    <Plate
                      key={company.id}
                      company={company}
                      handleAdClick={handleAdClick}
                    />
                  ))
                : companiesWithoutCategory.map(company => (
                    <Plate
                      key={company.id}
                      company={company}
                      handleAdClick={handleAdClick}
                    />
                  ))}
            </Box>
          </Box>
        </Box>
      </WideLayout>
    </Root>
  );
};

export default HomeGrouping;
