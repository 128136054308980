/** @format */

import {Grid} from '@mui/material';

import {useNavigate} from 'react-router-dom';

import BaseStep from 'onboarding/BaseStep';
import NextButton from 'onboarding/NextButton';
import {ONBOARDING_1_ID} from 'machines/onboarding/onboarding1Machine';

const STEP_ID = 'active.step2';

const Step2 = props => {
  const navigate = useNavigate();

  const title =
    'Заглядывай сюда пару в неделю, чтобы держать под полным контролем ' +
    'работу своих рекламщиков!';

  const renderButton = onClick => {
    const handleClick = () => {
      onClick();
      navigate('/home/settings/telegram');
    };

    return (
      <Grid item xs={10}>
        <NextButton onClick={handleClick}>Настроить уведомления</NextButton>
      </Grid>
    );
  };

  return (
    <BaseStep
      iconSrc={new URL('./images/step2.jpg', import.meta.url)}
      onboardingId={ONBOARDING_1_ID}
      showOnboarding={props.showOnboarding}
      stepId={STEP_ID}
      title={title}
      renderButton={renderButton}
    />
  );
};

export default Step2;
