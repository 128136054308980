/** @format */

import {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import BaseStep from 'onboarding/BaseStep';
import {ONBOARDING_2_ID} from 'machines/onboarding/onboarding2Machine';
import {useOnboardingContext} from 'contexts/OnboardingContext';

const STEP_ID = 'active.step4';

const Step4 = props => {
  const navigate = useNavigate();

  const context = useOnboardingContext();
  const showStep = context.showStep(ONBOARDING_2_ID, STEP_ID);

  useEffect(() => {
    if (!showStep) return;
    navigate(props.hasErrors ? '#errors' : '#warnings');
  }, [showStep, navigate, props.hasErrors]);

  const title =
    'Заглядывай сюда пару раз в неделю, чтобы ' +
    'держать под полным контролем работу своих рекламщиков!';

  return (
    <BaseStep
      iconSrc={new URL('./images/step4.jpg', import.meta.url)}
      onboardingId={ONBOARDING_2_ID}
      showOnboarding={props.showOnboarding}
      stepId={STEP_ID}
      title={title}
    />
  );
};

export default Step4;
