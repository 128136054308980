/** @format */

import {useEffect, useState} from 'react';

import connectHelpers from 'helpers/connectHelpers';
import {gql, useQuery} from '@apollo/client';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      type
      isWizardFinished
      profile {
        id
      }
      currentCompany {
        id
        tokens {
          lastError
          provider
        }
      }
    }
  }
`;

const useRedirectToCurrentStep = () => {
  const [redirectPath, setRedirectPath] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const {data} = useQuery(GET_CURRENT_USER);

  const loadRedirectPath = path => {
    setRedirectPath(path);
    setLoaded(true);
  };

  useEffect(() => {
    if (!data) return;
    const {
      isWizardFinished,
      currentCompany,
    } = data.currentUser;

    if (!currentCompany) {
      loadRedirectPath('/home/settings/connect');
      return;
    }

    const connectedTokens = connectHelpers.connectedTokens(
      currentCompany.tokens,
    );

    if (connectedTokens.length === 0) {
      const {id} = currentCompany;

      if (!isWizardFinished) {
        loadRedirectPath(`/wizard/connect?companyId=${id}`);
        return;
      }
    }

    loadRedirectPath(null);
  }, [setLoaded, data]);

  return [loaded, redirectPath];
};

export default {
  useRedirectToCurrentStep,
};
