/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import ConfirmRemove from 'components/ConfirmRemove';
import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const PREFIX = 'BadWords';

const classes = {
  text: `${PREFIX}-text`,
  addLink: `${PREFIX}-addLink`,
  tag: `${PREFIX}-tag`,
  tagText: `${PREFIX}-tagText`,
  addedTagText: `${PREFIX}-addedTagText`,
  removeTagIcon: `${PREFIX}-removeTagIcon`,
  removeAddedTagIcon: `${PREFIX}-removeAddedTagIcon`,
  footer: `${PREFIX}-footer`,
  footerText: `${PREFIX}-footerText`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.text}`]: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    whiteSpace: 'pre-line',
  },

  [`& .${classes.addLink}`]: {
    color: theme.palette.success.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.success.main,
    },
  },

  [`& .${classes.tag}`]: {
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing(3),
  },

  [`& .${classes.tagText}`]: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    opacity: 0.7,
  },

  [`& .${classes.addedTagText}`]: {
    fontWeight: 700,
    opacity: 1,
  },

  [`& .${classes.removeTagIcon}`]: {
    opacity: 0.7,
    padding: theme.spacing(1),
  },

  [`& .${classes.removeAddedTagIcon}`]: {
    opacity: 1,
  },

  [`& .${classes.footer}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.footerText}`]: {
    color: '#808080',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

const GET_BAD_WORDS = gql`
  query GetBadWords {
    currentUser {
      currentCompany {
        badWords
      }
    }
  }
`;

const SAVE_BAD_WORDS = gql`
  mutation SaveBadWords($badWords: [String!]!) {
    saveBadWords(badWords: $badWords)
  }
`;

const BadWords = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [badWords, setBadWords] = useState<string[]>([]);
  const [addedBadWords, setAddedBadWords] = useState<string[]>([]);

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [badWordToRemove, setBadWordToRemove] = useState('');

  const {loading, error, data} = useQuery(GET_BAD_WORDS);
  const [saveBadWords, {loading: saveBadWordsLoading}] =
    useMutation(SAVE_BAD_WORDS);

  useEffect(() => {
    if (!data) return;
    setBadWords(data.currentUser.currentCompany.badWords);
  }, [data]);

  if (loading) {
    return (
      <ModalLoading
        title={t('Пожалуйста, подождите', 'Пожалуйста, подождите')}
      />
    );
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  if (confirmRemove) {
    const handleConfirm = () => {
      const newBadWords = badWords.filter(v => v !== badWordToRemove);
      const newAddedBadWords = addedBadWords.filter(v => v !== badWordToRemove);

      setBadWords(newBadWords);
      setAddedBadWords(newAddedBadWords);

      setConfirmRemove(false);
      setBadWordToRemove('');
    };

    const handleCancel = () => {
      setConfirmRemove(false);
      setBadWordToRemove('');
    };

    const confirmRemoveHeader = t(
      'Вы уверены что хотите удалить слово "{{badWordToRemove}}" из чёрного списка?',
      'Вы уверены что хотите удалить слово "{{badWordToRemove}}" из чёрного списка?',
      {badWordToRemove},
    );

    return (
      <ConfirmRemove
        header={confirmRemoveHeader}
        text={t(
          'Вы всегда сможет добавить его снова, если захотите.',
          'Вы всегда сможет добавить его снова, если захотите.',
        )}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    );
  }

  const renderInput = () => {
    // https://stackoverflow.com/a/42645711/3632318
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    const handleAdd = () => {
      const lines = R.pipe(
        R.map(R.trim),
        R.reject(R.isEmpty),
      )(inputValue.split('\n'));
      const newBadWords = R.uniq(badWords.concat(lines));
      const newAddedBadWords = R.uniq(addedBadWords.concat(lines));

      setBadWords(newBadWords);
      setAddedBadWords(newAddedBadWords);

      setInputValue('');
    };

    return (
      <Box
        sx={{
          mt: 8,
        }}
      >
        <MediumTextField
          placeholder={t('Добавьте новые слова', 'Добавьте новые слова')}
          minRows={4}
          maxRows={8}
          multiline
          value={inputValue}
          myProps={{placeholderFocused: ''}}
          InputProps={{
            endAdornment: inputValue ? (
              <InputAdornment position='end'>
                <Link
                  className={classes.addLink}
                  component='button'
                  onClick={handleAdd}
                >
                  <Typography>
                    <Trans>Добавить</Trans>
                  </Typography>
                </Link>
              </InputAdornment>
            ) : null,
          }}
          onChange={handleChange}
        />
      </Box>
    );
  };

  const renderTag = (badWord: string, index: number) => {
    const handleRemove = () => {
      setConfirmRemove(true);
      setBadWordToRemove(badWord);
    };

    const isAddedTag = addedBadWords.includes(badWord);

    return (
      <Box key={index} className={classes.tag}>
        <Typography
          className={clsx(classes.tagText, {
            [classes.addedTagText]: isAddedTag,
          })}
        >
          {badWord}
        </Typography>
        <IconButton
          className={clsx([
            classes.removeTagIcon,
            {[classes.removeAddedTagIcon]: isAddedTag},
          ])}
          onClick={handleRemove}
          size='large'
        >
          <img
            alt='Remove'
            height={18}
            src={new URL('./images/remove.png', import.meta.url).href}
            width={18}
          />
        </IconButton>
      </Box>
    );
  };

  const renderButton = () => {
    const handleSave = async () => {
      await saveBadWords({variables: {badWords}});
      navigate(-1);
    };

    return (
      <Box
        sx={{
          mt: 16,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={saveBadWordsLoading}
              fullWidth
              onClick={handleSave}
            >
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item className={classes.footer} xs={12} sm={8}>
            <Typography className={classes.footerText}>
              <Trans>
                Добавленные и удаленные слова будут учтены при следующей
                проверке — завтра в 10:00
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Root>
      <ModalPage
        header={t(
          'Показы по запросам из черного списка',
          'Показы по запросам из черного списка',
        )}
      >
        <Typography className={classes.text}>
          <Trans>
            Вы можете настроить списки слов, по которым ваша реклама НЕ должна
            показываться. Добавьте новые слова или удалите существующие, если
            они вам не подходят.
            <br />
            <br />
            Вы можете ввести слово только в одной форме, остальные формы этого
            слова мы учтем сами.
          </Trans>
        </Typography>
        {renderInput()}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mt: 4,
          }}
        >
          {badWords.sort().map((v, i) => renderTag(v, i))}
        </Box>
        {renderButton()}
      </ModalPage>
    </Root>
  );
};

export default BadWords;
