/** @format */

import {useEffect, useState} from 'react';

// https://yadro.top/5229-sklonenie-slov-na-javascript.html
const inflectNoun = (number, one, two, five) => {
  let rem = number % 100;
  if (rem >= 5 && rem <= 20) return five;

  rem = rem % 10;
  if (rem === 1) return one;
  if (rem >= 2 && rem <= 4) return two;

  return five;
};

// https://github.com/gregberge/react-merge-refs
const mergeRefs =
  (...refs) =>
  value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };

// https://www.aaron-powell.com/posts/2019-09-23-recursive-settimeout-with-react-hooks/
const useGlobalVariable = (variableName, callback) => {
  const [retries, setRetries] = useState(0);

  const maxRetries = 10;
  const delay = 500;

  useEffect(() => {
    let timerId = null;

    const run = () => {
      if (window[variableName]) {
        callback();
      } else {
        if (retries >= maxRetries) return;
        setRetries(retries + 1);
        timerId = setTimeout(run, delay);
      }
    };

    timerId = setTimeout(run, delay);
    return () => timerId && clearTimeout(timerId);
  }, [retries, variableName, callback]);
};

export default {
  inflectNoun,
  mergeRefs,
  useGlobalVariable,
};
