/** @format */
import * as R from 'ramda';

import type {Check} from 'types';
import {sensorName} from 'helpers/sensor_names';

export const sortChecks = (
  checks: Check[],
  translate: (a: string) => string,
): Check[] => {
  const sortFunction = (a: Check, b: Check) => {
    // by found
    if (a.found === 0 && b.found > 0) return 1;
    if (a.found > 0 && b.found === 0) return -1;
    // by is_error
    if (!a.sensor.isError && b.sensor.isError) return 1;
    if (a.sensor.isError && !b.sensor.isError) return -1;

    if (a.sensor.isError === b.sensor.isError) {
      return translate(sensorName(a.sensor.name)).localeCompare(
        translate(sensorName(b.sensor.name)),
      );
    }

    return 0;
  };

  return R.sort(sortFunction, checks);
};
