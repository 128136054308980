/** @format */

import {Backdrop, Box, ListItem, ListItemText, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';

import constants from 'helpers/constants';
import {sensorName} from 'helpers/sensor_names';

const PREFIX = 'ExcludedRow';

const classes = {
  row: `${PREFIX}-row`,
  avatar: `${PREFIX}-avatar`,
  badge: `${PREFIX}-badge`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.row}`]: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4),
    position: 'relative',
  },

  [`& .${classes.avatar}`]: {
    minWidth: 16,
  },

  [`& .${classes.badge}`]: {
    backgroundColor: '#828282',
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    color: '#D2D2D2',
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '22px',
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.subtitle}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.backdrop}`]: {
    backgroundColor: theme.backdrop.main,
    position: 'absolute',
    zIndex: 1,
  },
}));

const ExcludedRow = props => {
  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  return (
    <StyledBox position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <Box className={classes.avatar} />
        <Box className={classes.badge}>{constants.ZWSP}</Box>

        <ListItemText
          disableTypography
          primary={
            <Typography className={classes.title}>
              {t(sensorName(props.check.sensor.name))}
            </Typography>
          }
          secondary={
            <Typography className={classes.subtitle}>
              <Trans>Сенсор отключен</Trans>
            </Typography>
          }
        />
      </ListItem>
      <Backdrop className={classes.backdrop} open={props.blurRow} />
    </StyledBox>
  );
};

export default ExcludedRow;
