/** @format */
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import DateGraph from 'components/graphs/DateGraph';
import numberHelpers from 'helpers/numberHelpers';
import type {MetricName, PlatformGraphStat} from 'types';

const METRICS_WITH_ANOMALIES: MetricName[] = ['clicks', 'cpc'];
const INVERTED_METRICS_WITH_ANOMALIES: MetricName[] = ['clicks'];

type Props = {
  platformGraphStats: PlatformGraphStat[];
};

const MetricGraph = (props: Props) => {
  const {t, i18n} = useTranslation();

  const METRIC_HUMAN_NAMES = {
    impressions: t('Показы', 'Показы'),
    clicks: t('Клики', 'Клики'),
    cpc: t('Цена клика', 'Цена клика'),
    ctr: t('CTR', 'CTR'),
  };

  const currency = () => {
    if (props.platformGraphStats.length === 0) {
      return '';
    }
    return props.platformGraphStats[0].currency;
  };

  const currencyFormatter = numberHelpers.buildFormatter(
    currency(),
    i18n.language,
  );

  const formatYValue = (metricName: MetricName) => (value: number) => {
    if (metricName === 'cpc') return currencyFormatter.format(value);
    if (metricName === 'ctr') return `${value}%`;

    return value.toString();
  };

  // https://stackoverflow.com/a/27563449/3632318
  return (
    <>
      {['impressions', 'clicks', 'cpc', 'ctr'].map(item => {
        const currentMetricName = item as MetricName;

        const graphData = props.platformGraphStats.map(v => ({
          dateValue: v.date,
          yValue: v[currentMetricName],
        }));

        return (
          <Box
            key={item}
            sx={{
              mt: 10,
            }}
          >
            <Box>
              <Typography variant='h2'>
                {METRIC_HUMAN_NAMES[currentMetricName]}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 4,
              }}
            >
              <DateGraph
                data={graphData}
                formatYValue={formatYValue(currentMetricName)}
                inverted={INVERTED_METRICS_WITH_ANOMALIES.includes(
                  currentMetricName,
                )}
                showAnomalies={METRICS_WITH_ANOMALIES.includes(
                  currentMetricName,
                )}
                variant='SLIM'
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default MetricGraph;
