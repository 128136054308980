/** @format */

import {ChangeEvent} from 'react';

import {styled} from '@mui/material/styles';

import {Box, Chip, ListItemText, MenuItem, Select} from '@mui/material';
import {Theme} from '@mui/material/styles';

import GreenCheckbox from 'components/checkboxes/GreenCheckbox';

const PREFIX = 'SecondaryMultySelect';

const classes = {
  select: `${PREFIX}-select`,
  menuItem: `${PREFIX}-menuItem`,
  chip: `${PREFIX}-chip`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.select}`]: {
    flexGrow: 1,
    width: (props: Props) => props.width,
    backgroundColor: theme.palette.secondary.main,
    '& .MuiSelect-root': {
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid #DADADA',
      borderRadius: 8,
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
    '& .MuiSelect-select': {
      color: theme.palette.text.secondary,
      border: '1px solid #DADADA',
      paddingRight: theme.spacing(8),
    },
    '& .MuiSelect-icon': {
      right: 6,
    },
  },

  [`& .${classes.menuItem}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
    '&.Mui-selected': {
      backgroundColor: '#E0E0E0',
    },
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },
}));

type Option = {
  value: string;
  text: string;
};

type Props = {
  disabled?: boolean;
  options: Option[];
  value: string[];
  placeholder?: string | null;
  width?: number;
  renderValue: (selected: string[]) => React.ReactNode;
  onSelect: (value: string[]) => void;
};

const SecondaryMultySelect = (props: Props) => {
  const {disabled = false} = props;

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string[]);
  };

  const handleDeleteItem = (value: string) => {
    props.onSelect(props.value.filter(v => v !== value));
  };

  const renderValue = (selected: any) => {
    return props.renderValue(selected as string[]);
  };

  const selectedOptions = props.value.map(v =>
    props.options.find(vv => vv.value === v),
  );

  return (
    <StyledBox>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Select
          className={classes.select}
          disableUnderline
          disabled={disabled}
          multiple
          value={props.value}
          placeholder={props.placeholder || undefined}
          renderValue={renderValue}
          // @ts-ignore
          onChange={handleChange}
        >
          {props.options.map((v, i) => (
            <MenuItem key={i} className={classes.menuItem} value={v.value}>
              <GreenCheckbox checked={props.value.indexOf(v.value) > -1} />
              <ListItemText primary={v.text} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        {selectedOptions.map(item => (
          <Chip
            key={item!.value}
            label={item!.text}
            className={classes.chip}
            onDelete={() => handleDeleteItem(item!.value)}
          />
        ))}
      </Box>
    </StyledBox>
  );
};

export default SecondaryMultySelect;
