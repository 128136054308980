/** @format */

import * as R from 'ramda';

import adTypeHelpers from 'helpers/adTypeHelpers';
import numberHelpers from 'helpers/numberHelpers';
import type {
  AccountGraphStat,
  AdType,
  BaseStat,
  CampaignGraphStat,
  GraphStat,
  Stat,
} from 'types';

const filterStatsForDashboard = (stats: Stat[], currentAdType: AdType) => {
  const adTypePlatforms = adTypeHelpers.platformsFromAdType(currentAdType);

  const adTypeStats = stats
    .filter(v => adTypePlatforms.includes(v.platform))
    .filter(v => v.state !== 'NO_TOKEN');

  const totalStat = sumStats(adTypeStats);
  return [...adTypeStats, totalStat];
};

const sumStats = (stats: Stat[]) =>
  calculateTotalStat<Stat>(
    // YANDEX = YANDEX_SEARCH + YANDEX_NETWORK =>
    // sum YANDEX, GOOGLE, FACEBOOK and VKONTAKTE only
    stats.filter(v => v.platform === v.extendedPlatform),
    {
      platform: 'ALL',
      extendedPlatform: 'ALL',
      state: 'READY',
      monthSpent: 0,
      spent: 0,
      impressions: 0,
      clicks: 0,
      cpc: 0,
      ctr: 0,
      currency: '',
    },
  );

  const sumGraphStats = (stats: GraphStat[]): GraphStat[] => {
    return R.pipe(
      R.groupWith<GraphStat>((a, b) => a.date == b.date),
      R.map(group => {
        return calculateTotalStat<GraphStat>(
          // See comment in sumStats
          group.filter(v => v.platform === v.extendedPlatform),
          {
            platform: 'ALL',
            extendedPlatform: 'ALL',
            date: group[0].date,
            spent: 0,
            impressions: 0,
            clicks: 0,
            cpc: 0,
            ctr: 0,
            currency: '',
          },
        );
      }),
    )(stats);
  };

const sumAccountGraphStats = (stats: AccountGraphStat[]): AccountGraphStat[] =>
  R.pipe(
    R.groupWith<AccountGraphStat>((a, b) => a.date === b.date),
    R.map(group => {
        const totalStat = calculateTotalStat<AccountGraphStat>(
          // See comment in sumStats
          group.filter(v => v.platform === v.extendedPlatform),
          {
            account: 'ALL',
            accountName: 'ALL',
            platform: 'ALL',
            extendedPlatform: 'ALL',
            date: group[0].date,
            spent: 0,
            impressions: 0,
            clicks: 0,
            cpc: 0,
            ctr: 0,
            currency: '',
          },
        );

        const accountTotalStats = R.pipe(
          R.groupWith<AccountGraphStat>((a,b) => a.account === b.account),
          R.map(accountGroup => {
              return calculateTotalStat<AccountGraphStat>(
                // See comment in sumStats
                accountGroup.filter(v => v.platform === v.extendedPlatform),
                {
                  account: accountGroup[0].account,
                  accountName: accountGroup[0].accountName,
                  platform: 'ALL',
                  extendedPlatform: 'ALL',
                  date: group[0].date,
                  spent: 0,
                  impressions: 0,
                  clicks: 0,
                  cpc: 0,
                  ctr: 0,
                  currency: '',
                },
              );
          }),
        )(group);

        const platformTotalStats = R.pipe(
          R.groupWith<AccountGraphStat>((a,b) => a.extendedPlatform === b.extendedPlatform),
          R.map(extendedPlatformGroup => {
              return calculateTotalStat<AccountGraphStat>(
                extendedPlatformGroup,
                {
                  account: 'ALL',
                  accountName: 'ALL',
                  platform: extendedPlatformGroup[0].platform,
                  extendedPlatform: extendedPlatformGroup[0].extendedPlatform,
                  date: group[0].date,
                  spent: 0,
                  impressions: 0,
                  clicks: 0,
                  cpc: 0,
                  ctr: 0,
                  currency: '',
                },
              );
          }),
        )(group);

        return R.pipe(
          R.concat(accountTotalStats),
          R.concat(platformTotalStats),
        )([totalStat]);
    }),
    R.flatten,
  )(stats);

const sumCampaignGraphStats = (
  stats: CampaignGraphStat[],
): CampaignGraphStat[] =>
  R.pipe(
    R.groupWith<CampaignGraphStat>((a,b) => a.date === b.date),
    R.map(group => {
        const totalStat = calculateTotalStat<CampaignGraphStat>(group, {
          account: 'ALL',
          campaignId: 'ALL',
          platform: 'ALL',
          extendedPlatform: 'ALL',
          date: group[0].date,
          spent: 0,
          impressions: 0,
          clicks: 0,
          cpc: 0,
          ctr: 0,
          currency: '',
        });

        const campaignTotalStats = R.pipe(
          R.groupWith<CampaignGraphStat>((a,b) => a.campaignId === b.campaignId),
          R.map(campaignGroup => {
              return calculateTotalStat<CampaignGraphStat>(campaignGroup, {
                account: campaignGroup[0].account,
                campaignId: campaignGroup[0].campaignId,
                platform: 'ALL',
                extendedPlatform: 'ALL',
                date: group[0].date,
                spent: 0,
                impressions: 0,
                clicks: 0,
                cpc: 0,
                ctr: 0,
                currency: '',
              });
          }),
        )(group);

        const platformTotalStats = R.pipe(
          R.groupWith<CampaignGraphStat>((a,b) => a.platform === b.platform),
          R.map(platformGroup => {
              return calculateTotalStat<CampaignGraphStat>(platformGroup, {
                account: 'ALL',
                campaignId: '',
                platform: platformGroup[0].platform,
                extendedPlatform: platformGroup[0].extendedPlatform,
                date: group[0].date,
                spent: 0,
                impressions: 0,
                clicks: 0,
                cpc: 0,
                ctr: 0,
                currency: '',
              });
          }),
        )(group);

        return R.pipe(
          R.concat(campaignTotalStats),
          R.concat(platformTotalStats),
        )([totalStat]);
    }),
    R.flatten,
  )(stats);

const calculateTotalStat = <T extends BaseStat>(group: T[], initial: T): T => {
  const totalStat = group.reduce((acc, v) => {
    if (isStat(acc) && isStat(v)) {
      acc.monthSpent = acc.monthSpent + v.monthSpent;
      if ((acc as Stat).state === 'READY') {
        acc.state = v.state;
      }
    }

    acc.spent = acc.spent + v.spent;
    acc.impressions = acc.impressions + v.impressions;
    acc.clicks = acc.clicks + v.clicks;

    return acc;
  }, initial);

  // https://github.com/microsoft/TypeScript/wiki/Breaking-Changes/83af27fca396d172b4d895d480b10c3bacf89112#-k-string-unknown--is-no-longer-a-wildcard-assignment-target
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currencies: string[] = R.pipe(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    R.map((v: T) => v.currency),
    R.reject(v => v === ''),
    R.uniq,
  )(group);

  if (currencies.length > 1) {
    totalStat.currency = 'mixed';
  } else if (currencies.length === 1) {
    totalStat.currency = currencies[0];
  }

  totalStat.cpc =
    totalStat.clicks === 0
      ? 0
      : numberHelpers.round(totalStat.spent / totalStat.clicks, 2);

  totalStat.ctr =
    totalStat.impressions === 0
      ? 0
      : numberHelpers.round(
          (100 * totalStat.clicks) / totalStat.impressions,
          2,
        );

  if (group.length === 0 && isStat(totalStat)) {
    totalStat.state = 'NOT_READY';
  }

  return totalStat;
};

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
const isStat = (stat: BaseStat): stat is Stat => {
  return (stat as Stat).monthSpent !== undefined;
};

export default {
  filterStatsForDashboard,
  sumGraphStats,
  sumAccountGraphStats,
  sumCampaignGraphStats,
};
