/** @format */

import {useMemo} from 'react';

import {styled} from '@mui/material/styles';

import {
  Box,
  Container,
  CssBaseline,
  Hidden,
  Link,
  Typography,
} from '@mui/material';
import {Trans} from 'react-i18next';

import routerHelpers from 'helpers/routerHelpers';

const PREFIX = 'AuthLayout';

const classes = {
  root: `${PREFIX}-root`,
  headContainer: `${PREFIX}-headContainer`,
  container: `${PREFIX}-container`,
  logo: `${PREFIX}-logo`,
  home: `${PREFIX}-home`,
  homeLink: `${PREFIX}-homeLink`,
  logoText: `${PREFIX}-logoText`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },

  [`& .${classes.headContainer}`]: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  [`& .${classes.container}`]: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(11),
      width: 526,
    },
  },

  [`& .${classes.logo}`]: {
    height: 34,
  },

  [`& .${classes.home}`]: {
    height: 24,
    weight: 24,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.homeLink}`]: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.logoText}`]: {
    fontSize: 20,
    fontWeight: 700,
  },
}));

const AuthLayout = props => {
  const domain = useMemo(() => routerHelpers.secondLevelDomain(), []);

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <Container className={classes.headContainer}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 22,
            mt: 13,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Hidden mdDown>
              <img
                alt='Logo'
                className={classes.logo}
                src={new URL('./images/logo.svg', import.meta.url)}
              />
            </Hidden>
          </Box>
          <Box>
            <Link href={`https://${domain}/`}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  alt='home page'
                  className={classes.home}
                  src={new URL('./images/home.png', import.meta.url)}
                />
                <Typography className={classes.homeLink}>
                  <Trans>На главную</Trans>
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Container>
      <Container className={classes.container} disableGutters maxWidth='sm'>
        {props.children}
      </Container>
    </Root>
  );
};

export default AuthLayout;
