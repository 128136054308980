/** @format */

import {Button} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledButton = styled(Button)(({theme}) => ({
  backgroundColor: theme.palette.success.main,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 16,
  fontWeight: '700',
  height: 48,
  lineHeight: '20px',
  padding: '14px 24px',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '&:disabled': {
    backgroundColor: '#4D4D4D',
    color: '#DBDBDB',
    fontWeight: 'normal',
  },
  '&:hover': {
    backgroundColor: '#648F13',
  },
}));

const PrimaryMediumButton = props => (
  <StyledButton fullWidth={false} {...props} />
);

export default PrimaryMediumButton;
