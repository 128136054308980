/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import {clsx} from 'clsx';
import {Box, Grow, IconButton, Typography, useMediaQuery} from '@mui/material';
import {Trans} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

const PREFIX = 'ClosableBanner';

const classes = {
  container: `${PREFIX}-container`,
  containerNew: `${PREFIX}-containerNew`,
  containerNewYellow: `${PREFIX}-containerNewYellow`,
  containerNewRed: `${PREFIX}-containerNewRed`,
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
  closeIcon: `${PREFIX}-closeIcon`,
  closeButtonNew: `${PREFIX}-closeButtonNew`,
  closeButtonDemo: `${PREFIX}-closeButtonDemo`,
  contactButtonNew: `${PREFIX}-contactButtonNew`,
  contactIcon: `${PREFIX}-contactIcon`,
  titleNew: `${PREFIX}-titleNew`,
  textNew: `${PREFIX}-textNew`,
  leftIconNew: `${PREFIX}-leftIconNew`,
  innerNew: `${PREFIX}-innerNew`,
  innerDemo: `${PREFIX}-innerDemo`,
  demoButtonsContainer: `${PREFIX}-demoButtonsContainer`,
};

interface Props {
  errorType?: 'is_demo' | 'sensor_incorrect';
  iconName?: string;
  title?: string | null;
  text: string;
  onClose: () => void;
}

const StyledGrow = styled(Grow)<{iconName: string}>(({theme, iconName}) => ({
  width: '100%',
  [`& .${classes.container}`]: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.action.disabledBackground,
    borderBottom: '1px solid #000000',
    display: 'flex',
    paddingBottom: iconName ? 0 : theme.spacing(3),
    paddingTop: iconName ? 0 : theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(3),
      paddingRight: iconName ? 0 : theme.spacing(10),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },

  [`& .${classes.containerNew}`]: {
    alignSelf: 'stretch',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.containerNewYellow}`]: {
    background: '#AA8258',
  },

  [`& .${classes.containerNewRed}`]: {
    background: '#AE5658',
  },

  [`& .${classes.text}`]: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: theme.spacing(2),
    },
  },

  [`& .${classes.icon}`]: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'end',
    },
  },

  [`& .${classes.closeIcon}`]: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },

  [`& .${classes.closeButtonNew}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '46px',
    height: '46px',
    marginLeft: 'auto',
    borderRadius: '8px',
    border: '1px solid #FFF',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '14px',
    },
  },

  [`& .${classes.closeButtonDemo}`]: {
    marginLeft: '16px',
  },

  [`& .${classes.contactButtonNew}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    borderRadius: '8px',
    border: '1px solid #FFF',
    color: '#FFF',
    fontSize: ' 14px',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginLeft: '0',
    },
  },

  [`& .${classes.contactIcon}`]: {
    marginRight: '10px',
  },

  [`& .${classes.titleNew}`]: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
  },

  [`& .${classes.textNew}`]: {
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  },

  [`& .${classes.leftIconNew}`]: {
    marginRight: '16px',
  },

  [`& .${classes.innerNew}`]: {
    width: '936px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    padding: '16px 0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'center',
      padding: '16px',
    },
  },

  [`& .${classes.innerDemo}`]: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.demoButtonsContainer}`]: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '16px',
      justifyContent: 'flex-end',
    },
  },
}));

const ClosableBanner = (props: Props) => {
  const [open, setOpen] = useState(false);

  const {isNewVersionEnabled} = useFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  const renderIcon = () => {
    if (!props.iconName) return null;

    const iconSrc = isMobile
      ? new URL(`./images/${props.iconName}_mobile.svg`, import.meta.url)
      : new URL(`./images/${props.iconName}_desktop.svg`, import.meta.url);

    return <img alt='Icon' className={classes.icon} src={iconSrc.toString()} />;
  };

  // @@TODO hidden until Demo Account Flag is ready
  // const onContact = () => {};

  return isNewVersionEnabled ? (
    <StyledGrow in={open} iconName={props.iconName || ''}>
      <Box>
        <Box
          className={clsx(
            classes.containerNew,
            props.errorType === 'sensor_incorrect' &&
              classes.containerNewYellow,
            props.errorType === 'is_demo' && classes.containerNewRed,
          )}
        >
          <Box
            className={clsx(
              classes.innerNew,
              props.errorType === 'is_demo' && classes.innerDemo,
            )}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {props.errorType !== 'is_demo' && (
                <Box>
                  {props.errorType === 'sensor_incorrect' ? (
                    <ErrorOutlineIcon
                      className={classes.leftIconNew}
                      htmlColor='#FFFFFF'
                      fontSize='large'
                    />
                  ) : (
                    <QueryBuilderIcon
                      className={classes.leftIconNew}
                      htmlColor='#FFFFFF'
                      fontSize='large'
                    />
                  )}
                </Box>
              )}
              <Box>
                <Typography className={classes.titleNew}>
                  {props.title}
                </Typography>
                <Typography className={classes.textNew}>
                  {props.text}
                </Typography>
              </Box>
            </Box>

            {props.errorType === 'is_demo' ? (
              <Box className={classes.demoButtonsContainer}>
                <IconButton
                  // onClick={props.onContact}
                  className={classes.contactButtonNew}
                  size='large'
                >
                  <QuestionAnswerOutlinedIcon
                    className={classes.contactIcon}
                    fontSize='small'
                    htmlColor='#FFFFFF'
                  />
                  <Trans>Связаться</Trans>
                </IconButton>

                <IconButton
                  className={clsx(
                    classes.closeButtonNew,
                    classes.closeButtonDemo,
                  )}
                  onClick={props.onClose}
                  size='large'
                >
                  <CloseIcon fontSize='small' htmlColor='#FFFFFF' />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                className={classes.closeButtonNew}
                onClick={props.onClose}
                size='large'
              >
                <CloseIcon fontSize='small' htmlColor='#FFFFFF' />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </StyledGrow>
  ) : (
    <StyledGrow in={open} iconName={props.iconName || ''}>
      <Box>
        <Box className={classes.container}>
          {renderIcon()}
          <Box
            sx={{
              py: 1,
            }}
          >
            <Typography className={classes.text}>{props.text}</Typography>
          </Box>
          <IconButton
            className={classes.closeIcon}
            onClick={props.onClose}
            size='large'
          >
            <img
              alt='Close'
              src={new URL('./images/close.svg', import.meta.url).toString()}
            />
          </IconButton>
        </Box>
      </Box>
    </StyledGrow>
  );
};

ClosableBanner.defaultProps = {
  iconName: null,
};

export default ClosableBanner;
