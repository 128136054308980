/** @format */

import {useState} from 'react';

import {Box, Grid, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Info from './Info';
import Plan from './Plan';
import paymentHelpers from 'helpers/paymentHelpers';

export const DAILY_PLANS = [
  {
    id: '1',
    type: 'daily',
    companiesText: 'От 1 до 3 проектов',
    description: 'Подойдет для вашего бизнеса',
    maxCompanies: 3,
    name: 'Стандартный',
    price: 4999,
    priceText: '4 999',
  },
  {
    id: '2',
    type: 'daily',
    companiesText: 'От 4 до 10 проектов',
    description: 'Подойдет для небольшого бутикового агентства',
    maxCompanies: 10,
    name: 'Оптимальный',
    price: 9999,
    priceText: '9 999',
  },
  {
    id: '3',
    type: 'daily',
    companiesText: 'Без ограничений в количестве проектов',
    description: 'Подойдет для крупного агентства',
    maxCompanies: 9999,
    name: 'Премиум',
    price: 19999,
    priceText: '19 999',
  },
];

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '28px',
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-25),
      marginRight: theme.spacing(-25),
    },
  },
  link: {
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
}));

const DailyPlans = props => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => setShowAll(true);

  return (
    <>
      <Typography className={classes.title}>Ежедневный мониторинг</Typography>

      <Info
        companiesCount={props.companies.length}
        title={'Мы каждый день будем проверять'}
        totalStats={props.totalStats}
      />

      <Box mt={10}>
        {showAll ? (
          <Box className={classes.gridContainer}>
            <Grid container spacing={4}>
              {DAILY_PLANS.map(plan => (
                <Grid key={plan.id} item xs={12} sm={6} md={4}>
                  <Plan
                    allPlans={DAILY_PLANS}
                    companiesCount={props.companies.length}
                    plan={plan}
                    subscription={props.subscription}
                    onChangePlan={props.onChangePlan}
                    onRenewPlan={props.onRenewPlan}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Plan
            allPlans={DAILY_PLANS}
            companiesCount={props.companies.length}
            plan={paymentHelpers.planToShow(
              DAILY_PLANS,
              props.subscription,
              props.companies.length,
            )}
            subscription={props.subscription}
            onChangePlan={props.onChangePlan}
            onRenewPlan={props.onRenewPlan}
          />
        )}
      </Box>

      {!showAll && (
        <Box mt={4}>
          <Link
            className={classes.link}
            component='button'
            onClick={handleShowAll}
          >
            <Typography variant='body2'>Другие тарифы</Typography>
          </Link>
        </Box>
      )}
    </>
  );
};

export default DailyPlans;
