/** @format */

import {Box, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '28px',
    },
    body: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
  }),
);

const WaitForPayment = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClick = () => {
    navigate('/home/main', {replace: true});
  };

  return (
    <>
      <Typography className={classes.title}>
        <Trans>
          Оплата может занимать до 15 минут, отправляйтесь на главную
        </Trans>
      </Typography>

      <Box mt={4}>
        <Typography className={classes.body}>
          <Trans>Мы начнём проверки, как только пройдёт оплата.</Trans>
        </Typography>
      </Box>

      <Box mt={16}>
        <PrimaryMediumButton onClick={handleClick}>
          <Trans>На главную</Trans>
        </PrimaryMediumButton>
      </Box>
    </>
  );
};

export default WaitForPayment;
