/** @format */

import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles/createTheme' {
  interface Theme {}
}

const theme = createTheme({
  spacing: 4,
  palette: {
    mode: 'light',
    primary: {
      main: '#121212',
    },
    secondary: {
      main: '#121212',
    },
  },
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: '700',
      lineHeight: '32px',
      whiteSpace: 'pre-line',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
  },
});

export default theme;
