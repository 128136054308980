/** @format */

import {Box, Typography} from '@mui/material';

import BackHeader from 'components/BackHeader';

const NoSubscription = () => {
  return (
    <>
      <Box mb={10}>
        <BackHeader />
      </Box>
      <Typography variant='h1'>{'Информация о подписке'}</Typography>

      <Box mt={4}>
        <Typography variant='body1'>
          {'У вас нет активной подписки.'}
        </Typography>
      </Box>
    </>
  );
};

export default NoSubscription;
