/** @format */

import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
const PREFIX = 'Error';

const classes = {
  body: `${PREFIX}-body`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.body}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },
}));

const Error = props => {
  return (
    <StyledBox mt={5}>
      <Typography className={classes.body}>{props.text}</Typography>
    </StyledBox>
  );
};

export default Error;
