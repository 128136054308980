/** @format */

export const canCreateCompany = (
  totalCompanies: number,
  aliveCompanies: number,
  totalRestriction: number | null,
  aliveRestriction: number | null
): boolean => {
  if (totalRestriction != null && totalCompanies >= totalRestriction) {
    return false;
  }
  if (aliveRestriction != null && aliveCompanies >= aliveRestriction) {
    return false;
  }

  return true;
};
