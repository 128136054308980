/** @format */
import {ChangeEvent} from 'react';

import {Box, InputAdornment} from '@mui/material';
import {useTranslation} from 'react-i18next';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import LegacyTextField from 'components/inputs/LegacyTextField';
import imageHelpers from 'helpers/imageHelpers';
import type {Provider} from 'types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: '0',
      },
    },
    providerIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '64px',
      height: '64px',
      marginRight: '16px',
      borderRadius: '50%',
      background: '#2B2B2B',
    },
    textField: {
      width: 'calc(100% - 70px)',
    },
    roubleIcon: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
  }),
);

type Props = {
  provider: Provider;
  value?: number;
  onChange(provider: Provider, value: number): void;
};

const FormRowNew = (props: Props) => {
  const {t} = useTranslation();

  const classes = useStyles();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    props.onChange(props.provider, Number(value));
  };

  return (
    <Box display='flex' alignItems='center' className={classes.container}>
      <Box className={classes.providerIconContainer}>
        <img
          alt='Logo'
          src={imageHelpers.providerNewIconSrc(props.provider, 40)}
        />
      </Box>

      <LegacyTextField
        className={classes.textField}
        rightIconClassName={classes.roubleIcon}
        placeholder={t('Укажите сумму в месяц', 'Укажите сумму в месяц')}
        value={props.value || ''}
        aria-label={`mediaplan-form-row-${props.provider}`}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <img
                alt='Rouble icon'
                src={String(
                  new URL('./images/rouble_icon.svg', import.meta.url),
                )}
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default FormRowNew;
