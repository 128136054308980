/** @format */

import {Box, Link} from '@mui/material';
import {Trans} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(_theme => ({
  text: {
    color: '#949494',
    fontSize: 12,
  },
  link: {
    color: '#1976D2',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.text} mt={8}>
      <Trans>
        Нажимая кнопку, вы соглашаетесь c{' '}
        <Link
          className={classes.link}
          href='/privacy_policy.pdf'
          target='_blank'
        >
          политикой конфиденциальности
        </Link>{' '}
        и{' '}
        <Link className={classes.link} href='/offer.pdf' target='_blank'>
          офертой
        </Link>{' '}
        и подтверждаете свое согласие на{' '}
        <Link
          className={classes.link}
          href='/personal_data_processing.pdf'
          target='_blank'
        >
          обработку персональных данных
        </Link>
        .
      </Trans>
    </Box>
  );
};

export default Footer;
