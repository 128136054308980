/** @format */

import {Tab as MaterialTab, TabProps} from '@mui/material';
import {styled} from '@mui/material/styles';

interface Props extends TabProps {
  variant: string;
}

const Tab = styled(MaterialTab)<Props>(({theme, variant}) => ({
  color: '#858585',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  minWidth: 'unset',
  opacity: 1,
  padding: 0,
  paddingRight: 0,
  maxWidth: '100%',
  flexGrow: 1,
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#363636',
    color: variant === 'DARK' ? theme.palette.text.primary : '#000000',
  },
}));

const StyledTab = (props: Props) => {
  return <Tab {...props} />;
};

export default StyledTab;
