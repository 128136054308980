/** @format */

import {useMemo, useState} from 'react';

import {styled} from '@mui/material/styles';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, CircularProgress, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {add, format} from 'date-fns';

import MetricGraph from 'components/graphs/MetricGraph';
import PrimarySelect from 'components/selects/PrimarySelect';
import SpendGraph from 'components/graphs/SpendGraph';
import statsHelpers from 'helpers/statsHelpers';
import type {
  ExtendedPlatform,
  GraphStat,
  AdTypeMediaplan,
  PlatformGraphStat,
} from 'types';

const PREFIX = 'Graphs';

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
};

const Root = styled('div')(() => ({
  [`& .${classes.loadingContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    height: 562,
    justifyContent: 'center',
    width: '100%',
  },
}));

type MenuValue = '30_DAYS' | '60_DAYS' | '90_DAYS';

type MenuOption = {
  value: MenuValue;
  days: number;
};

const MENU_OPTIONS: MenuOption[] = [
  {value: '30_DAYS', days: 30},
  {value: '60_DAYS', days: 60},
  {value: '90_DAYS', days: 90},
];

const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      currentCompany {
        graphStats(startDate: $startDate, endDate: $endDate) {
          currency
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
      }
    }
  }
`;

type Props = {
  extendedPlatform: ExtendedPlatform;
  mediaplan: AdTypeMediaplan | null;
};

const Graphs = (props: Props) => {
  const {t} = useTranslation();

  const [menuOption, setMenuOption] = useState(MENU_OPTIONS[0]);

  const now = new Date();
  const startDate = add(now, {days: -menuOption.days});
  const endDate = add(now, {days: -1});

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });

  const platformGraphStats = useMemo(() => {
    if (!data) return [];

    const {graphStats} = data.currentUser.currentCompany;
    const totalStats = statsHelpers.sumGraphStats(graphStats);
    const allStats: GraphStat[] = graphStats.concat(totalStats);

    return R.pipe(
      // https://stackoverflow.com/a/60227128/3632318
      R.filter<GraphStat>(v => v.extendedPlatform === props.extendedPlatform),
      R.sortBy<GraphStat>(v => v.date),
      R.map<GraphStat, PlatformGraphStat>(v => ({
        date: v.date,
        spent: v.spent,
        impressions: v.impressions,
        clicks: v.clicks,
        cpc: v.cpc,
        ctr: v.ctr,
        currency: v.currency,
      })),
    )(allStats);
  }, [props.extendedPlatform, data]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const handleSelectPeriod = (value: string) => {
    const newMenuOption = MENU_OPTIONS.find(v => v.value === value);
    setMenuOption(newMenuOption as MenuOption);
  };

  const selectOptions = MENU_OPTIONS.map(v => ({
    value: v.value,
    text: t('За {{days}} дней', 'За {{days}} дней', {days: v.days}),
  }));

  const planValue = () => {
    if (props.mediaplan == null) {
      return 0;
    }
    return props.mediaplan['dayly'];
  };

  return (
    <Root>
      <Box
        sx={{
          alignItems: 'baseline',
          display: 'flex',
        }}
      >
        <Typography variant='h1'>
          <Trans>Динамика</Trans>
        </Typography>
        <Box
          sx={{
            ml: 5,
          }}
        >
          <PrimarySelect
            options={selectOptions}
            value={menuOption.value}
            onSelect={handleSelectPeriod}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 5,
        }}
      >
        <Typography variant='body1'>
          <Trans>Расход бюджета по дням</Trans>
        </Typography>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <SpendGraph
            platformGraphStats={platformGraphStats}
            planValue={planValue()}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 10,
        }}
      >
        <MetricGraph platformGraphStats={platformGraphStats} />
      </Box>
    </Root>
  );
};

export default Graphs;
