/** @format */
import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'Hint';

const classes = {
  hint: `${PREFIX}-hint`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.hint}`]: {
    backgroundColor: theme.palette.background.default,
    color: '#A6A6A6',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

type Props = {
  text: string;
};

const Hint = (props: Props) => {
  return (
    <StyledBox mt={1}>
      <Typography className={classes.hint}>{props.text}</Typography>
    </StyledBox>
  );
};

export default Hint;
