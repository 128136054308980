/** @format */

import {Box, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Theme} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

import BoxPlate from 'components/plates/BoxPlate';
import ConnectRow from 'components/connect/ConnectRow';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';

import type {Platform, Provider, ProviderTokenAccount, UserRole} from 'types';

const PREFIX = 'ConnectRows';

const classes = {
  adTypeText: `${PREFIX}-adTypeText`,
  adTypeTextNew: `${PREFIX}-adTypeTextNew`,
  tuneMediaplanButton: `${PREFIX}-tuneMediaplanButton`,
  tuneMediaplanIcon: `${PREFIX}-tuneMediaplanIcon`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.adTypeText}`]: {
    color: '#8F8F8F',
  },

  [`& .${classes.adTypeTextNew}`]: {
    color: '#F1F1F1',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.169px',
  },

  [`& .${classes.tuneMediaplanButton}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    borderRadius: '8px',
    background: '#202020',
    border: 'none',
    width: 'auto !important',
  },

  [`& .${classes.tuneMediaplanIcon}`]: {
    marginRight: theme.spacing(2),
  },
}));

export type ProviderToken = {
  lastError: string | null;
  provider: Provider;
  accounts: ProviderTokenAccount[];
};

interface Props {
  canEditLimits: boolean;
  canSelectSubaccounts: boolean;
  companyId: string;
  needPay: boolean;
  tokens: ProviderToken[];
  platforms: Platform[];
  showMetaExtremismCaption: boolean;
  userRole: UserRole[];
}

const ConnectRows = (props: Props) => {
  const {needPay = false} = props;

  const navigate = useNavigate();
  const location = useLocation();

  const {isNewVersionEnabled} = useFlags();

  const handleClickLimit = (adType: string) => {
    navigate(
      {
        pathname: '/home/settings/mediaplan',
        search: `?companyId=${props.companyId}&adType=${adType}`,
      },
      {
        state: {background: location},
      },
    );
  };

  const renderContextRows = () => {
    if (isNewVersionEnabled) {
      return (
        <Root>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 8,
            }}
          >
            <Typography className={classes.adTypeTextNew}>
              <Trans>Контекст</Trans>
            </Typography>

            {props.canEditLimits && (
              <TransparentSmallButton
                className={classes.tuneMediaplanButton}
                onClick={() => handleClickLimit('context')}
              >
                <TuneOutlinedIcon
                  className={classes.tuneMediaplanIcon}
                  fontSize='small'
                />

                <Trans>Настроить медиаплан</Trans>
              </TransparentSmallButton>
            )}
          </Box>
          <Box
            sx={{
              mt: 6,
            }}
          >
            <Grid container spacing={5}>
              {props.platforms.includes('YANDEX') && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='YANDEX'
                    providerToken={props.tokens.find(
                      v => v.provider === 'YANDEX',
                    )}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
              {props.platforms.includes('GOOGLE') && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='GOOGLE'
                    providerToken={props.tokens.find(
                      v => v.provider === 'GOOGLE',
                    )}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Root>
      );
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.adTypeText} variant='body1'>
            <Trans>Контекст</Trans>
          </Typography>

          {props.canEditLimits && (
            <TransparentSmallButton onClick={() => handleClickLimit('context')}>
              <Trans>Настроить лимиты</Trans>
            </TransparentSmallButton>
          )}
        </Box>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <Grid container spacing={5}>
            {props.platforms.includes('YANDEX') && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='YANDEX'
                  providerToken={props.tokens.find(
                    v => v.provider === 'YANDEX',
                  )}
                  userRole={props.userRole}
                />
              </Grid>
            )}
            {props.platforms.includes('GOOGLE') && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='GOOGLE'
                  providerToken={props.tokens.find(
                    v => v.provider === 'GOOGLE',
                  )}
                  userRole={props.userRole}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    );
  };

  const renderTargetRows = () => {
    const facebookToken = props.tokens.find(v => v.provider === 'FACEBOOK');

    if (isNewVersionEnabled) {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography className={classes.adTypeTextNew}>
              <Trans>Таргет</Trans>
            </Typography>

            {props.canEditLimits && (
              <TransparentSmallButton
                className={classes.tuneMediaplanButton}
                onClick={() => handleClickLimit('target')}
              >
                <TuneOutlinedIcon
                  className={classes.tuneMediaplanIcon}
                  fontSize='small'
                />

                <Trans>Настроить медиаплан</Trans>
              </TransparentSmallButton>
            )}
          </Box>
          <Box
            sx={{
              mt: 6,
            }}
          >
            <Grid container spacing={5}>
              {props.platforms.includes('FACEBOOK') && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='FACEBOOK'
                    providerToken={facebookToken}
                    showMetaExtremismCaption={props.showMetaExtremismCaption}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
              {props.platforms.includes('VKONTAKTE') && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='VKONTAKTE'
                    providerToken={props.tokens.find(
                      v => v.provider === 'VKONTAKTE',
                    )}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
              {props.platforms.includes('VKONTAKTE_ADS') &&
                props.userRole.includes('VK_ADS') && (
                  <Grid item xs={12}>
                    <ConnectRow
                      canSelectSubaccounts={props.canSelectSubaccounts}
                      companyId={props.companyId}
                      needPay={needPay}
                      provider='VKONTAKTE_ADS'
                      providerToken={props.tokens.find(
                        v => v.provider === 'VKONTAKTE_ADS',
                      )}
                      userRole={props.userRole}
                    />
                  </Grid>
                )}
            </Grid>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.adTypeText} variant='body1'>
            <Trans>Таргет</Trans>
          </Typography>

          {props.canEditLimits && (
            <TransparentSmallButton onClick={() => handleClickLimit('target')}>
              <Trans>Настроить лимиты</Trans>
            </TransparentSmallButton>
          )}
        </Box>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <Grid container spacing={5}>
            {props.platforms.includes('FACEBOOK') && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='FACEBOOK'
                  providerToken={facebookToken}
                  showMetaExtremismCaption={props.showMetaExtremismCaption}
                  userRole={props.userRole}
                />
              </Grid>
            )}
            {props.platforms.includes('VKONTAKTE') && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='VKONTAKTE'
                  providerToken={props.tokens.find(
                    v => v.provider === 'VKONTAKTE',
                  )}
                  userRole={props.userRole}
                />
              </Grid>
            )}
            {props.platforms.includes('VKONTAKTE_ADS') &&
              props.userRole.includes('VK_ADS') && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='VKONTAKTE_ADS'
                    providerToken={props.tokens.find(
                      v => v.provider === 'VKONTAKTE_ADS',
                    )}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
          </Grid>
        </Box>
      </>
    );
  };

  const renderStatisticRows = () => {
    const metrikaToken = props.tokens.find(v => v.provider === 'METRIKA');
    const webdavToken = props.tokens.find(v => v.provider === 'WEBDAV');
    const displayMetrika =
      props.platforms.includes('METRIKA') && props.userRole.includes('METRIKA');
    const displayWebdav =
      props.platforms.includes('WEBDAV') && props.userRole.includes('WEBDAV');

    if (isNewVersionEnabled) {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 8,
            }}
          >
            <Typography className={classes.adTypeTextNew}>
              <Trans>Статистика</Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 6,
            }}
          >
            <Grid container spacing={5}>
              {displayMetrika && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='METRIKA'
                    providerToken={metrikaToken}
                    userRole={props.userRole}
                  />
                </Grid>
              )}
              {displayWebdav && (
                <Grid item xs={12}>
                  <ConnectRow
                    canSelectSubaccounts={props.canSelectSubaccounts}
                    companyId={props.companyId}
                    needPay={needPay}
                    provider='WEBDAV'
                    providerToken={webdavToken}
                    userRole={props.userRole}
                    onConnect={() =>
                      alert('Для подключения обратитесь в поддердку')
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.adTypeText} variant='body1'>
            <Trans>Статистика</Trans>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <Grid container spacing={5}>
            {displayMetrika && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='METRIKA'
                  providerToken={metrikaToken}
                  userRole={props.userRole}
                />
              </Grid>
            )}
            {displayWebdav && (
              <Grid item xs={12}>
                <ConnectRow
                  canSelectSubaccounts={props.canSelectSubaccounts}
                  companyId={props.companyId}
                  needPay={needPay}
                  provider='WEBDAV'
                  providerToken={webdavToken}
                  userRole={props.userRole}
                  onConnect={() =>
                    alert('Для подключения обратитесь в поддержку')
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    );
  };

  const displayStatisticRows =
    (props.platforms.includes('METRIKA') &&
      props.userRole.includes('METRIKA')) ||
    (props.platforms.includes('WEBDAV') && props.userRole.includes('WEBDAV'));

  return (
    <>
      {isNewVersionEnabled ? (
        <>
          {renderContextRows()}

          <Box
            sx={{
              mt: 8,
            }}
          >
            {renderTargetRows()}
          </Box>

          {displayStatisticRows && renderStatisticRows()}
        </>
      ) : (
        <>
          <BoxPlate>{renderContextRows()}</BoxPlate>

          <Box
            sx={{
              mt: 2,
            }}
          >
            <BoxPlate>{renderTargetRows()}</BoxPlate>
          </Box>

          {displayStatisticRows && (
            <Box
              sx={{
                mt: 2,
              }}
            >
              <BoxPlate>{renderStatisticRows()}</BoxPlate>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ConnectRows;
