/** @format */

const constants = {
  BULLET: '\u2022',
  CHECK_MARK: '\u2713',
  EM_DASH: '\u2014',
  EN_DASH: '\u2013',
  NBSP: '\u00A0',
  PLUS: '\uFF0B',
  RUBLE: '\u20BD',
  THIN_SPACE: '\u2009',
  ZWSP: '\u200B',
};

export default constants;
