/** @format */
import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {Theme} from '@mui/material/styles';
import {gql, useQuery} from '@apollo/client';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';

const PREFIX = 'GetHelp';

const classes = {
  text: `${PREFIX}-text`,
};

const Root = styled('div')(() => ({
  [`& .${classes.text}`]: {
    color: '#A4A4A4',
  },
}));

interface currentUser {
  currentUser: {
    phone: string;
    profile?: {
      id: number;
      name?: string;
    };
  };
}

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      phone
      profile {
        id
        name
      }
    }
  }
`;

const GetHelp = () => {
  const [state, setState] = useState({phone: '', name: ''});
  const [sentSuccess, setSentSuccess] = useState(false);

  // const {mixpanel} = useMixpanelContext();
  const {t} = useTranslation();

  const {loading, error, data} = useQuery<currentUser>(GET_CURRENT_USER);

  useEffect(() => {
    if (data != null) {
      const {phone, profile} = data.currentUser;

      const name = profile && profile.name ? profile.name : '';
      setState({phone, name});
    }
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) {
    return <Alert severity='error'>{error}</Alert>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleChangePhone = (value: string) => {
    setState({...state, phone: value});
  };

  const handleClick = async () => {
    await fetch(
      'https://hook.integromat.com/rl2fvk8y0ohs7t5jnvdccqmxa03f1jis',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      },
    );
    setSentSuccess(true);
  };

  return (
    <Root>
      <Box
        sx={{
          mb: 8,
        }}
      >
        <Typography variant='h1'>
          <Trans>Запрос консультации</Trans>
        </Typography>
      </Box>
      {sentSuccess && (
        <>
          <Typography variant='body1'>
            <Trans>Заявка на консультацию отправлена</Trans>
          </Typography>
          <Typography variant='body1' className={classes.text}>
            <Trans>Мы свяжемся с вами в ближайшее время!</Trans>
          </Typography>
        </>
      )}
      {!sentSuccess && (
        <>
          <Typography variant='body1' className={classes.text}>
            <Trans>
              Оставьте заявку, наш менеджер свяжется с вами и поможет начать
              пользоваться сервисом
            </Trans>
          </Typography>
          <Box
            sx={{
              my: 6,
            }}
          >
            <LegacyTextField
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              placeholder={t('Как вас зовут', 'Как вас зовут')}
              value={state.name}
              onChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              mb: 6,
            }}
          >
            <PhoneNumberField
              value={state.phone}
              onChange={handleChangePhone}
            />
          </Box>
          <PrimaryMediumButton
            disabled={loading || !state.phone || !state.name}
            onClick={handleClick}
          >
            <Trans>Отправить</Trans>
          </PrimaryMediumButton>
        </>
      )}
    </Root>
  );
};

export default GetHelp;
