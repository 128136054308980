/** @format */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';

const PREFIX = 'ArrowBack';

const classes = {
  button: `${PREFIX}-button`,
  buttonText: `${PREFIX}-buttonText`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.button}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.buttonText}`]: {
    color: '#949494',
  },
}));

interface Props {
  children: React.ReactChild;
  onClick: () => void;
}

const ArrowBack = (props: Props) => {
  return (
    <StyledBox
      className={classes.button}
      display='flex'
      alignItems='center'
      onClick={props.onClick}
    >
      <ArrowBackIosIcon htmlColor='#000' />
      <Typography className={classes.buttonText}>{props.children}</Typography>
    </StyledBox>
  );
};

export default ArrowBack;
