/** @format */

import {ChangeEvent} from 'react';

import {styled} from '@mui/material/styles';

import {MenuItem, Select} from '@mui/material';

const PREFIX = 'PrimarySelect';

const classes = {
  menuSelect: `${PREFIX}-menuSelect`,
  menuItem: `${PREFIX}-menuItem`,
};

const StyledSelect = styled(Select)(({theme}) => ({
  [`&.${classes.menuSelect}`]: {
    lineHeight: '8px',
    backgroundColor: 'transparent !important',
    '&:hover': {
      background: 'transparent',
    },
  },

  // Disable hover
  [`& .${classes.menuItem}`]: {
    '&:hover': {
      // theme.js: MuiMenu.paper.backgroundColor
      backgroundColor: '#4A4A4A',
    },
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

type Option = {
  value: string;
  text: string;
};

type Props = {
  options: Option[];
  value: string;
  onSelect: (id: string) => void;
};

const PrimarySelect = (props: Props) => {
  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  return (
    <StyledSelect
      disableUnderline
      value={props.value}
      // @ts-ignore
      onChange={handleChange}
      className={classes.menuSelect}
      variant='filled'
    >
      {props.options.map((v, i) => (
        <MenuItem key={i} className={classes.menuItem} value={v.value}>
          {v.text}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default PrimarySelect;
