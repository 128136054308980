/** @format */
import {Box} from '@mui/material';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BoxPlate from 'components/plates/BoxPlate';
import BoxPlateText from 'components/Dashboard/BoxPlateText';
import BoxPlateTitle from 'components/Dashboard/BoxPlateTitle';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface Props {
  id: string;
  name: string;
}

const NoTokens = (props: Props) => {
  const navigate = useNavigate();
  const {mixpanel} = useMixpanelContext();

  const handleClickButton = () => {
    mixpanel.track('click_go_to_connect_on_no_tokes', {});

    navigate(`/home/settings/connect/company?companyId=${props.id}`);
  };

  return (
    <Box mt={7}>
      <BoxPlate>
        <BoxPlateTitle>
          <Trans>В проекте нет подключенных рекламных кабинетов.</Trans>
        </BoxPlateTitle>
        <Box my={4}>
          <BoxPlateText>
            <Trans>
              AdSensor ничего не проверяет.
              <br />
              Добавьте в проект рекламный кабинет, чтобы получить первый отчёт.
              Для этого перейдите на страницу подключения доступов и нажмите «+»
              напротив рекламной системы с активным рекламными аккаунтами,
              которые хотите проверить.
            </Trans>
          </BoxPlateText>
        </Box>
        <PrimaryMediumButton onClick={handleClickButton}>
          <Trans>Перейти к подключению</Trans>
        </PrimaryMediumButton>
      </BoxPlate>
    </Box>
  );
};

export default NoTokens;
