/** @format */

import {Routes, Route, useLocation} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import WizardLayout from 'layouts/WizardLayout';

import Help from './Help';
import InviteGuest from './InviteGuest';
import Modals from './Modals';
import {Connect} from './Connect';

const Wizard = () => {
  const location = useLocation();
  // https://reactrouter.com/web/example/modal-gallery
  const background = location.state && location.state.background;
  return (
    <WizardLayout>
      <Routes location={background || location}>
        <Route
          path={ROUTES.CONNECT}
          element={
            <PrivateRoute>
              <Connect />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.INVITE_GUEST}
          element={
            <PrivateRoute>
              <InviteGuest />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.HELP}
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />
      </Routes>

      {background && (
        <Routes>
          <Route
            path={ROUTES.WIZARD_BEFORE_CONNECT}
            element={
              <PrivateRoute>
                <Modals.BeforeConnect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_CONFIRM_DISCONNECT}
            element={
              <PrivateRoute>
                <Modals.ConfirmDisconnect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_SUBACCOUNTS}
            element={
              <PrivateRoute>
                <Modals.Subaccounts />
              </PrivateRoute>
            }
          />
        </Routes>
      )}
    </WizardLayout>
  );
};

export default Wizard;
