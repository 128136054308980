/** @format */

import {useState} from 'react';

import clsx from 'clsx';
import {Box, Button, Link, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import constants from 'helpers/constants';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '17px',
    overflowWrap: 'anywhere',
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: 'rgba(255, 255, 255, 0.3)',
  },
  showUrlText: {
    color: theme.palette.success.main,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '17px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.success.main,
    },
  },
  copyButton: {
    backgroundColor: '#393939',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '17px',
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 220,
    },
    '&:hover': {
      backgroundColor: '#393939',
    },
  },
}));

const ObjectLink = props => {
  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const [showUrl, setShowUrl] = useState(false);
  const [copied, setCopied] = useState(false);

  const url = props.reportItem.objects.link.value;

  const handleOpenLink = () => {
    mixpanel.track('click_object_link', {sensor: props.sensorName});
  };

  const handleShowUrl = () => {
    if (!showUrl) {
      mixpanel.track('click_show_url_button', {sensor: props.sensorName});
    }

    if (showUrl) setCopied(false);
    setShowUrl(!showUrl);
  };

  const handleCopy = () => {
    mixpanel.track('click_copy_url_button', {sensor: props.sensorName});
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const checkSign = constants.CHECK_MARK;

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between'>
        <Link
          className={clsx(classes.text, classes.underline)}
          href={url}
          target='_blank'
          onClick={handleOpenLink}
        >
          <Box alignItems='center' display='flex'>
            <Trans>Ссылка</Trans>
          </Box>
        </Link>

        <Link
          className={classes.showUrlButton}
          component='button'
          onClick={handleShowUrl}
        >
          <Box ml={2}>
            <Typography className={classes.showUrlText}>
              {showUrl ? (
                <Trans>Скрыть URL</Trans>
              ) : (
                <Trans>Показать URL</Trans>
              )}
            </Typography>
          </Box>
        </Link>
      </Box>

      {showUrl && (
        <Box alignItems='flex-start' display='flex' flexDirection='column'>
          <Box mt={4}>
            <Typography className={classes.text}>{url}</Typography>
          </Box>

          <Button className={classes.copyButton} onClick={handleCopy}>
            {copied ? (
              <Trans>Скопировано {{checkSign}}</Trans>
            ) : (
              <Trans>Скопировать</Trans>
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ObjectLink;
