/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import {Box, Grow, IconButton} from '@mui/material';

const PREFIX = 'ErrorBanner';

const classes = {
  container: `${PREFIX}-container`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledGrow = styled(Grow)(({theme}) => ({
  [`& .${classes.container}`]: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#B83857',
    display: 'flex',
    padding: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(12),
    },
  },

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

// https://www.carlrippon.com/react-children-with-typescript/
type Props = {
  onClose: () => void;
  children: React.ReactNode;
};

const ErrorBanner = (props: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  return (
    <StyledGrow in={open}>
      <Box className={classes.container}>
        {props.children}
        <IconButton
          className={classes.closeIcon}
          onClick={props.onClose}
          size='large'
        >
          <img
            alt='Close'
            src={new URL('./images/close.svg', import.meta.url).href}
          />
        </IconButton>
      </Box>
    </StyledGrow>
  );
};

export default ErrorBanner;
