/** @format */

import {Button} from '@mui/material';
import {styled} from '@mui/material/styles';
const StyledButton = styled(Button)(({theme}) => ({
  borderRadius: 16,
  fontSize: 16,
  fontWeight: '500',
  height: 64,
  lineHeight: '20px',
  padding: '14px 24px',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const PrimaryLargeButton = props => (
  <StyledButton fullWidth={false} {...props} />
);

export default PrimaryLargeButton;
