/** @format */

import {Backdrop, CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'Loading';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBackdrop = styled(Backdrop)(() => ({
  [`&.${classes.backdrop}`]: {
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    zIndex: 1,
  },
}));

const Loading = () => {
  return (
    <StyledBackdrop className={classes.backdrop} open>
      <CircularProgress />
    </StyledBackdrop>
  );
};

export default Loading;
