/** @format */

import Platforms from 'components/icons/Platforms';
import adTypeHelpers from 'helpers/adTypeHelpers';
import type {AdType, Platform} from 'types';

type Props = {
  adTypes: AdType[];
  bw?: boolean;
  size?: number;
};

const AdTypePlatforms = (props: Props) => {
  const {bw = false, size = 40} = props;

  const platforms = props.adTypes
    .sort()
    .map(v => adTypeHelpers.platformsFromAdType(v))
    .flat() as Platform[];

  return <Platforms bw={bw} platforms={platforms} size={size} />;
};

export default AdTypePlatforms;
