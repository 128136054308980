/** @format */

import {Button} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledButton = styled(Button)(({theme}) => ({
  border: '1px solid #202020',
  borderRadius: 16,
  fontSize: 16,
  fontWeight: 500,
  height: 64,
  lineHeight: '20px',
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  textTransform: 'none',
}));

type Props = {
  ml?: number;
  onClick: () => void;
};

const OutlinedLargeButton = (props: Props) => (
  <StyledButton {...props} variant='outlined' />
);

export default OutlinedLargeButton;
