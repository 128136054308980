/** @format */

import {useState} from 'react';

import {
  Box,
  Container,
  CssBaseline,
  Link,
  IconButton,
  Drawer,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';

import StratLowConvIssueBannerNew from 'components/banners/StratLowConvIssueBannerNew';
import PaymentErrorBanner from 'components/banners/PaymentErrorBanner';
import PaymentBanner from 'components/banners/PaymentBanner';
import NoDataBannerNew from 'components/banners/NoDataBannerNew';

import TopMenuNew from 'components/menus/TopMenuNew';
import {ACTION_BANNER_Z_INDEX} from 'components/banners/ActionBanner';

import logoImg from 'images/logo_mobile.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  menuMobile: {
    padding: theme.spacing(4, 3),
    backgroundColor: theme.palette.action.disabledBackground,
  },
  menuMobileExpandButton: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#2B2B2B',
  },
  drawerPaper: {
    background: '#202020',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  menu: {
    position: 'relative',
    zIndex: ACTION_BANNER_Z_INDEX + 1,
  },
  container: {
    paddingTop: theme.spacing(6),
  },
}));

interface Props {
  children: React.ReactNode;
}

const MainLayoutNew = (props: Props) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawer = () => {
    return (
      <nav>
        <Drawer
          open={isDrawerOpen}
          classes={{paper: classes.drawerPaper}}
          variant='temporary'
          anchor={'right'}
          onClose={handleToggleDrawer}
        >
          <TopMenuNew onToggleDrawer={handleToggleDrawer} />
        </Drawer>
      </nav>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {renderDrawer()}
      <Hidden mdUp>
        <nav>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className={classes.menuMobile}
          >
            <Link href='/'>
              <img alt='AdSensor Logo' height='26' src={logoImg} width='115' />
            </Link>
            <Box>
              <IconButton
                color='inherit'
                edge='start'
                onClick={handleToggleDrawer}
                className={classes.menuMobileExpandButton}
                size='large'
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </nav>
      </Hidden>
      <Box className={classes.content}>
        <NoDataBannerNew />
        <PaymentErrorBanner />
        <PaymentBanner />
        <StratLowConvIssueBannerNew />

        <Box>
          <Hidden mdDown>
            <Box className={classes.menu}>
              <TopMenuNew />
            </Box>
          </Hidden>
        </Box>

        <Container maxWidth='md' className={classes.container}>
          {props.children}
        </Container>
      </Box>
    </div>
  );
};

export default MainLayoutNew;
