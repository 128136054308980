/** @format */
import {useState} from 'react';

import {styled} from '@mui/material/styles';

import {Box, Grid, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

import constants from 'helpers/constants';
import BoxPlate from 'components/plates/BoxPlate';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const PREFIX = 'ShareLink';

const classes = {
  linkText: `${PREFIX}-linkText`,
};

const Root = styled('div')(() => ({
  [`& .${classes.linkText}`]: {
    color: (props: Props) => (props.variant === 'LIGHT' ? '#73A011' : 'white'),
    fontWeight: 400,
    lineHeight: '17px',
    overflowWrap: 'anywhere',
  },
}));

type Props = {
  url: string;
  onCopy: () => void;
  variant: 'DARK' | 'LIGHT';
};

const ShareLink = (props: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.url);
    setCopied(true);
    if (props.onCopy != null) {
      props.onCopy();
    }
  };

  const backgroundColor = () => {
    if (props.variant === 'LIGHT') {
      return '#F6F6F6';
    }
    return '#202020';
  };

  return (
    <Root>
      <BoxPlate backgroundColor={backgroundColor()}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography
                variant='body2'
                component='span'
                className={classes.linkText}
              >
                {props.url}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PrimaryMediumButton onClick={handleCopy} fullWidth>
              {copied ? (
                <>
                  <Trans>Скопировано</Trans> {constants.CHECK_MARK}
                </>
              ) : (
                <Trans>Скопировать</Trans>
              )}
            </PrimaryMediumButton>
          </Grid>
        </Grid>
      </BoxPlate>
    </Root>
  );
};

export default ShareLink;
