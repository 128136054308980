/** @format */

import {useEffect} from 'react';

import {createBrowserRouter, useLocation} from 'react-router-dom';

const RuDomain = 'adsensor.ru';
const DevPagesDomain = 'pages.dev';
//NOTE: специальный домен для добавления в реестр российского ПО
const RuRegistryDomain = 'ad-keeper.ru';

const useBackgroundPath = () => {
  const location = useLocation();

  const {pathname, search} = location.state.background;
  return {pathname, search};
};

// https://reacttraining.com/react-router/web/example/query-parameters
const useSearchParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const secondLevelDomain = () => {
  return window.location.hostname.split('.').slice(-2).join('.');
};

const useCallbackOnReturn = callback => {
  const location = useLocation();

  useEffect(() => {
    callback();
  }, [location, callback]);
};

const IsRuVersion = () => {
  if (!import.meta.env.PROD) return true;

  if (secondLevelDomain() === DevPagesDomain) {
    return true;
  }
  if (secondLevelDomain() === RuRegistryDomain) {
    return true;
  }

  return secondLevelDomain() === RuDomain;
};

export default {
  IsRuVersion,
  useBackgroundPath,
  useSearchParams,
  useCallbackOnReturn,
  secondLevelDomain,
};
