/** @format */

import React from 'react';

import {styled} from '@mui/material/styles';

import {Breadcrumbs as MUIBreadcrumbs, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PREFIX = 'Breadcrumbs';

const classes = {
  breadcrumbsSeparator: `${PREFIX}-breadcrumbsSeparator`,
  breadcrumbsItem: `${PREFIX}-breadcrumbsItem`,
  breadcrumbsActiveItem: `${PREFIX}-breadcrumbsActiveItem`,
};

const StyledMUIBreadcrumbs = styled(MUIBreadcrumbs)(({theme}) => ({
  [`& .${classes.breadcrumbsSeparator}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.breadcrumbsItem}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
  },

  [`& .${classes.breadcrumbsActiveItem}`]: {
    color: '#AE5658',
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',
  },
}));

interface Props {
  items: {
    href: string;
    title: React.ReactNode;
  }[];
}

const Breadcrumbs = (props: Props) => {
  return (
    <StyledMUIBreadcrumbs
      separator={
        <ChevronRightIcon
          fontSize='small'
          className={classes.breadcrumbsSeparator}
        />
      }
      aria-label='breadcrumb'
    >
      {props.items.map((v, index) =>
        index !== props.items.length - 1 ? (
          <RouterLink
            key={index}
            to={v.href}
            className={classes.breadcrumbsActiveItem}
          >
            {v.title}
          </RouterLink>
        ) : (
          <Typography key={index} className={classes.breadcrumbsItem}>
            {v.title}
          </Typography>
        ),
      )}
    </StyledMUIBreadcrumbs>
  );
};

export default Breadcrumbs;
