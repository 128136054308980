/** @format */

import {styled} from '@mui/material/styles';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const StyledPrimaryMediumButton = styled(PrimaryMediumButton)(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#828282',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&:disabled': {
    backgroundColor: '#DBDBDB',
  },
}));

const SecondaryMediumButton = props => <StyledPrimaryMediumButton {...props} />;

export default SecondaryMediumButton;
