/** @format */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';

const PREFIX = 'InformationDialog';

const classes = {
  title: `${PREFIX}-title`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.text.secondary,
  },
}));

const InformationDialog = props => {
  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disableElevation
          fullWidth={false}
          size='medium'
          variant='contained'
          onClick={props.onClose}
        >
          <Trans>Закрыть</Trans>
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default InformationDialog;
