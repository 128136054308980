/** @format */

import React, {useEffect, useState} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {styled} from '@mui/material/styles';
import clsx from 'clsx';
import {Trans, useTranslation} from 'react-i18next';
import {Box, Typography, LinearProgress, Button, Checkbox} from '@mui/material';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import {gql, useQuery, useMutation} from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import {useNavigate} from 'react-router';
import {customAlphabet} from 'nanoid';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'components/Modal';
import LegacyTextField from 'components/inputs/LegacyTextField';
import BackHeader from 'components/BackHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import {ROUTES} from 'constants/ROUTES';

const PREFIX = 'Grouping';

const classes = {
  headerTitle: `${PREFIX}-headerTitle`,
  addButtonContainer: `${PREFIX}-addButtonContainer`,
  addButton: `${PREFIX}-addButton`,
  addButtonIcon: `${PREFIX}-addButtonIcon`,
  groupContainer: `${PREFIX}-groupContainer`,
  groupContainerEditMode: `${PREFIX}-groupContainerEditMode`,
  groupHeader: `${PREFIX}-groupHeader`,
  groupTitle: `${PREFIX}-groupTitle`,
  groupEditButton: `${PREFIX}-groupEditButton`,
  groupEditButtonIcon: `${PREFIX}-groupEditButtonIcon`,
  groupNameInput: `${PREFIX}-groupNameInput`,
  saveGroupEditButton: `${PREFIX}-saveGroupEditButton`,
  resetGroupEditButton: `${PREFIX}-resetGroupEditButton`,
  groupBody: `${PREFIX}-groupBody`,
  groupHeadingRow: `${PREFIX}-groupHeadingRow`,
  groupRow: `${PREFIX}-groupRow`,
  borderBottom: `${PREFIX}-borderBottom`,
  projectsAmount: `${PREFIX}-projectsAmount`,
  deleteFolderButton: `${PREFIX}-deleteFolderButton`,
  projectContainer: `${PREFIX}-projectContainer`,
  projectContainerEditable: `${PREFIX}-projectContainerEditable`,
  projectDeleteButton: `${PREFIX}-projectDeleteButton`,
  projectDeleteButtonIcon: `${PREFIX}-projectDeleteButtonIcon`,
  addProjectButton: `${PREFIX}-addProjectButton`,
  addProjectButtonIcon: `${PREFIX}-addProjectButtonIcon`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.headerTitle}`]: {
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.24px',
  },
  [`& .${classes.addButtonContainer}`]: {
    display: 'flex',
    justifyContent: 'end',
  },
  [`& .${classes.addButton}`]: {
    minWidth: 'auto',
    height: '50px',
    fontSize: '14px',
    fontWeight: 400,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.addButtonIcon}`]: {
    fontSize: '18px',
  },

  [`& .${classes.groupContainer}`]: {
    marginBottom: theme.spacing(4),
    borderRadius: '16px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    border: '1px solid transparent',
  },
  [`& .${classes.groupContainerEditMode}`]: {
    borderColor: '#F1F1F1',
  },
  [`& .${classes.groupHeader}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(5, 6),
  },
  [`& .${classes.groupTitle}`]: {
    color: '#F1F1F1',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.2px',
  },
  [`& .${classes.groupEditButton}`]: {
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.groupEditButtonIcon}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
  },

  [`& .${classes.groupNameInput}`]: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  [`& .${classes.saveGroupEditButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.resetGroupEditButton}`]: {
    height: '48px',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.groupBody}`]: {
    padding: theme.spacing(0, 6, 6, 6),
  },

  [`& .${classes.groupHeadingRow}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.28px',
    '& > div': {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        width: '100%',
      },
    },
  },

  [`& .${classes.groupRow}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0, 4, 0),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    '& > div': {
      width: '50%',
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        width: '100%',
      },
    },
  },
  [`& .${classes.borderBottom}`]: {
    borderBottom: '1px solid #383838',
  },

  [`& .${classes.projectsAmount}`]: {
    marginTop: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },

  [`& .${classes.deleteFolderButton}`]: {
    height: '32px',
    marginTop: theme.spacing(6),
    borderRadius: '8px',
    background: '#EA5759',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },

  [`& .${classes.projectContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: '8px',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.projectContainerEditable}`]: {
    '&:hover': {
      background: '#4D4E4E',
      cursor: 'pointer',
    },
  },

  [`& .${classes.projectDeleteButton}`]: {
    width: '24px',
    minWidth: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.projectDeleteButtonIcon}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
  },

  [`& .${classes.addProjectButton}`]: {
    marginTop: theme.spacing(4),
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.addProjectButtonIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '16px',
  },
}));

const breadcrumbs = [
  {
    href: ROUTES.SETTINGS,
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Настройка группировок</Trans>,
  },
];

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      companies {
        id
        name
        tags {
          id
          name
          category {
            id
            name
          }
        }
      }
      categories {
        id
        name
        tags {
          id
          name
        }
      }
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation CreateCategory($category: CreateCategoryInput!) {
    createCategory(category: $category) {
      id
      name
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($category: UpdateCategoryInput!) {
    updateCategory(category: $category)
  }
`;

const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($category: RemoveCategoryInput!) {
    removeCategory(category: $category)
  }
`;

interface Tag {
  id?: number | string;
  name: string;
  isNew?: boolean;
  companyIds?: number[];
}

interface Company {
  id: number;
  name: string;
  tags?: Tag[];
  isChecked?: boolean;
}

interface Category {
  id?: number | string;
  name: string;
  isNew?: boolean;
  tags?: Tag[];
}

const Grouping = () => {
  // const navigate = useNavigate();

  const {t} = useTranslation();

  const nanoid = customAlphabet('1234567890', 10);

  const {isNewVersionEnabled} = useFlags();

  const [categories, setCategories] = useState<Category[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesBackup, setCompaniesBackup] = useState<Company[]>([]);
  const [companyIds, setCompanyIds] = useState({} as {[key: string]: number[]});
  const [currentTagId, setCurrentTagId] = useState<null | number>(null);
  const [editCategoryId, setEditCategoryId] = useState<null | number | string>(
    null,
  );
  const [isTagsModalVisible, setIsTagsModalVisible] = useState<boolean>(false);
  const [removedTagIds, setRemovedTagIds] = useState<number[]>([]);

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);
  const [createCategoryError, setCreateCategoryError] = useState('');
  const [removeCategoryError, setRemoveCategoryError] = useState('');
  const [createCategory, {loading: createCategoryLoading}] =
    useMutation(CREATE_CATEGORY);
  const [updateCategory, {loading: updateCategoryLoading}] =
    useMutation(UPDATE_CATEGORY);
  const [removeCategory, {loading: removeCategoryLoading}] =
    useMutation(REMOVE_CATEGORY);

  useEffect(() => {
    if (!data) return;

    const {companies: currentUserCompanies, categories: currentUserCategories} =
      data.currentUser;

    setCategories(currentUserCategories);
    setCompanies(
      currentUserCompanies.map(v => ({
        ...v,
        isChecked: false,
      })),
    );
  }, [data]);

  useEffect(() => {
    if (categories.length) {
      const map = companies.reduce(
        (acc, company) => {
          const companyTags = (company.tags || []).filter(v => v.id);
          companyTags.forEach(tag => {
            if (!tag.id || typeof tag.id !== 'string') return;
            if (!acc[tag.id]) {
              acc[tag.id] = [];
            }
            if (acc[tag.id]) {
              acc[tag.id].push(company.id);
            } else {
              acc[tag.id] = [company.id];
            }
          });
          return acc;
        },
        {} as {[key: string]: number[]},
      );

      const editCategory = categories.find(v => v.id === editCategoryId);
      if (editCategory) {
        editCategory.tags?.forEach(tag => {
          map[String(tag.id)] = companyIds[String(tag.id)] || [];
        });
      }

      setCompanyIds(map);
    }
  }, [categories]);

  const handleClickAddNew = async () => {
    const newCategoryId = nanoid();

    setCategories([
      {
        id: newCategoryId,
        name: 'Новая группа',
        isNew: true,
        tags: [
          {id: nanoid(), isNew: true, name: 'Новая папка', companyIds: []},
        ],
      },
      ...categories,
    ]);

    setCompaniesBackup([...companies]);
    setEditCategoryId(newCategoryId);
  };
  if (createCategoryLoading || updateCategoryLoading || removeCategoryLoading) {
    return <LinearProgress style={{flex: 1}} />;
  }
  if (createCategoryError || removeCategoryError) {
    return (
      <Alert severity='error'>
        {createCategoryError || removeCategoryError}
      </Alert>
    );
  }
  if (loading || error) return null;

  const handleClickSaveCategory = async (id?: number | string) => {
    const category = categories.find(v => v.id === id);

    if (!category) return;

    if (category.isNew) {
      await createCategory({
        variables: {
          category: {
            name: category.name,
            tags: category.tags?.map(tag => ({
              name: tag.name,
              companyIds: companyIds[tag.id].map(v => Number(v)),
            })),
          },
        },
      })
        .then(() => {
          refetch();
        })
        .catch(e => setCreateCategoryError((e as Error).message));
    } else {
      const createdTags = category.tags
        ?.filter(v => v.isNew)
        .map(tag => {
          return {
            name: tag.name,
            companyIds: (companyIds[Number(tag.id)] || []).map(v => Number(v)),
          };
        });

      const updatedTags = category.tags
        ?.filter(v => !v.isNew)
        .map(tag => {
          return {
            id: Number(tag.id),
            name: tag.name,
            companyIds: (companyIds[Number(tag.id)] || []).map(v => Number(v)),
          };
        });

      updateCategory({
        variables: {
          category: {
            id: Number(category.id),
            name: category.name,
            removedTags: removedTagIds.map(v => ({id: Number(v)})),
            createdTags: createdTags,
            updatedTags: updatedTags,
          },
        },
      })
        .then(() => {
          refetch();

          setEditCategoryId(null);
        })
        .catch(e => setCreateCategoryError((e as Error).message));
    }
  };
  const handleCategoryNameChange = (value: string, id?: number | string) => {
    const updatedCategory = {
      ...categories.find(v => v.id === id),
      name: value,
    };

    if (updatedCategory) {
      setCategories(
        categories.map(category => {
          if (category.id === id) {
            return updatedCategory;
          }

          return category;
        }),
      );
    }
  };
  const handleSelectCompany = (
    e: React.ChangeEvent<HTMLInputElement>,
    companyId: number,
  ) => {
    if (!currentTagId) return;

    const tagCompanyIds = companyIds[currentTagId] || [];
    if (e.target.checked) {
      const updatedArray = [...tagCompanyIds, companyId];

      setCompanyIds({
        ...companyIds,
        ...{
          [currentTagId]: updatedArray,
        },
      });
    } else {
      const updatedArray = tagCompanyIds.filter(id => id !== companyId);

      setCompanyIds({
        ...companyIds,
        ...{
          [currentTagId]: updatedArray,
        },
      });
    }
  };

  const handleTagNameChange = (
    value: string,
    categoryId: number,
    tagId: number,
  ) => {
    const category = categories.find(v => v.id === categoryId);

    if (category) {
      const updatedCategory = {
        ...category,
        tags: category.tags?.map(tag => {
          if (tag.id === tagId) {
            return {
              ...tag,
              name: value,
            };
          }

          return tag;
        }),
      };

      setCategories(
        categories.map(v => {
          if (v.id === categoryId) {
            return updatedCategory;
          }
          return v;
        }),
      );
    }
  };

  const handleTagDelete = (
    categoryId: number | string,
    tagId: number | string,
  ) => {
    const category = categories.find(v => v.id === categoryId);

    if (category) {
      const updatedCategory = {
        ...category,
        tags: category.tags?.filter(v => v.id !== tagId),
      };

      const tag = category.tags?.find(v => v.id === tagId);
      if(!tag) return;

      if (!tag.isNew) {
        setRemovedTagIds([...removedTagIds, Number(tagId)]);
      }

      setCategories(
        categories.map(v => {
          if (v.id === categoryId) {
            return updatedCategory;
          }
          return v;
        }),
      );
    }
  };

  const handleCategoryDelete = async (id?: number | string) => {
    const category = categories.find(v => v.id === id);

    if (!category?.isNew) {
      await removeCategory({
        variables: {
          category: {
            id: Number(id),
          },
        },
      })
        .then(() => {
          handleTagsModalClose();

          refetch();
        })
        .catch(e => setRemoveCategoryError(e));
    } else {
      setCategories(categories.filter(v => v.id !== id));
    }
  };

  const handleCompanyDelete = (id: number | string, tagId: number | string) => {
    const newCompanyIds = companyIds[tagId].filter(v => v !== id);
    setCompanyIds({
      ...companyIds,
      ...{
        [tagId]: newCompanyIds,
      },
    });
  };

  const handleAddCompany = (categoryId, tagId) => {
    const category = categories.find(v => v.id === categoryId);

    if (category) {
      setIsTagsModalVisible(true);
    }

    setCurrentTagId(tagId);
  };

  const handleAddTag = categoryId => {
    const category = categories.find(v => v.id === categoryId);

    if (category) {
      const updatedCategory = {
        ...category,
        tags: [
          ...category.tags,
          {id: nanoid(), isNew: true, name: '', companyIds: []},
        ],
      };

      setCategories(
        categories.map(v => {
          if (v.id === categoryId) {
            return updatedCategory;
          }
          return v;
        }),
      );
    }
  };

  const handleTagsModalClose = () => {
    setIsTagsModalVisible(false);

    setCurrentTagId(null);
  };

  const handleEditCategoryClick = (id: number | string | undefined) => {
    setCompaniesBackup([...companies]);
    setEditCategoryId(id || null);
  };

  const handleResetEditMode = () => {
    setCompanies(companiesBackup);
    setCompaniesBackup([]);
    setEditCategoryId(null);
  };

  return (
    <Root>
      <Box
        sx={{
          mb: 3,
        }}
      >
        {isNewVersionEnabled ? (
          <Breadcrumbs items={breadcrumbs} />
        ) : (
          <BackHeader />
        )}
      </Box>
      <Box
        sx={{
          mt: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.headerTitle}>
            <Trans>Режимы группировки</Trans>
          </Typography>
          <PrimaryMediumButton
            variant='contained'
            onClick={handleClickAddNew}
            className={classes.addButton}
          >
            <Box
              sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineRounded className={classes.addButtonIcon} />
            </Box>
            <Trans>Добавить</Trans>
          </PrimaryMediumButton>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 6,
        }}
      >
        {categories.length ? (
          categories.map(category => {
            let isEditMode = editCategoryId === category.id;

            return (
              <Box
                key={category.id}
                className={clsx(
                  classes.groupContainer,
                  isEditMode && classes.groupContainerEditMode,
                )}
              >
                <Box className={classes.groupHeader}>
                  {isEditMode ? (
                    <LegacyTextField
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCategoryNameChange(e.target.value, category.id)
                      }
                      placeholder={category.name}
                      className={classes.groupNameInput}
                    />
                  ) : (
                    <Typography className={classes.groupTitle}>
                      {category.name}
                    </Typography>
                  )}

                  {isEditMode ? (
                    <Box>
                      <PrimaryMediumButton
                        variant='contained'
                        onClick={() => handleClickSaveCategory(category.id)}
                        className={classes.saveGroupEditButton}
                      >
                        <Trans>Сохранить</Trans>
                      </PrimaryMediumButton>

                      <Button
                        onClick={handleResetEditMode}
                        className={classes.resetGroupEditButton}
                      >
                        <Trans>Отменить</Trans>
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      className={classes.groupEditButton}
                      onClick={() => handleEditCategoryClick(category.id)}
                    >
                      <EditIcon className={classes.groupEditButtonIcon} />
                    </Button>
                  )}
                </Box>

                <Box className={classes.groupBody}>
                  <Box
                    className={clsx(
                      classes.groupHeadingRow,
                      classes.borderBottom,
                    )}
                  >
                    <Box>
                      <Trans>Папки</Trans>
                    </Box>
                    <Box>
                      <Trans>Проекты</Trans>
                    </Box>
                  </Box>

                  {category.tags &&
                    category.tags.map(tag => {
                      let tagCompanies: Company[] = [];

                      companyIds[Number(tag.id)]?.forEach(v => {
                        tagCompanies.push(companies.find(vv => vv.id === v));
                      });

                      // for (let i = 0; i < companies.length; i++) {
                      // for (let j = 0; j < companies[i].tags.length; j++) {
                      // if (companies[i].tags[j].id === tag.id) {
                      // tagCompanies.push(companies[i]);
                      // }
                      // }
                      // }

                      return (
                        <React.Fragment key={tag.id}>
                          <Box
                            className={clsx(
                              classes.groupRow,
                              classes.borderBottom,
                            )}
                          >
                            <Box>
                              {isEditMode ? (
                                <LegacyTextField
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleTagNameChange(
                                      e.target.value,
                                      category.id,
                                      tag.id,
                                    )
                                  }
                                  placeholder={tag.name}
                                />
                              ) : (
                                <Typography>{tag.name}</Typography>
                              )}
                              {tagCompanies && (
                                <Box className={classes.projectsAmount}>
                                  {t('plurals.companies', {
                                    count: tagCompanies.length,
                                  })}
                                </Box>
                              )}
                              {isEditMode && (
                                <Button
                                  onClick={() =>
                                    handleTagDelete(category.id, tag.id)
                                  }
                                  className={classes.deleteFolderButton}
                                >
                                  <Trans>Удалить папку</Trans>
                                </Button>
                              )}
                            </Box>
                            <Box>
                              {tagCompanies.map(v => {
                                return (
                                  <Box
                                    key={v.id}
                                    className={clsx(
                                      classes.projectContainer,
                                      isEditMode &&
                                        classes.projectContainerEditable,
                                    )}
                                  >
                                    {v.name}

                                    {isEditMode && (
                                      <Button
                                        className={classes.projectDeleteButton}
                                        onClick={() =>
                                          handleCompanyDelete(v.id, tag.id)
                                        }
                                      >
                                        <CloseIcon
                                          className={
                                            classes.projectDeleteButtonIcon
                                          }
                                        />
                                      </Button>
                                    )}
                                  </Box>
                                );
                              })}

                              {isEditMode && (
                                <Button
                                  onClick={() =>
                                    handleAddCompany(category.id, tag.id)
                                  }
                                  className={classes.addProjectButton}
                                >
                                  <AddIcon
                                    className={classes.addProjectButtonIcon}
                                  />
                                  <Trans>Добавить компанию</Trans>
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    })}

                  {isTagsModalVisible && (
                    <Modal
                      header={category.name}
                      onClose={handleTagsModalClose}
                      showCloseIcon={true}
                      footer={
                        <Box>
                          <PrimaryMediumButton onClick={handleTagsModalClose}>
                            <Trans>Сохранить</Trans>
                          </PrimaryMediumButton>
                        </Box>
                      }
                    >
                      <Box>
                        {companies.map(company => {
                          return (
                            <Box key={company.id} maxHeight='70vh'>
                              <Checkbox
                                edge='start'
                                color='default'
                                defaultChecked={company.isChecked}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handleSelectCompany(e, company.id);
                                }}
                              />
                              {company.name}
                            </Box>
                          );
                        })}
                      </Box>
                    </Modal>
                  )}

                  {isEditMode && (
                    <Button
                      onClick={() => handleAddTag(category.id)}
                      className={classes.addProjectButton}
                    >
                      <AddIcon className={classes.addProjectButtonIcon} />
                      <Trans>Добавить папку</Trans>
                    </Button>
                  )}

                  {isEditMode && (
                    <Button
                      onClick={() => handleCategoryDelete(category.id)}
                      className={classes.deleteFolderButton}
                    >
                      <Trans>Удалить группу</Trans>
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Trans>Нет созданных категорий</Trans>
        )}
      </Box>
    </Root>
  );
};

export default Grouping;
