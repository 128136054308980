/** @format */
import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

const NotFoundMessage = () => {
  return (
    <Box>
      <Box mb={4}>
        <Typography variant='h2'>
          <Trans>Страница устарела!</Trans>
        </Typography>
      </Box>
      <Box>
        <Typography variant='body2'>
          <Trans>
            Это могло произойти, если вы нажали кнопку "Назад" в браузере. Для возврата к предыдущему шагу рекомендуем провести операцию с начала — используйте меню и другие вспомогательные элементы навигации.
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFoundMessage;
