/** @format */

import {useLocation} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

import {gql, useMutation} from '@apollo/client';

import Alert from '@mui/material/Alert';
import {Container, Box, Hidden, IconButton, Link} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as R from 'ramda';

import logoImg from 'images/logo__white.svg';

import NativeMenuNew from 'components/menus/NativeMenuNew';
import type {Item} from 'components/menus/NativeMenuNew';

import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'TopMenu';

const classes = {
  menuHeader: `${PREFIX}-menuHeader`,
  menu: `${PREFIX}-menu`,
  menuIcon: `${PREFIX}-menuIcon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.menuHeader}`]: {
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    width: '100vw',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  [`& .${classes.menu}`]: {
    display: 'flex',
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100vw',
    },
  },

  [`& .${classes.menuIcon}`]: {
    fontSize: '16px',
  },
}));

const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      id
    }
  }
`;

interface Props {
  onToggleDrawer?: () => void;
}

const TopMenu = (props: Props) => {
  const {pathname} = useLocation();
  const {t} = useTranslation();

  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();

  const [signOut, {error: signOutError}] = useMutation(SIGN_OUT);

  if (signOutError) {
    return <Alert severity='error'>{signOutError.message}</Alert>;
  }

  const handleLogout = async () => {
    mixpanel.track('click_sign_out_button');

    try {
      await signOut();
      await authContext.signOut();
      // NOTE: temporary replace to explicit page reload for avoiding switch theme bug
      // history.push('/');
      location.replace('/');
    } catch (e) {
      console.error(e);
    }
  };

  const rightItems = [
    {
      path: '/',
      icon: <AppsIcon className={classes.menuIcon} />,
      name: 'PROJECTS',
      text: t('Проекты', 'Проекты'),
    },
    {
      path: '/home/settings',
      icon: <SettingsOutlinedIcon className={classes.menuIcon} />,
      name: 'SETTINGS',
      text: t('Настройки', 'Настройки'),
    },
    {
      path: '/home/settings/profile',
      icon: <AccountCircleOutlinedIcon className={classes.menuIcon} />,
      name: 'ACCOUNT',
      text: t('Аккаунт', 'Аккаунт'),
    },
    {
      path: '#',
      icon: <ExitToAppIcon className={classes.menuIcon} />,
      name: 'EXIT',
      onClick: handleLogout,
      text: t('Выход', 'Выход'),
    },
  ] as Item[];

  const activeItem = R.pipe(
    // sort by path length to find the most specific path
    R.sort<Item>((a, b) => (b.path.length - a.path.length)),
    R.find((v) => pathname.startsWith(v.path)),
  )(rightItems) || rightItems[0];

  return (
    <StyledBox>
      {props.onToggleDrawer && (
        <Box className={classes.menuHeader}>
          <Hidden mdUp>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 7,
              }}
            >
              <Link href='/'>
                <img
                  alt='AdSensor Logo'
                  height='26'
                  src={logoImg}
                  width='115'
                />
              </Link>
              <IconButton
                color='inherit'
                edge='start'
                onClick={props.onToggleDrawer}
                size='large'
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Box>
      )}
      <Box className={classes.menuHeader}>
        <Container maxWidth='md'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!props.onToggleDrawer && (
              <Link href='/'>
                <img
                  alt='AdSensor Logo'
                  height='26'
                  src={logoImg}
                  width='115'
                />
              </Link>
            )}

            <NativeMenuNew
              className={classes.menu}
              activeItem={activeItem}
              rightItems={rightItems}
            />
          </Box>
        </Container>
      </Box>
    </StyledBox>
  );
};

export default TopMenu;
