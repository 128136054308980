/** @format */

import React, {useCallback, useEffect, useContext, useState, useMemo} from 'react';

import {gql, useQuery} from '@apollo/client';

import {useAuthContext} from 'contexts/AuthContext';

const SidebarContext = React.createContext();

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      companies {
        id
        uuid
        name
        tokens {
          id
          lastError
          provider
        }
      }
    }
  }
`;

export const SidebarProvider = props => {
  const {authToken} = useAuthContext();

  const [companies, setCompanies] = useState([]);
  const {data, refetch} = useQuery(GET_CURRENT_USER, {skip: !authToken});

  useEffect(() => {
    if (!data) return;
    if (!data.currentUser) return;
    setCompanies(data.currentUser.companies);
  }, [data]);

  const refreshSidebar = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const contextValue = useMemo(() => ({
    companies,
    refreshSidebar
  }), [companies, refreshSidebar]);

  return (
    <SidebarContext.Provider value={contextValue}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
