/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Radio, Box} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const PREFIX = 'GreenRadio';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  iconDark: `${PREFIX}-iconDark`,
  iconLight: `${PREFIX}-iconLight`,
  checkedIcon: `${PREFIX}-checkedIcon`,
  iconSquare: `${PREFIX}-iconSquare`,
};

const StyledRadio = styled(Radio)(({theme}) => ({
  padding: 0,
  [`& .${classes.icon}`]: {
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    height: 24,
    paddingTop: 2,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.iconDark}`]: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: theme.palette.action.active,
  },

  [`& .${classes.iconLight}`]: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: '#E0E0E0',
  },
  [`& .${classes.checkedIcon}`]: {
    backgroundColor: theme.palette.success.main,
    borderWidth: 0,
    '&:before': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 12,
      margin: 6,
      width: 12,
    },
  },
  [`& .${classes.iconSquare}`]: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    border: '1px solid #2B2B2B',
    background: 'transparent',
  },
}));

const StyledSquareRadio = styled(StyledRadio)(({theme}) => ({
  '&:hover': {
    backgroundColor: '#4D4D4D',
    borderRadius: theme.spacing(2),
  },
}));

// https://material-ui.com/components/radio-buttons/#customized-radios
const GreenRadio = props => {
  const {iconVariant, variant, ...rest} = props;

  const iconStyle = () => {
    if (variant === 'DARK') {
      return clsx(classes.icon, classes.iconDark);
    }

    if (variant === 'SQUARE') {
      return clsx(classes.icon, classes.iconSquare);
    }

    if (variant === 'LIGHT') {
      return clsx(classes.icon, classes.iconLight);
    }

    return null;
  };

  const checkedIcon = () => {
    return variant === 'SQUARE' ? (
      <Box className={classes.iconSquare}>
        <CheckIcon fontSize='small' htmlColor='#FFF' />
      </Box>
    ) : iconVariant === 'circle' ? (
      <span className={clsx(classes.icon, classes.checkedIcon)} />
    ) : iconVariant === 'check' ? (
      <span className={iconStyle()} position='relative'>
        <img
          alt='Icon'
          height='12'
          src={new URL('./images/check.png', import.meta.url)}
          width='12'
        />
      </span>
    ) : null;
  };

  return variant === 'SQUARE' ? (
    <StyledSquareRadio
      checkedIcon={checkedIcon()}
      icon={<span className={iconStyle()} />}
      {...rest}
    />
  ) : (
    <StyledRadio
      checkedIcon={checkedIcon()}
      icon={<span className={iconStyle()} />}
      {...rest}
    />
  );
};

GreenRadio.defaultProps = {
  iconVariant: 'circle',
  variant: 'DARK',
};

export default GreenRadio;
