/** @format */

import {Navigate} from 'react-router-dom';

import {useAuthContext} from 'contexts/AuthContext';

const PrivateRoute = ({children, ...props}) => {
  const {authToken} = useAuthContext();

  if (!authToken) {
    return <Navigate to={{pathname: '/auth/sign-in'}} />;
  }

  if (authToken.type === 'GUEST' && !location.pathname.startsWith('/guest')) {
    return <Navigate to='/auth/sign-in' />;
  }

  return children;
};

export default PrivateRoute;
