/** @format */

import React, {useMemo, useState} from 'react';

import * as R from 'ramda';
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Trans, useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import adTypeHelpers from 'helpers/adTypeHelpers';
import categoriesData from 'pages/Home/Main/Status/Issues/categories.json';
import {sensorName} from 'helpers/sensor_names';

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  nestedListItem: {
    paddingLeft: theme.spacing(7),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  }
}));

const SensorsModal = props => {
  const [selectedSensors, setSelectedSensors] = useState(props.selectedSensors);
  const [categoriesOpened, setCategoriesOpened] = useState(
    categoriesData.reduce((a, v) => ({...a, [v.name]: false}), {})
  );

  const {t} = useTranslation();
  const styles = useStyles();

  const sensorsSet = useMemo(() => {
    const platforms = adTypeHelpers.platformsFromAdType(props.adType);
    const adTypeSensorNames = props.sensors
      .filter(v => platforms.includes(v.platform))
      .map(v => v.name);
    return new Set(adTypeSensorNames);
  }, [props.sensors, props.adType]);

  const filledCategories = useMemo(() => {
    return categoriesData.filter((v, _) => {
      const allowedSensors = v['sensors'].filter(sensor => sensorsSet.has(sensor));
      return allowedSensors.length > 0;
    });
  }, [sensorsSet]);

  const sensorsByCategories = useMemo(() => {
    const sortSensors = sensors => {
      return sensors
        .map(v => ({id: v, title: t(sensorName(v))}))
        .sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title === b.title) return 0;
          return -1;
        })
        .map(v => v.id);
    };

    return filledCategories.map(v => ({
      name: v.name,
      title: v.title,
      sensors: sortSensors(v.sensors.filter(vv => sensorsSet.has(vv)))
    }));
  }, [filledCategories, sensorsSet, t]);

  const selectedCategories = useMemo(() => {
    return sensorsByCategories.reduce((a, v) => ({
      ...a,
      [v.name]: R.all(vv => selectedSensors[vv])(v.sensors)
    }), {});
  }, [selectedSensors, sensorsByCategories]);

  const handleClick = () => {
    props.onSubmit(selectedSensors);
  };

  const handleSelect = check => {
    const newState = {...selectedSensors};
    newState[check] = !newState[check];
    setSelectedSensors(newState);
  };

  const anySelected = useMemo(() => {
    return R.pipe(
    R.toPairs,
    R.values,
    R.any(v => v[1]),)(selectedSensors);
  }, [selectedSensors]);

  const handleClickCategory = category => {
    const currentValue = categoriesOpened[category];
    setCategoriesOpened({...categoriesOpened, [category]: !currentValue});
  };

  const handleSelectCategory = (category, e) => {
    e.stopPropagation();
    const newSelectValue = !selectedCategories[category];
    const newState = {...selectedSensors};
    sensorsByCategories
      .find(v => v.name === category)
      .sensors
      .forEach(v => newState[v] = newSelectValue);
    setSelectedSensors(newState);
  };

  const renderFooter = () => (
    <PrimaryMediumButton
      disabled={!anySelected}
      fullWidth
      onClick={handleClick}
    >
      <Trans>Продолжить</Trans>
    </PrimaryMediumButton>
  );

  return (
    <>
      <Modal
        header={t(
          'Выберите, какие проверки вы хотите\nвключить в отчет',
          'Выберите, какие проверки вы хотите\nвключить в отчет',
        )}
        footer={renderFooter()}
        onClose={props.onClose}
      >
        <Box mt={5} maxHeight='80vh'>
          <Grid container spacing={2}>
            <List className={styles.list}>
              {sensorsByCategories.map((v, i) => (
                <React.Fragment key={i}>
                  <ListItem
                    button
                    className={styles.listItem}
                    onClick={() => handleClickCategory(v.name)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        color='default'
                        checked={selectedCategories[v.name]}
                        onClick={e => handleSelectCategory(v.name, e)}
                        disableRipple
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={t(v['title'])}/>
                    {categoriesOpened[v.name] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={categoriesOpened[v.name]} timeout='auto' unmountOnExit>
                    <List disablePadding>
                      {v.sensors
                          .filter(vv => sensorsSet.has(vv))
                          .map((vv, ii) => (
                        <ListItem
                          key={ii}
                          button
                          className={styles.nestedListItem}
                          onClick={() => handleSelect(vv)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge='start'
                              color='default'
                              checked={selectedSensors[vv]}
                              disableRipple
                              tabIndex={-1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={t(sensorName(vv))} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default SensorsModal;
