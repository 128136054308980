/** @format */
import React from 'react';

import {Box, Link, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

type Props = {
  text: string;
  activeImg: string;
  inactiveImg: string;
  to: {
    pathname: string;
    search?: string;
  };
  openBlank?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const PREFIX = 'MenuItemLink';

const classes = {
  container: `${PREFIX}-container`,
  current: `${PREFIX}-current`,
  link: `${PREFIX}-link`,
  text: `${PREFIX}-text`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    width: '100%',
  },
  [`& .${classes.current}`]: {
    cursor: 'inherit',
    backgroundColor: '#73A011',
    width: 2,
  },
  [`& .${classes.link}`]: {
    display: 'inline-block',
    width: '100%',
  },
  [`& .${classes.text}`]: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
}));

const MenuItemLink = (props: Props) => {
  const {pathname} = useLocation();

  const isActive = props.to.pathname.startsWith(pathname);

  const icon = () => {
    if (isActive) {
      return props.activeImg;
    }
    return props.inactiveImg;
  };

  const renderContent = () => {
    return (
      <Box
        className={classes.container}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              mr: 2,
            }}
          >
            <img src={icon()} width={24} height={24} />
          </Box>
          <Box>
            <Typography className={classes.text}>{props.text}</Typography>
          </Box>
        </Box>
        {isActive && <Box className={classes.current}></Box>}
      </Box>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <StyledBox>
      {isActive ? (
        renderContent()
      ) : (
        <Link
          to={props.to}
          component={RouterLink}
          target={props.openBlank ? '_blank' : '_self'}
          className={classes.link}
          onClick={handleClick}
        >
          {renderContent()}
        </Link>
      )}
    </StyledBox>
  );
};

export default MenuItemLink;
