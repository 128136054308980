/** @format */

import {IconButton} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Props {
  className?: string;
  onConnect: () => void;
}

const ConnectButtonNew = (props: Props) => {
  return (
    (<IconButton className={props.className} onClick={props.onConnect} size="large">
      <AddCircleIcon htmlColor='#677C41' fontSize='small' />
    </IconButton>)
  );
};

export default ConnectButtonNew;
