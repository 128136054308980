/** @format */

import {Button} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const NextButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '20px',
    padding: 10,
    '&:hover': {
      backgroundColor: '#648F13',
    },
  },
}))(Button);

export default NextButton;
