/** @format */

import * as R from 'ramda';
import {differenceInCalendarDays, endOfDay, isPast, parseISO} from 'date-fns';

import {DAILY_PLANS} from 'pages/Home/Settings/Payment/DailyPlans';
import {ONE_TIME_PLANS} from 'pages/Home/Settings/Payment/OneTimePlans';

const WARN_BEFORE_IN_DAYS = 10;

const payNow = subscription => {
  // No subscription
  if (!subscription.beginDate || !subscription.endDate) return true;
  if (subscription.activeSubscription === false) return true;

  const endDate = parseISO(subscription.endDate);
  // Subscription already expired
  return isPast(endOfDay(endDate));
};

const paySoon = subscription => {
  // No subscription
  if (!subscription.endDate) return false;

  const endDate = parseISO(subscription.endDate);
  // Subscription already expired
  if (isPast(endOfDay(endDate))) return false;

  // Subscription expiring soon
  return daysLeft(subscription) <= WARN_BEFORE_IN_DAYS;
};

const daysLeft = subscription => {
  const endDate = parseISO(subscription.endDate);
  if (isPast(endOfDay(endDate))) return 0;

  return differenceInCalendarDays(endDate, new Date());
};

const isPlanExceeded = (companyId, companies, planId) => {
  const index = R.pipe(
    R.sortBy(R.prop('id')),
    R.findIndex(v => v.id === companyId),
  )(companies);

  const allPlans = DAILY_PLANS.concat(ONE_TIME_PLANS);
  const plan = allPlans.find(v => v.id === planId);

  return plan ? index >= plan.maxCompanies : false;
};

const planToShow = (plans, subscription, companiesCount) => {
  const currentPlan = plans.find(v => v.id === subscription.planId);
  if (currentPlan) return currentPlan;

  const suitablePlan = plans.find(v => companiesCount <= v.maxCompanies);
  return suitablePlan;
};

const isOneTimePlan = planId => {
  return ONE_TIME_PLANS.some(v => v.id === planId);
};

export default {
  payNow,
  paySoon,
  daysLeft,
  isPlanExceeded,
  planToShow,
  isOneTimePlan,
};
