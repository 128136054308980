/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, ListItem, ListItemText, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';

import {sensorName} from 'helpers/sensor_names';
import type {Check} from 'types';

const PREFIX = 'Row';

const classes = {
  row: `${PREFIX}-row`,
  avatar: `${PREFIX}-avatar`,
  leftTextContainer: `${PREFIX}-leftTextContainer`,
  rightTextContainer: `${PREFIX}-rightTextContainer`,
  foundIconContainer: `${PREFIX}-foundIconContainer`,
  rowRed: `${PREFIX}-rowRed`,
  rowYellow: `${PREFIX}-rowYellow`,
  rowNeutral: `${PREFIX}-rowNeutral`,
  title: `${PREFIX}-title`,
  textYellow: `${PREFIX}-textYellow`,
  textRed: `${PREFIX}-textRed`,
  text: `${PREFIX}-text`,
  borderRed: `${PREFIX}-borderRed`,
  borderYellow: `${PREFIX}-borderYellow`,
  borderNeutral: `${PREFIX}-borderNeutral`,
  backdrop: `${PREFIX}-backdrop`,
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.row}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#202020',
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    position: 'relative',
    paddingRight: theme.spacing(4),
    borderRight: '2px solid transparent',
    borderLeft: '2px solid transparent',
  },

  [`& .${classes.avatar}`]: {
    minWidth: 16,
  },

  [`& .${classes.leftTextContainer}`]: {
    minWidth: 'unset',
    maxWidth: '70%',
  },

  [`& .${classes.rightTextContainer}`]: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(40),
    padding: theme.spacing(1.5, 0),
    borderRadius: '8px',
    border: '1px solid #8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.169px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  [`& .${classes.foundIconContainer}`]: {
    flexGrow: 0,
    minWidth: 'unset',
  },

  [`& .${classes.rowRed}`]: {
    borderColor: '#AE5658',
  },

  [`& .${classes.rowYellow}`]: {
    borderColor: '#AA8258',
  },

  [`& .${classes.rowNeutral}`]: {
    borderColor: '#8B8B8B',
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.169px',
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.textYellow}`]: {
    color: '#AA8258',
  },

  [`& .${classes.textRed}`]: {
    color: '#AE5658 !important',
  },

  [`& .${classes.text}`]: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.169px',
  },

  [`& .${classes.borderRed}`]: {
    borderColor: '#AE5658',
  },

  [`& .${classes.borderYellow}`]: {
    borderColor: '#AA8258',
  },

  [`& .${classes.borderNeutral}`]: {
    borderColor: '#8B8B8B',
  },

  [`& .${classes.backdrop}`]: {
    backgroundColor: theme.backdrop.main,
    position: 'absolute',
    zIndex: 1,
  },

  [`& .${classes.icon}`]: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
    marginRight: theme.spacing(4),
  },
}));

interface Props {
  check: Check;
  onClick: (check: Check) => void;
}

const Row = (props: Props) => {
  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const textContainerClassName = clsx(
    props.check.new > 0 && classes.borderRed,
    props.check.new === 0 && classes.borderYellow,
    props.check.found === 0 && classes.borderNeutral,
  );

  const textClassName = clsx(
    props.check.new > 0 && classes.textRed,
    props.check.new === 0 && classes.textYellow,
    props.check.found === 0 && classes.text,
  );

  const foundText = (check: Check) => {
    return `${check.found}`;
  };

  return (
    <StyledBox position='relative'>
      <ListItem
        className={clsx(
          classes.row,
          props.check.new > 0 && classes.rowRed,
          props.check.new === 0 && classes.rowYellow,
          props.check.found === 0 && classes.rowNeutral,
        )}
        onClick={handleClick}
      >
        <ListItemText
          className={classes.leftTextContainer}
          disableTypography
          primary={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                className={classes.icon}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!props.check.sensor.isError ? (
                  <EmojiObjectsOutlinedIcon fontSize='small' htmlColor='#fff' />
                ) : (
                  <NotificationImportantOutlinedIcon
                    fontSize='small'
                    htmlColor='#fff'
                  />
                )}
              </Box>

              <Typography className={classes.title}>
                {t(sensorName(props.check.sensor.name))}
              </Typography>
            </Box>
          }
        />

        <ListItemText
          className={clsx(classes.rightTextContainer, textContainerClassName)}
          disableTypography
          primary={
            <Typography
              align='right'
              className={clsx(classes.text, textClassName)}
            >
              {foundText(props.check)}&nbsp;
            </Typography>
          }
          secondary={
            <Typography
              align='right'
              className={clsx(classes.text, textClassName)}
            >
              {Boolean(props.check.new) && `(+${props.check.new})`}
            </Typography>
          }
        />
      </ListItem>
    </StyledBox>
  );
};

export default Row;
