/** @format */

import React, {useContext, useRef, useState} from 'react';

import onboarding1Machine, {
  ONBOARDING_1_ID,
} from 'machines/onboarding/onboarding1Machine';
import onboarding2Machine, {
  ONBOARDING_2_ID,
} from 'machines/onboarding/onboarding2Machine';
import onboarding3Machine, {
  ONBOARDING_3_ID,
} from 'machines/onboarding/onboarding3Machine';
import onboarding4Machine, {
  ONBOARDING_4_ID,
} from 'machines/onboarding/onboarding4Machine';
import xstateHelpers from 'helpers/xstateHelpers';

const OnboardingContext = React.createContext();

export const OnboardingProvider = props => {
  const onboardings = {
    [ONBOARDING_1_ID]: xstateHelpers.usePersistedMachine(onboarding1Machine),
    [ONBOARDING_2_ID]: xstateHelpers.usePersistedMachine(onboarding2Machine),
    [ONBOARDING_3_ID]: xstateHelpers.usePersistedMachine(onboarding3Machine),
    [ONBOARDING_4_ID]: xstateHelpers.usePersistedMachine(onboarding4Machine),
  };

  const [anchorRefs] = useState({
    [ONBOARDING_1_ID]: {
      'active.step1': useRef(null),
      'active.step2': useRef(null),
    },
    [ONBOARDING_2_ID]: {
      'active.step1': useRef(null),
      'active.step2': useRef(null),
      'active.step3': useRef(null),
      'active.step4': useRef(null),
    },
    [ONBOARDING_3_ID]: {
      'active.step1': useRef(null),
      'active.step2': useRef(null),
    },
    [ONBOARDING_4_ID]: {
      'active.step1': useRef(null),
      'active.step2': useRef(null),
    },
  });

  const [showOnboardings, setShowOnboardings] = useState({
    [ONBOARDING_1_ID]: false,
    [ONBOARDING_2_ID]: false,
    [ONBOARDING_3_ID]: false,
    [ONBOARDING_4_ID]: false,
  });

  const setShowOnboarding = (onboardingId, value) => {
    setShowOnboardings(v => ({...v, [onboardingId]: value}));
  };

  const showStep = (onboardingId, stepId) => {
    const {current} = onboardings[onboardingId];
    return showOnboardings[onboardingId] && current.matches(stepId);
  };

  return (
    <OnboardingContext.Provider
      value={{onboardings, anchorRefs, setShowOnboarding, showStep}}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
