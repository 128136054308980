/** @format */

import {useTranslation} from 'react-i18next';

import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';

// https://material-ui.com/components/text-fields/#input-adornments
const EmailFieldFilled = props => {
  const {t} = useTranslation();

  return (
    <LegacyTextFieldFilled
      myProps={props.myProps}
      type={'email'}
      placeholder={t('Почта', 'Почта')}
      {...props}
    />
  );
};

export default EmailFieldFilled;
