/** @format */

import Alert from '@mui/material/Alert';
import {styled} from '@mui/material/styles';
import {Box, Grid, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import Loading from 'components/loading/Loading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useSidebarContext} from 'contexts/SidebarContext';

const PREFIX = 'ConfirmDeleteCompany';

const classes = {
  body: `${PREFIX}-body`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.body}`]: {
    color: theme.palette.text.secondary,
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID!) {
    currentUser {
      company(id: $id) {
        id
        uuid
        name
      }
      firstCompany {
        id
        uuid
        name
        tokens {
          id
          provider
        }
      }
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: ID!) {
    deleteCompany(id: $id)
  }
`;

const ConfirmDeleteCompany = () => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();
  const {currentCompany, switchCompany} = useCompanyContext();
  const {refreshSidebar} = useSidebarContext();

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {id: companyId},
  });
  const [deleteCompany, {loading: deleteCompanyLoading}] =
    useMutation(DELETE_COMPANY);

  if (loading) return <Loading />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {company, firstCompany} = data.currentUser;

  const renderButton = () => {
    const handleConfirm = async () => {
      mixpanel.track('click_confirm_delete_company_button', {
        company_id: company.id,
        company_uuid: company.uuid,
        company_name: company.name,
      });

      await deleteCompany({variables: {id: companyId}});

      if (company.id === currentCompany.id) {
        switchCompany(firstCompany);
      }

      refreshSidebar();
      navigate('/home/settings/connect', {replace: true});
    };

    const handleCancel = () => {
      mixpanel.track('click_cancel_delete_company_button', {
        company_id: company.id,
        company_uuid: company.uuid,
        company_name: company.name,
      });

      navigate(-1);
    };

    return (
      <Box
        sx={{
          mt: 16,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={deleteCompanyLoading}
              fullWidth
              onClick={handleConfirm}
            >
              <Trans>Удалить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={5}>
            <SecondaryMediumButton onClick={handleCancel}>
              <Trans>Оставить</Trans>
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Root>
      <ModalPage
        header={t(
          'Вы уверены, что хотите удалить проект "{{companyName}}"?',
          'Вы уверены, что хотите удалить проект "{{companyName}}"?',
          {companyName: company.name},
        )}
      >
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography className={classes.body} variant='body1'>
            <Trans>
              Мы перестанем собирать данные по всем подключенным к этому проекту
              кабинетам.
            </Trans>
          </Typography>
        </Box>
        {renderButton()}
      </ModalPage>
    </Root>
  );
};

export default ConfirmDeleteCompany;
