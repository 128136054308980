/** @format */

import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Theme} from '@mui/material/styles';

const PREFIX = 'PlusMinusButton';

const classes = {
  button: `${PREFIX}-button`,
};

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.button}`]: {
    backgroundColor: '#ECECEC',
    height: 23,
    width: 23,
    '&:hover': {
      backgroundColor: '#E1E1E1',
    },
  },
}));

type Props = {
  type: 'PLUS' | 'MINUS';
  onClick: () => void;
};

const PlusMinusButton = (props: Props) => {
  return (
    <StyledIconButton
      className={classes.button}
      onClick={props.onClick}
      size='large'
    >
      {props.type === 'PLUS' && (
        <img
          alt='Plus'
          height='9'
          src={new URL('./images/plus.png', import.meta.url).href}
          width='9'
        />
      )}
      {props.type === 'MINUS' && (
        <img
          alt='Minus'
          height='2'
          src={new URL('./images/minus.png', import.meta.url).href}
          width='11'
        />
      )}
    </StyledIconButton>
  );
};

export default PlusMinusButton;
