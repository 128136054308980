/** @format */
import {Box, Typography} from '@mui/material';
import {clsx} from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

interface Props {
  Name: React.ReactElement;
  Value: string;
  mt?: number;
}

const useStyles = makeStyles(styles);

const CustomInfoRow = (props: Props) => {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='space-between' mt={props.mt}>
      <Typography className={clsx(classes.text, classes.title)}>
        {props.Name}
      </Typography>

      <Typography className={clsx(classes.text, classes.value)}>
        {props.Value}
      </Typography>
    </Box>
  );
};

export default CustomInfoRow;
