/** @format */

import React, {useCallback, useEffect, useContext, useState} from 'react';

import {gql, useQuery} from '@apollo/client';

import {useAuthContext} from 'contexts/AuthContext';
import {useCompanyContext} from 'contexts/CompanyContext';

const ScoreContext = React.createContext();

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      companies {
        id
        allLastChecks {
          id
          checks
          cost
          found
          new
          fixed
          sensor {
            id
            name
            isPaused
            platform
            isError
          }
        }
        lastScores {
          adType
          value
        }
      }
    }
  }
`;

export const ScoreProvider = props => {
  const {currentCompany, currentAdType} = useCompanyContext();
  const {authToken} = useAuthContext();

  const [lastScores, setLastScores] = useState({});
  const {data, refetch} = useQuery(GET_CURRENT_USER, {skip: !authToken});

  useEffect(() => {
    if (!data) return;

    const {companies} = data.currentUser;

    const newLastScores = companies.reduce((acc, company) => {
      if (company.lastScores.length === 0) {
        acc[company.id] = {CONTEXT: null, TARGET: null};
      } else {
        acc[company.id] = {
          CONTEXT: company.lastScores.find(v => v.adType === 'CONTEXT').value,
          TARGET: company.lastScores.find(v => v.adType === 'TARGET').value,
        };
      }

      return acc;
    }, {});

    setLastScores(newLastScores);
  }, [data]);

  const currentScore = () => {
    if (!currentCompany) return null;
    if (!lastScores[currentCompany.id]) return null;

    return lastScores[currentCompany.id][currentAdType];
  };

  const refreshScores = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ScoreContext.Provider value={{lastScores, currentScore, refreshScores}}>
      {props.children}
    </ScoreContext.Provider>
  );
};

export const useScoreContext = () => useContext(ScoreContext);
