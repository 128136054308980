/** @format */

import {Tabs, TabsTypeMap} from '@mui/material';
import {styled} from '@mui/material/styles';
import {OverridableComponent} from '@mui/material/OverridableComponent';

const PREFIX = 'StyledTabsNew';

const classes = {
  root: `${PREFIX}-root`,
  indicator: `${PREFIX}-indicator`,
};

const StyledTabs = styled(Tabs)(({theme}) => ({
  borderRadius: '8px 8px 0 0',
  height: 48,
  minHeight: 48,
  backgroundColor: '#202020',
  boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',

  [`& .MuiTabs-indicator`]: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 1,
    '& > span': {
      backgroundColor: theme.palette.success.main,
      width: '100%',
    },
  },
}));

const StyledTabsNew = ((props: any) => (
  <StyledTabs TabIndicatorProps={{children: <span />}} {...props} />
)) as OverridableComponent<TabsTypeMap>;

export default StyledTabsNew;
