/** @format */

import {useEffect} from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@mui/styles';
import {useCookies} from 'react-cookie';
import {useFlags} from 'launchdarkly-react-client-sdk';

import './App.css';
import Auth from 'pages/Auth';
import Errors from 'pages/Errors';
import Guest from 'pages/Guest';
import Home from 'pages/Home';
import HomeNew from 'pages/HomeNew';
import HomeGrouping from 'pages/HomeGrouping';
import Oops from 'pages/Oops';

import Share from 'pages/Share';

import Wizard from 'pages/Wizard';

import PrivateRoute from 'components/PrivateRoute';
import TelegramFab from 'components/fabs/TelegramFab';

import {ROUTES} from 'constants/ROUTES';
import routerHelpers from 'helpers/routerHelpers';
import theme from 'theme';
import whiteTheme from 'white_theme';
import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useSidebarContext} from 'contexts/SidebarContext';

// 1 month
const COOKIE_MAX_AGE = 60 * 60 * 24 * 30;

const App = () => {
  const searchParams = routerHelpers.useSearchParams();
  const partner = searchParams.get('partner');

  const [, setCookie] = useCookies();

  const {isNewVersionEnabled} = useFlags();

  const {authToken} = useAuthContext();
  const mixpanelContext = useMixpanelContext();
  const {refreshSidebar} = useSidebarContext();
  const domain = routerHelpers.secondLevelDomain();

  useEffect(() => {
    if (partner) {
      setCookie('partner', partner, {domain, maxAge: COOKIE_MAX_AGE});
    }

    if (!routerHelpers.IsRuVersion()) {
      let config = {ID: '4uL3Rd5AbrGxKW4dn'};
      if (authToken != null) {
        config.clientID = authToken.user.uuid;
      }
    }

    mixpanelContext.setup(authToken);

    // Refresh sidebar on sign in or sign up
    if (authToken) {
      refreshSidebar();
    }
  }, [partner, setCookie, authToken, mixpanelContext, refreshSidebar, domain]);

  return (
    <>
      <Routes>
        <Route
          path={`${ROUTES.OOPS}`}
          element={
            <ThemeProvider theme={theme}>
              <Oops />
            </ThemeProvider>
          }
        />

        <Route
          path={`${ROUTES.AUTH}/*`}
          element={
            <ThemeProvider theme={whiteTheme}>
              <Auth />
            </ThemeProvider>
          }
        />

        <Route path={`${ROUTES.GUEST}/*`} element={<Guest />} />

        <Route
          path={`${ROUTES.WIZARD}/*`}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <Wizard />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={`${ROUTES.HOME}/*`}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <Home />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.HOME_NEW}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <HomeNew />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={`${ROUTES.ERRORS}/*`}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <Errors />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={`${ROUTES.SHARE}/*`}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <Share />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={`${ROUTES.GROUPING}/*`}
          element={
            <PrivateRoute>
              <ThemeProvider theme={theme}>
                <HomeGrouping />
              </ThemeProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.INDEX}
          element={
            <Navigate
              to={
                isNewVersionEnabled ? `/${ROUTES.HOME_NEW}` : `/${ROUTES.HOME}`
              }
            />
          }
        />
      </Routes>

      {routerHelpers.IsRuVersion && <TelegramFab />}
    </>
  );
};

export default App;
