/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      lineHeight: '20px',
    },
  },
  email: {
    fontWeight: '700',
  },
}));

const Deleted = props => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();

  const handleClickClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      header={t('Почта исключена из рассылки', 'Почта исключена из рассылки')}
      onClose={handleClickClose}
    >
      <Typography className={classes.text} display='inline'>
        <Trans>Мы больше не будем отправлять ежедневные отчёты на адрес </Trans>
      </Typography>
      <Typography
        className={clsx(classes.text, classes.email)}
        display='inline'
      >
        {props.email}
      </Typography>

      <Box mt={16}>
        <PrimaryMediumButton onClick={handleClickClose}>
          <Trans>Понятно</Trans>
        </PrimaryMediumButton>
      </Box>
    </Modal>
  );
};

export default Deleted;
