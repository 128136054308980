/** @format */

import {Button} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledButton = styled(Button)(({theme}) => ({
  border: '1px solid #202020',
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

type Props = {
  children: React.ReactNode;
  ml?: number;
  onClick: () => void;
};

const OutlinedSmallButton = (props: Props) => (
  <StyledButton fullWidth={false} {...props} />
);

export default OutlinedSmallButton;
