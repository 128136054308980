/** @format */

import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
const PREFIX = 'ConnectButton';

const classes = {
  button: `${PREFIX}-button`,
};

const StyledIconButton = styled(IconButton)(({theme}) => ({
  [`&.${classes.button}`]: {
    backgroundColor: theme.palette.success.main,
    height: 44,
    width: 44,
    '&:hover': {
      backgroundColor: '#648F13',
    },
  },
}));

const ConnectButton = props => {
  return (
    <StyledIconButton
      className={classes.button}
      onClick={props.onConnect}
      size='large'
    >
      <img
        alt='Connect'
        height='19'
        src={new URL('./images/plus.png', import.meta.url)}
        width='19'
      />
    </StyledIconButton>
  );
};

export default ConnectButton;
