/** @format */

import {styled} from '@mui/material/styles';

import PrimarySmallButton from 'components/buttons/PrimarySmallButton';

const StyledPrimarySmallButton = styled(PrimarySmallButton)(({theme}) => ({
  backgroundColor: 'transparent',
  border: '1px solid #5A5A5A',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  component?: any;
  href?: string;
  target?: string;
};

const TransparentSmallButton = (props: Props) => (
  <StyledPrimarySmallButton {...props} />
);

export default TransparentSmallButton;
