/** @format */

import {useTranslation} from 'react-i18next';

import LegacyTextField from 'components/inputs/LegacyTextField';

// https://material-ui.com/components/text-fields/#input-adornments
const EmailField = props => {
  const {t} = useTranslation();

  return (
    <LegacyTextField
      myProps={props.myProps}
      type={'email'}
      placeholder={t('Почта', 'Почта')}
      {...props}
    />
  );
};

export default EmailField;
