/** @format */

import {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import BaseStep from 'onboarding/BaseStep';
import {ONBOARDING_2_ID} from 'machines/onboarding/onboarding2Machine';
import {useOnboardingContext} from 'contexts/OnboardingContext';

const STEP_ID = 'active.step3';

const Step3 = props => {
  const navigate = useNavigate();

  const context = useOnboardingContext();
  const showStep = context.showStep(ONBOARDING_2_ID, STEP_ID);

  useEffect(() => {
    if (!showStep) return;
    navigate(props.hasErrors ? '#errors' : '#warnings');
  }, [showStep, navigate, props.hasErrors]);

  const title = 'В этом блоке все ошибки и замечания';

  const body =
    'Когда с ошибками покончено, можно переходить к замечаниям — ' +
    'это точки роста твоей рекламы.';

  return (
    <BaseStep
      body={body}
      iconSrc={new URL('./images/step3.jpg', import.meta.url)}
      onboardingId={ONBOARDING_2_ID}
      showOnboarding={props.showOnboarding}
      stepId={STEP_ID}
      title={title}
    />
  );
};

export default Step3;
