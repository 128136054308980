/** @format */

import {styled} from '@mui/material/styles';

import PrimarySmallButton from 'components/buttons/PrimarySmallButton';

const StyledPrimarySmallButton = styled(PrimarySmallButton)(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#828282',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&:disabled': {
    backgroundColor: '#DBDBDB',
  },
}));

const SecondarySmallButton = props => <StyledPrimarySmallButton {...props} />;

export default SecondarySmallButton;
