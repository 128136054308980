/** @format */

import {Snackbar as MUISnackbar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/material';

interface Props {
  isSnackbarOpen: boolean;
  handleSnackbarClose: () => void;
  message: string;
}

const Snackbar = (props: Props) => (
  <MUISnackbar
    open={props.isSnackbarOpen}
    autoHideDuration={3000}
    onClose={props.handleSnackbarClose}
    message={props.message}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    action={
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={props.handleSnackbarClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    }
  />
);

export default Snackbar;
