/** @format */
import clsx from 'clsx';

import {styled} from '@mui/material/styles';

import {Box, Grid, Link, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import TransparentMediumButton from 'components/buttons/TransparentMediumButton';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'InvalidToken';

const classes = {
  title: `${PREFIX}-title`,
  body: `${PREFIX}-body`,
  underline: `${PREFIX}-underline`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '25px',
  },

  [`& .${classes.body}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },

  [`& .${classes.underline}`]: {
    textDecoration: 'underline',
  },
}));

const InvalidToken = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();

  const renderButtons = () => {
    const handleReconnect = () => {
      navigate('/home/settings/connect');
    };

    return (
      <Box mt={6}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <PrimaryMediumButton fullWidth onClick={handleReconnect}>
              <Trans>Переподключить кабинет</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TransparentMediumButton
              fullWidth
              href='https://t.me/adsensor_amocrm_bot'
              target='_blank'
            >
              <Trans>Написать в поддержку</Trans>
            </TransparentMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleClickLink = () => {
    mixpanel.track('open_instruction_reconnect_token', {});
  };

  return (
    <StyledBox
      sx={{
        mt: 5,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <img
          alt='Finger'
          height='29'
          src={new URL('./images/finger.png', import.meta.url)}
          width='22'
        />
        <Box
          sx={{
            ml: 2,
          }}
        >
          <Typography className={classes.title}>
            <Trans>Проблема с доступом к кабинету</Trans>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Typography className={classes.body}>
          <Trans>
            Возникла проблема с доступом к вашему рекламному кабинету, и
            AdSensor больше не может делать проверки.
            <br />
            Это могло произойти по нескольким причинам:
            <br />
            — Вы отозвали доступ AdSensor к вашему рекламному кабинету в Yandex,
            Google и т.д.
            <br />
            — Истек срок жизни токена
            <br />
            — Вы или ваши коллеги поменяли пароль в Yandex, Google и т.д.
            <br />
            <br />
            Как это исправить читайте&nbsp;
            <Link
              href='https://adsensor.ru/instructions#rec520666473'
              className={clsx(classes.body, classes.underline)}
              target='_blank'
              onClick={handleClickLink}
            >
              тут
            </Link>
          </Trans>
        </Typography>
      </Box>
      {renderButtons()}
    </StyledBox>
  );
};

export default InvalidToken;
