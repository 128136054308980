/** @format */

import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AdTypePlatforms from 'components/icons/AdTypePlatforms';
import BoxPlate from 'components/plates/BoxPlate';
import type {AdType} from 'types';

interface Props {
  adType: AdType;
  children: React.ReactChild;
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
  }),
);

const AdTypePlate = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const text = () => {
    if (props.adType === 'CONTEXT') return t('Контекст', 'Контекст');
    if (props.adType === 'TARGET') return t('Таргет', 'Таргет');

    return null;
  };

  return (
    <BoxPlate>
      <Box display='flex' flexGrow={1} justifyContent='space-between'>
        <Box>
          <Typography align='left' className={classes.text}>
            {text()}
          </Typography>
        </Box>
        <AdTypePlatforms adTypes={[props.adType]} size={20} />
      </Box>
      <Box mt={4}>{props.children}</Box>
    </BoxPlate>
  );
};

export default AdTypePlate;
