/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

import imageHelpers from 'helpers/imageHelpers';

const PREFIX = 'ConnectedProviders';

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.icon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const ConnectedProviders = props => {
  return (
    <StyledBox
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          height: props.size,
        }}
      />
      {props.adTypes.includes('CONTEXT') && (
        <>
          {props.tokens.find(v => v.provider === 'YANDEX') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'YANDEX',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'GOOGLE') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'GOOGLE',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}
      {props.adTypes.includes('TARGET') && (
        <>
          {props.tokens.find(v => v.provider === 'VKONTAKTE') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'VKONTAKTE',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'VKONTAKTE_ADS') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'VKONTAKTE_ADS',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'FACEBOOK') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'FACEBOOK',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}
      {props.adTypes.includes('STATS') && (
        <>
          {props.tokens.find(v => v.provider === 'METRIKA') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'METRIKA',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}
    </StyledBox>
  );
};

ConnectedProviders.defaultProps = {
  disabled: false,
};

export default ConnectedProviders;
