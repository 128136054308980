/** @format */

import {Box, Typography} from '@mui/material';

import {styled} from '@mui/material/styles';

type Props = {
  text: string;
  imageSrc: string;
  onClick: () => void;
};

const PREFIX = 'MenuItemImage';

const classes = {
  container: `${PREFIX}-container`,
  text: `${PREFIX}-text`,
  image: `${PREFIX}-image`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    width: '100%',
  },
  [`& .${classes.text}`]: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  [`& .${classes.image}`]: {
    height: 17,
  },
}));

const MenuItemImage = (props: Props) => {
  return (
    <StyledBox
      className={classes.container}
      onClick={props.onClick}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            mr: 2,
            pt: 0.5,
          }}
        >
          <img src={props.imageSrc} className={classes.image} />
        </Box>
        <Box
          sx={{
            display: 'inline-block',
          }}
        >
          <Typography className={classes.text}>{props.text}</Typography>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default MenuItemImage;
