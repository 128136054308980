/** @format */

const CustomYTick = (props: any) => {
  return (
    <text
      dx={-40}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.payload.value}
    </text>
  );
};

export default CustomYTick;
