/** @format */

import {styled} from '@mui/material/styles';
import {Typography} from '@mui/material';

const StyledTypography = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '30px',
}));

type Props = Record<string, unknown>;

const Title = (props: Props) => <StyledTypography {...props} />;

export default Title;
