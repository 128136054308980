/** @format */

import {Box, Hidden} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import OutlinedSmallButton from 'components/buttons/OutlinedSmallButton';

const PREFIX = 'BackHeader';

const classes = {
  goBackText: `${PREFIX}-goBackText`,
};

const StyledOutlinedSmallButton = styled(OutlinedSmallButton)(({theme}) => ({
  [`& .${classes.goBackText}`]: {
    color: theme.palette.text.primary,
  },
}));

type Props = {
  backPath?: string;
  onClickBack?: () => void;
};

const BackHeader = (props: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }

    if (props.backPath) {
      navigate(props.backPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledOutlinedSmallButton onClick={handleClick}>
      <img
        alt='Go back'
        height='11'
        src={new URL('./images/arrow_left.png', import.meta.url).href}
        width='6'
      />
      <Hidden smDown>
        <Box
          className={classes.goBackText}
          sx={{
            ml: 3,
          }}
        >
          <Trans>Назад</Trans>
        </Box>
      </Hidden>
    </StyledOutlinedSmallButton>
  );
};

export default BackHeader;
