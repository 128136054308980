/** @format */

import {styled} from '@mui/material/styles';
import {Box, Button} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from 'react-router-dom';

import adTypeHelpers from 'helpers/adTypeHelpers';

import Row from './Row';

const PREFIX = 'Plate';

const classes = {
  plateHeader: `${PREFIX}-plateHeader`,
  editButton: `${PREFIX}-editButton`,
  editButtonIcon: `${PREFIX}-editButtonIcon`,
};

const Root = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '49%',
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: '16px',
  background: '#202020',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  [`& .${classes.plateHeader}`]: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#F1F1F1',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.36px',
  },

  [`& .${classes.editButton}`]: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    width: '48px',
  },
  [`& .${classes.editButtonIcon}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
  },
}));

interface Props {
  company: any;
  handleAdClick: (any, any) => void;
}

const Plate = (props: Props) => {
  const navigate = useNavigate();

  const isContextConnected = adTypeHelpers.isAdTypeConnected(
    props.company.tokens,
    'CONTEXT',
  );
  const isTargetConnected = adTypeHelpers.isAdTypeConnected(
    props.company.tokens,
    'TARGET',
  );
  const areNoTokens = props.company.tokens.length === 0;

  const isNoAccessToContextAccount = adTypeHelpers
    .filterAdTypeFromPlatforms(props.company.tokens, 'CONTEXT')
    .some(token => !token.isValid);
  const isNoAccessToTargetAccount = adTypeHelpers
    .filterAdTypeFromPlatforms(props.company.tokens, 'TARGET')
    .some(token => !token.isValid);

  const {recomendations, newErrors} = props.company.lastChecks.reduce(
    (acc, cur) => ({
      recomendations: acc.recomendations + cur.found,
      newErrors: acc.newErrors + cur.new,
    }),
    {
      recomendations: 0,
      newErrors: 0,
    },
  );
  const contextBudgetScore = props.company.lastBudgetScores.find(
    v => v.adType === 'CONTEXT',
  ).value;
  const targetBudgetScore = props.company.lastBudgetScores.find(
    v => v.adType === 'TARGET',
  ).value;

  return (
    <Root>
      <Box className={classes.plateHeader}>
        {props.company.name}
        <Button
          className={classes.editButton}
          onClick={() =>
            navigate(
              `/home/settings/connect/company?companyId=${props.company.id}`,
            )
          }
        >
          <EditIcon className={classes.editButtonIcon} />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isContextConnected && (
          <Row
            adType='CONTEXT'
            isLaunched={props.company.isLaunched}
            lastCheckCollectDate={props.company.lastCheckCollectDate}
            isNoAccessToAccount={isNoAccessToContextAccount}
            areNoTokens={areNoTokens}
            budgetScore={contextBudgetScore}
            recomendations={recomendations}
            newErrors={newErrors}
            onClick={() => props.handleAdClick('CONTEXT', props.company)}
          />
        )}
        {isTargetConnected && (
          <Row
            adType='TARGET'
            budgetScore={targetBudgetScore}
            lastCheckCollectDate={props.company.lastCheckCollectDate}
            isNoAccessToAccount={isNoAccessToTargetAccount}
            areNoTokens={areNoTokens}
            onClick={() => props.handleAdClick('TARGET', props.company)}
          />
        )}
      </Box>
    </Root>
  );
};

export default Plate;
