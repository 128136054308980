/** @format */

import {createContext, useContext, useState} from 'react';

import * as R from 'ramda';

const BannerContext = createContext();

const VIEWED_BANNERS_KEY = 'VIEWED_BANNERS';
export const FEEDBACK_BANNER = 'FEEDBACK_BANNER';
export const NO_DATA_BANNER = 'NO_DATA_BANNER';
export const IS_DEMO_BANNER = 'IS_DEMO_BANNER';
export const PAYMENT_ERROR_BANNER = 'PAYMENT_ERROR_BANNER';
export const STRAT_LOW_CONV_BANNER = 'STRAT_LOW_CONV_BANNER';

export const BannerProvider = props => {
  const initialState = R.pipe(
    R.defaultTo(''),
    R.split(','),
    R.reject(R.isEmpty),
  )(localStorage.getItem(VIEWED_BANNERS_KEY));

  const [viewed, setViewed] = useState(initialState);

  const isBannerViewed = banner => R.includes(banner, viewed);

  const setBannerViewed = banner => {
    const newViewed = R.union([banner], viewed);
    localStorage.setItem(VIEWED_BANNERS_KEY, newViewed);
    setViewed(newViewed);
  };

  return (
    <BannerContext.Provider value={{isBannerViewed, setBannerViewed}}>
      {props.children}
    </BannerContext.Provider>
  );
};

export const useBannerContext = () => useContext(BannerContext);
