/** @format */

import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Info from './Info';
import Plan from './Plan';
import paymentHelpers from 'helpers/paymentHelpers';

export const ONE_TIME_PLANS = [
  {
    id: '7',
    type: 'one-time',
    companiesText: 'От 1 до 3 проектов',
    description:
      'Вам будет доступно по 1 проверке для каждого проекта в течение 1 месяца',
    maxCompanies: 3,
    name: 'Стандартный',
    price: 1999,
    priceText: '1 999',
  },
  {
    id: '8',
    type: 'one-time',
    companiesText: 'От 4 до 10 проектов',
    description:
      'Вам будет доступно по 1 проверке для каждого проекта в течение 1 месяца',
    maxCompanies: 10,
    name: 'Оптимальный',
    price: 4999,
    priceText: '4 999',
  },
  {
    id: '9',
    type: 'one-time',
    companiesText: 'Без ограничений в количестве проектов',
    description:
      'Вам будет доступно по 1 проверке для каждого проекта в течение 1 месяца',
    maxCompanies: 9999,
    name: 'Премиум',
    price: 10999,
    priceText: '10 999',
  },
];

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '28px',
  },
}));

const OneTimePlans = props => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>Разовая проверка</Typography>

      <Info
        companiesCount={props.companies.length}
        title={'Мы проверим'}
        totalStats={props.totalStats}
      />

      <Box mt={10}>
        <Plan
          allPlans={ONE_TIME_PLANS}
          companiesCount={props.companies.length}
          plan={paymentHelpers.planToShow(
            ONE_TIME_PLANS,
            props.subscription,
            props.companies.length,
          )}
          subscription={props.subscription}
          onChangePlan={props.onChangePlan}
          onRenewPlan={props.onRenewPlan}
        />
      </Box>
    </>
  );
};

export default OneTimePlans;
