/** @format */

import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

import BackHeader from 'components/BackHeader';
import Dashboard from 'components/Dashboard';

const BusinessMetrics = () => {
  return (
    <>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <BackHeader />
      </Box>
      <Typography variant='h1'>
        <Trans>Бизнес-показатели</Trans>
      </Typography>
      <Dashboard showExtendedStats showGraphs showSearchNetwork />
    </>
  );
};

export default BusinessMetrics;
