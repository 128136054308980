/** @format */

// import {useTranslation} from 'react-i18next';
// import {gql, useQuery} from '@apollo/client';

// import ClosableBanner from 'components/banners/ClosableBanner';
// import {IS_DEMO_BANNER, useBannerContext} from 'contexts/BannerContext';

// export const GET_CURRENT_USER = gql`
//   query GetCurrentUser {
//     currentUser {
//     }
//   }
// `;

export const IsDemoBanner = () => {
  //   const {t} = useTranslation();

  //   const {isBannerViewed, setBannerViewed} = useBannerContext();

  //   const {loading, error, data} = useQuery(GET_CURRENT_USER);
  //   if (loading || error) return null;

  //   const skip = data.currentUser.isDemoAccount  || isBannerViewed(IS_DEMO_BANNER);

  //   if (skip) return null;

  //   const handleClose = () => {
  // setBannerViewed(IS_DEMO_BANNER);
  //   };

  //   return (
  //     <ClosableBanner
  //       errorType='is_demo'
  //       title={t(
  //         'Вы используете ограниченную версию',
  //         'Вы используете ограниченную версию',
  //       )}
  //       text={t(
  //         'Для подключения полной функциональности обратитесь к вашему аккаунт-менеджеру.',
  //         'Для подключения полной функциональности обратитесь к вашему аккаунт-менеджеру.',
  //       )}
  //       onClose={handleClose}
  //     />
  //   );

  return null;
};
