/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, ButtonBase, Grid} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'NativeMenu';

const classes = {
  item: `${PREFIX}-item`,
  activeItem: `${PREFIX}-activeItem`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.item}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '17px',
  },

  [`& .${classes.activeItem}`]: {
    color: theme.palette.success.main,
  },
}));

const NativeMenu = props => {
  const {mixpanel} = useMixpanelContext();

  const handleClick = name => () => {
    const lcName = name.toLowerCase();
    mixpanel.people.increment(`click_${lcName}_top_menu_count`);
    mixpanel.track(`click_${lcName}_top_menu`);
  };

  const renderItems = (items, alignRight = false) => (
    <Grid
      container
      spacing={8}
      className={props.className}
      sx={{
        justifyContent: alignRight ? 'flex-end' : 'flex-start',
      }}
    >
      {items.map((v, i) => {
        const isActiveItem = props.activeItem
          ? props.activeItem.name === v.name
          : false;

        const className = clsx(classes.item, {
          [classes.activeItem]: isActiveItem,
        });

        return (
          <Grid key={i} item>
            <ButtonBase
              className={className}
              component={RouterLink}
              disableRipple
              to={v.path}
              onClick={handleClick(v.name)}
            >
              {v.text}
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <StyledBox display='flex' justifyContent='space-between' width={'100%'}>
      {props.leftItems && renderItems(props.leftItems)}
      {props.rightItems && renderItems(props.rightItems, true)}
    </StyledBox>
  );
};

export default NativeMenu;
