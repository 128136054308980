/** @format */
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary,
  fontSize: 20,
  fontWeight: 400,
  lineHeight: '23px',
}));

interface Props {
  children: React.ReactNode;
}

const BoxPlateTitle = (props: Props) => {
  return <StyledTypography>{props.children}</StyledTypography>;
};

export default BoxPlateTitle;
