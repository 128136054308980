/** @format */

import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';

import BaseLayout from 'layouts/BaseLayout';
import JuliaBot from 'components/managers/JuliaBot';

const PREFIX = 'Oops';

const classes = {
  title: `${PREFIX}-title`,
  body: `${PREFIX}-body`,
};

const Root = styled(Box)(({_theme}) => ({
  [`& .${classes.title}`]: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: '34px',
  },

  [`& .${classes.body}`]: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '18px',
  },
}));

const Oops = () => {
  return (
    <Root>
      <BaseLayout>
        <Typography className={classes.title}>
          <Trans>Что-то пошло не так</Trans>
        </Typography>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography className={classes.body}>
            <Trans>
              Чтобы получить доступ в свой кабинет AdSensor и следить за тем,
              качественно ли вам ведут рекламу, напиши вашему персональному
              менеджеру.
            </Trans>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <JuliaBot />
        </Box>
      </BaseLayout>
    </Root>
  );
};

export default Oops;
