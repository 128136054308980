/** @format */

const floor = (number: number, precision: number): number => {
  const factor = 10 ** precision;
  return Math.floor(number * factor) / factor;
};

const round = (number: number, precision: number): number => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

const formatRUB = (number: number): string => {
  return Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
    // https://stackoverflow.com/questions/41045270
    minimumFractionDigits: 0,
  }).format(number);
};

const buildFormatter = (
  currency: string,
  language: string
): Intl.NumberFormat => {
  if (currency === '' || currency === 'mixed') {
    return Intl.NumberFormat(language, {
      maximumFractionDigits: 0,
      // https://stackoverflow.com/questions/41045270
      minimumFractionDigits: 0,
    });
  }
  return Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    // https://stackoverflow.com/questions/41045270
    minimumFractionDigits: 0,
  });
};

export default {
  buildFormatter,
  floor,
  formatRUB,
  round,
};
