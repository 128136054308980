/** @format */

import {useEffect, useState, ChangeEvent} from 'react';

import {Box, Grid, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';

import MediumTextField from 'components/inputs/MediumTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

interface Props {
  initialName?: string;
  canCreate: boolean;
  loading: boolean;
  isTrial: boolean;
  isNew: boolean;
  saveError?: string;
  onSave: (name: string) => void;
}

const NAME_HINT = 'Назовите проект, которые вы хотите проанализировать';

const Form = (props: Props) => {
  const [state, setState] = useState({name: ''});

  const {t} = useTranslation();

  useEffect(() => {
    setState({name: props.initialName || ''});
  }, [props.initialName]);

  const handleChange = (event: ChangeEvent<{value: unknown; name: string}>) => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleNext = () => {
    props.onSave(state.name);
  };

  const restrictionMessageVisible = !props.canCreate && props.isNew;
  const disableButton =
    props.loading || !state.name || restrictionMessageVisible;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <MediumTextField
            error={Boolean(props.saveError)}
            placeholder={t('Название проекта', 'Название проекта')}
            value={state.name}
            inputProps={{name: 'name'}}
            myProps={{hint: t(NAME_HINT, NAME_HINT), placeholderFocused: ''}}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryMediumButton disabled={disableButton} onClick={handleNext}>
            <Trans>Продолжить</Trans>
          </PrimaryMediumButton>
        </Grid>
        <Grid item xs={12}>
          {restrictionMessageVisible && (
            <Box
              sx={{
                mt: 3,
              }}
            >
              <Typography variant='body1'>
                {props.isTrial && (
                  <Trans>
                    Исчерпан лимит установленный для вашего триал-режима.
                    <br />
                    Для расширения возможностей - обратитесь к вашему менеджеру.
                  </Trans>
                )}
                {!props.isTrial && (
                  <Trans>
                    Исчерпан лимит установленный для вашего тарифа.
                    <br />
                    Для расширения возможностей - обратитесь к вашему менеджеру.
                  </Trans>
                )}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Form;
