/** @format */

import BaseManager from './BaseManager';

type Props = {
  buttonVariant?: 'PRIMARY' | 'SECONDARY';
  variant?: 'DARK' | 'LIGHT';
};

const JuliaBot = (props: Props) => {
  const {buttonVariant = 'PRIMARY', variant = 'DARK'} = props;

  return (
    <BaseManager
      buttonVariant={buttonVariant}
      imageSrc={new URL('./images/julia.png', import.meta.url).href}
      name='Юля'
      phone='+74991133519'
      telegram='https://t.me/AdSensor_bot'
      variant={variant}
    />
  );
};

export default JuliaBot;
