/** @format */

import {useEffect} from 'react';

import {Route, Routes} from 'react-router-dom';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import AuthLayout from 'layouts/AuthLayout';

import {ROUTES} from 'constants/ROUTES';

import EmailSignIn from './EmailSignIn';
import EmailSignUp from './EmailSignUp';
import ResetPassword from './ResetPassword';
import ResetPasswordEmail from './ResetPasswordEmail';
import ResetPasswordSent from './ResetPasswordSent';
import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifyCode from './VerifyCode';

const Auth = () => {
  const {mixpanel, setup} = useMixpanelContext();

  useEffect(() => {
    // Initialize Mixpanel before tracking events
    setup();
    mixpanel.track('visit_app_landing');
  }, [mixpanel, setup]);

  return (
    <AuthLayout>
      <Routes>
        <Route path={ROUTES.EMAIL_SING_IN} element={<EmailSignIn />} />

        <Route path={ROUTES.PHONE_SIGN_UP} element={<SignUp />} />

        <Route path={ROUTES.EMAIL_SING_UP} element={<EmailSignUp />} />

        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />

        <Route path={ROUTES.SIGN_UP} element={<SignUp />} />

        <Route
          path={ROUTES.RESET_PASSWORD_SENT}
          element={<ResetPasswordSent />}
        />

        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

        <Route path={ROUTES.PHONE_RESET_PASSWORD} element={<ResetPassword />} />

        <Route
          path={ROUTES.EMAIL_RESET_PASSWORD}
          element={<ResetPasswordEmail />}
        />

        <Route path={ROUTES.VERIFY_CODE} element={<VerifyCode />} />

        <Route index element={<SignIn />} />
      </Routes>
    </AuthLayout>
  );
};

export default Auth;
