/** @format */

import {useCallback, useState} from 'react';

import {styled} from '@mui/material/styles';

import {Box, Grid, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {HelpOutline} from '@mui/icons-material';
import {Trans, useTranslation} from 'react-i18next';
import {clsx} from 'clsx';
import {format} from 'date-fns';
// prettier-ignore
import {ru} from 'date-fns/locale';

import PlanFactPie from 'components/graphs/PlanFactPie';
import {
  GOOD_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/mediaplanBreakpoints';
import numberHelpers from 'helpers/numberHelpers';

const PREFIX = 'Spent';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  hint: `${PREFIX}-hint`,
  value: `${PREFIX}-value`,
  overspend: `${PREFIX}-overspend`,
  main: `${PREFIX}-main`,
  plannedValue: `${PREFIX}-plannedValue`,
  remainder: `${PREFIX}-remainder`,
  help: `${PREFIX}-help`,
  good: `${PREFIX}-good`,
  unoptimal: `${PREFIX}-unoptimal`,
  bad: `${PREFIX}-bad`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    marginTop: 0,
    marginBottom: 0,
  },

  [`& .${classes.title}`]: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.hint}`]: {
    color: '#9F9F9F',
    fontSize: 13,
    textTransform: 'capitalize',
    fontWeight: 600,
  },

  [`& .${classes.value}`]: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
  },

  [`& .${classes.overspend}`]: {
    color: '#CE5959',
    fontSize: 13,
    lineHeight: '16px',
    borderTop: '1px dashed #4B4B4B',
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },

  [`& .${classes.main}`]: {
    borderRadius: theme.spacing(2),
    backgroundColor: '#2B2B2B',
    padding: theme.spacing(4),
    columnGap: theme.spacing(4),
  },

  [`& .${classes.plannedValue}`]: {
    fontSize: 13,
    lineHeight: 'normal',
    color: '#D5D5D5',
  },

  [`& .${classes.remainder}`]: {
    borderTop: '1px dashed #4B4B4B',
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
    fontSize: 14,
  },

  [`& .${classes.help}`]: {
    width: 12,
    height: 12,
    color: '#777',
    marginLeft: theme.spacing(1.5),
  },

  [`& .${classes.good}`]: {
    color: '#677C41',
  },

  [`& .${classes.unoptimal}`]: {
    color: '#AA7F53',
  },

  [`& .${classes.bad}`]: {
    color: '#CE8080',
  },
}));

const Spent = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const getClassNameByPlan = useCallback(
    (value, plannedValue) => {
      const diff = plannedValue - value;

      const percentage = (diff / plannedValue) * 100;

      if (percentage >= GOOD_BREAKPOINT) {
        return classes.good;
      }

      if (percentage >= UNOPTIMAL_BREAKPOINT) {
        return classes.unoptimal;
      }

      return classes.bad;
    },
    [classes],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {t, i18n} = useTranslation();
  const {currency} = props.platformStat;
  const currencyFormatter = numberHelpers.buildFormatter(
    currency,
    i18n.language,
  );

  const monthFormatted = format(props.date, 'LLLL yyyy', {locale: ru});
  const dayFormatted = format(props.date, 'dd.MM.yyyy');

  const renderCell = (title, statsName, mediaplanName) => {
    const value = props.platformStat[statsName];
    let diff = null;
    let formattedDiff = null;
    let formattedPlannedValue = null;
    let plannedValue = null;

    if (props.mediaplan) {
      plannedValue = props.mediaplan[mediaplanName];
      diff = plannedValue - value;
      formattedDiff = currencyFormatter.format(Math.abs(diff));
      formattedPlannedValue = currencyFormatter.format(plannedValue);
    }
    const formattedValue = currencyFormatter.format(value);

    const dateHint = mediaplanName === 'dayly' ? dayFormatted : monthFormatted;

    const remainderClassName = getClassNameByPlan(value, plannedValue);

    return (
      <Grid item xs={12} sm={6}>
        <Box display='flex' flexDirection='column'>
          <Box
            sx={{
              ml: 4,
              mb: 4,
            }}
          >
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.hint}>{dateHint}</Typography>
          </Box>
          <Box
            className={classes.main}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.mediaplan ? (
              <PlanFactPie
                hasWarning={!!diff && diff < 0}
                plan={props.mediaplan[mediaplanName]}
                fact={value}
              />
            ) : (
              <PlanFactPie />
            )}
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  mt: 2,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography className={classes.value}>
                      {formattedValue}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography className={classes.plannedValue}>
                      <Trans>из</Trans> {formattedPlannedValue || '-'}{' '}
                    </Typography>
                    {mediaplanName === 'dayly' &&
                      plannedValue &&
                      (isMobile ? (
                        <Tooltip
                          key='mobile-tooltip'
                          open={isOpen}
                          onClick={toggleTooltip}
                          title={
                            <Trans>
                              Средне-математическое значение дневного бюджета,
                              рассчитанное из указанного вами месячного
                              медиаплана для проекта.
                            </Trans>
                          }
                          arrow
                        >
                          <HelpOutline className={classes.help} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          key='tooltip'
                          title={
                            <Trans>
                              Средне-математическое значение дневного бюджета,
                              рассчитанное из указанного вами месячного
                              медиаплана для проекта.
                            </Trans>
                          }
                          arrow
                        >
                          <HelpOutline className={classes.help} />
                        </Tooltip>
                      ))}
                  </Box>
                </Box>
              </Box>
              {diff && diff > 0 && (
                <Typography
                  className={clsx(classes.remainder, remainderClassName)}
                >
                  <Trans>остаток</Trans>: {formattedDiff}
                </Typography>
              )}
              {((diff && diff < 0) || !plannedValue) && (
                <Typography className={classes.overspend}>
                  {plannedValue ? (
                    <Trans>перерасход {{formattedDiff}}</Trans>
                  ) : (
                    <Trans>Укажите медиаплан</Trans>
                  )}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <StyledBox
      sx={{
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <Grid
        container
        spacing={4}
        className={classes.container}
        sx={{
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {renderCell(
          t('Расход за месяц', 'Расход за месяц'),
          'monthSpent',
          'monthly',
        )}
        {renderCell(
          t('Расход за прошлые сутки', 'Расход за прошлые сутки'),
          'spent',
          'dayly',
        )}
      </Grid>
    </StyledBox>
  );
};

export default Spent;
