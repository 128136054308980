/** @format */

import * as R from 'ramda';
import {createMachine, assign} from 'xstate';

const MAX_CLOSE_COUNT = 4;

const step = (onboardingId, nextStep) => ({
  on: {
    NEXT: {
      target: nextStep,
      actions: 'incrementCurrentStep',
    },
    CLOSE: [
      {
        target: 'complete',
        guard: 'maxCloseCountReached',
      },
      {
        target: `#${onboardingId}.hidden`,
        actions: 'incrementCloseCount',
      },
    ],
  },
});

const create = (onboardingId, totalSteps) => {
  const notLastSteps = R.pipe(
    R.times(i => [`step${i + 1}`, step(onboardingId, `step${i + 2}`)]),
    Object.fromEntries,
  )(totalSteps - 1);

  return createMachine(
    {
      id: onboardingId,
      context: {
        currentStep: 0,
        totalSteps,
        closeCount: 0,
        maxCloseCount: MAX_CLOSE_COUNT,
      },
      initial: 'active',
      states: {
        hidden: {
          on: {
            SHOW: 'active.last',
          },
        },
        active: {
          initial: 'complete',
          states: {
            pending: {
              on: {
                START: {
                  target: 'step1',
                  actions: 'incrementCurrentStep',
                },
              },
            },
            ...notLastSteps,
            [`step${totalSteps}`]: {
              on: {
                NEXT: 'complete',
                CLOSE: 'complete',
              },
            },
            complete: {
              type: 'final',
            },
            last: {
              type: 'history',
              target: 'step1',
            },
          },
        },
      },
    },
    {
      actions: {
        incrementCurrentStep: assign({
          currentStep: context => context.currentStep + 1,
        }),
        incrementCloseCount: assign({
          closeCount: context => context.closeCount + 1,
        }),
      },
      guards: {
        maxCloseCountReached: context =>
          context.closeCount === context.maxCloseCount - 1,
      },
    },
  );
};

export default {create};
