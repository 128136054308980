/** @format */
import {ChangeEvent} from 'react';

import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import {Platform} from 'types';

import NewStyledTab from './NewStyledTab';
import StyledTabsNew from './StyledTabsNew';

interface Props {
  onChange: (value: Platform) => void;
  activeTabName: string;
  tabs: {
    name: string;
    text: string;
    mobileText?: string;
    hasWarning?: boolean;
  }[];
  variant: string;
}

const NewNativeTabs = (props: Props) => {
  const handleChange = (_event: ChangeEvent<unknown>, value: string) => {
    props.onChange(value as Platform);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledTabsNew value={props.activeTabName} onChange={handleChange}>
      {props.tabs.map((v, i) => {
        const label = isMobile ? v.mobileText : v.text;

        return (
          <NewStyledTab
            key={i}
            label={label}
            value={v.name}
            variant={props.variant}
          />
        );
      })}
    </StyledTabsNew>
  );
};

export default NewNativeTabs;
