/** @format */

import {Trans, useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ModalPage from 'components/ModalPage';

const useStyles = makeStyles(_theme => ({
  body: {
    color: '#454641',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '20px',
    whiteSpace: 'pre-line',
  },
}));

const ScoreDescription = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <ModalPage
      header={t('Качество настройки рекламы', 'Качество настройки рекламы')}
    >
      <Typography className={classes.body}>
        <Trans>
          Качество настройки рекламы — это показатель того, насколько
          эффективно работают ваши рекламные кампании. Он зависит от того, по
          каким параметрам и в каком объёме были сформированы рекомендации
          по улучшению. Если параметров для улучшения не найдено, то индекс
          будет равен 5.
          <br />
          <br />
          Критичные параметры отнимают от индекса больше, рекомендации
          по возможныму улучшению — меньше.
          <br />
          <br />
          Если есть хотя бы одна критичная рекомендация, то максимальный индекс
          будет равен 3.9.
        </Trans>
      </Typography>
    </ModalPage>
  );
};

export default ScoreDescription;
