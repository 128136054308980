/** @format */

import {clsx} from 'clsx';
import {Box, Grid, Link, Typography, useMediaQuery} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans, useTranslation} from 'react-i18next';
import {isToday, parseISO} from 'date-fns';
import {gql, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import BoxPlate from 'components/plates/BoxPlate';
import BudgetScoreStatus from 'components/BudgetScoreStatus';
import Onboarding1 from 'onboarding/Onboarding1';
import Onboarding2 from 'onboarding/Onboarding2';
import StepBackdrop from 'onboarding/StepBackdrop';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import checkHelpers from 'helpers/checkHelpers';
import miscHelpers from 'helpers/miscHelpers';
import type {Check} from 'types';
import {ONBOARDING_1_ID} from 'machines/onboarding/onboarding1Machine';
import {ONBOARDING_2_ID} from 'machines/onboarding/onboarding2Machine';
import {useOnboardingContext} from 'contexts/OnboardingContext';
import {
  GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

const LAUNCH = gql`
  mutation Launch {
    launch
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
    boxPlateContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    headerTitleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    headerTitle: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    headerSubtitleContainer: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    updatedText: {
      color: '#9F9F9F',
      fontSize: 12,
      fontWeight: 400,
    },
    headerSubtitle: {
      color: '#fff',
      fontSize: 14,
      fontWeight: 400,
    },
    link: {
      textDecoration: 'underline',
    },
    buttonContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    statusContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: theme.spacing(6.5),
        '& > div': {
          width: '100%',
        },
      },
    },
    statusTitle: {
      color: '#fff',
      fontSize: 20,
      fontWeight: 700,
      marginBottom: theme.spacing(2.5),
    },
    statusDescription: {
      color: '#D5D5D5',
      fontSize: 13,
      fontWeight: 400,
    },
    statusItem: {
      width: 90,
      height: 60,
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    dynamicImage: {
      marginRight: theme.spacing(1.5),
    },
  }),
);

type Props = {
  budgetScore: number;
  isLaunchEnabled: boolean;
  isLaunched: boolean;
  lastCheckCreateDate: string;
  lastChecks: Check[];
  onLaunch: () => void;
};

const Performance = (props: Props) => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const onboardingContext = useOnboardingContext();

  const boxPlateRef = useOnboardingButtonPlateRef();
  const classes = useStyles();

  const [launch, {loading}] = useMutation(LAUNCH);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const date = parseISO(props.lastCheckCreateDate);

  const showO1S1 = onboardingContext.showStep(ONBOARDING_1_ID, 'active.step1');
  const showO2S1 = onboardingContext.showStep(ONBOARDING_2_ID, 'active.step1');

  const renderHeader = () => {
    const lastCheckText = () => {
      if (isToday(date)) {
        return t('Сегодня в {{formattedTime, datetime}}', {
          formatParams: {
            formattedTime: {hour: 'numeric', minute: 'numeric'},
          },
        });
      }
      return t('{{formattedDate, datetime}} в {{formattedTime, datetime}}', {
        formattedDate: date,
        formattedTime: date,
        formatParams: {
          formattedDate: {day: 'numeric', month: 'long'},
          formattedTime: {hour: 'numeric', minute: 'numeric'},
        },
      });
    };

    const renderLaunchText = () => {
      const handleLaunch = async () => {
        await launch();
        props.onLaunch();
      };

      return (
        <Box alignItems='center' display='flex' justifyContent='flex-end'>
          <img
            alt='Recheck'
            height='14'
            src={new URL('./images/recheck.png', import.meta.url).href}
            width='13'
          />
          <Box ml={2}>
            {loading ? (
              <Typography className={classes.headerSubtitle}>
                <Trans>Повторить проверку</Trans>
              </Typography>
            ) : (
              <Link component='button' onClick={handleLaunch}>
                <Typography
                  className={clsx(classes.headerSubtitle, classes.link)}
                >
                  <Trans>Повторить проверку</Trans>
                </Typography>
              </Link>
            )}
          </Box>
        </Box>
      );
    };

    return (
      <Box display='flex'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} className={classes.headerTitleContainer}>
            <Typography className={classes.headerTitle}>
              <Trans>Оценка эффективности</Trans>
            </Typography>
          </Grid>

          <Grid container item spacing={1} xs={12} sm={4}>
            <Grid
              item
              xs={5}
              sm={12}
              className={classes.headerSubtitleContainer}
              style={{alignItems: isMobile ? 'flex-start' : 'flex-end'}}
            >
              <Typography className={classes.updatedText}>Обновлено</Typography>
              <Typography className={classes.headerSubtitle}>
                {lastCheckText()}
              </Typography>
            </Grid>
            {props.isLaunchEnabled && (
              <Grid item xs={7} sm={12}>
                {props.isLaunched ? (
                  <Typography
                    className={classes.headerSubtitle}
                    style={{textAlign: 'right'}}
                  >
                    <Trans>Проводится проверка...</Trans>
                  </Typography>
                ) : (
                  renderLaunchText()
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderBudgetScore = () => {
    const statusTitle = () => {
      if (props.isLaunched) {
        return <Trans>Ваши кампании еще не прошли проверку</Trans>;
      }

      if (props.budgetScore === GOOD_BREAKPOINT) {
        return <Trans>РК работают без замечаний</Trans>;
      }

      if (props.budgetScore >= NORMAL_BREAKPOINT) {
        return <Trans>РК работают почти без замечаний</Trans>;
      }

      if (props.budgetScore >= UNOPTIMAL_BREAKPOINT) {
        return <Trans>РК работают не оптимально</Trans>;
      }

      return <Trans>РК работают не эффективно</Trans>;
    };

    const statusDescription = () => {
      if (props.isLaunched) {
        return <Trans>Дождитесь формирования первого отчета</Trans>;
      }

      if (props.budgetScore === GOOD_BREAKPOINT) {
        return (
          <Trans>
            Но не время расслабляться, посмотрим, что мы сможем найти завтра ;)
          </Trans>
        );
      }

      if (props.budgetScore >= NORMAL_BREAKPOINT) {
        return (
          <Trans>
            Но вам есть что улучшить, просмотрите и примените свежие
            рекомендации
          </Trans>
        );
      }

      if (props.budgetScore >= UNOPTIMAL_BREAKPOINT) {
        return (
          <Trans>
            Мы нашли новые рекомендации и замечания, займитесь ими в ближайшее
            время
          </Trans>
        );
      }

      return (
        <Trans>
          Требуется немедленная доработка, обратите внимание на рекомендации
        </Trans>
      );
    };

    const handleClick = () => {
      navigate('/home/main/graphs');
    };

    return (
      <BoxPlate ref={boxPlateRef}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8}>
            <Box
              alignItems='center'
              display='flex'
              flexGrow={1}
              justifyContent='flex-start'
              className={classes.statusContainer}
            >
              <BudgetScoreStatus
                statusClassName={classes.statusItem}
                isLaunched={props.isLaunched}
                budgetScore={props.budgetScore}
              />

              <Box ml={3}>
                <Typography className={classes.statusTitle} variant='body2'>
                  {statusTitle()}
                </Typography>
                <Typography
                  className={classes.statusDescription}
                  variant='body2'
                >
                  {statusDescription()}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item className={classes.buttonContainer} xs={12} sm={12} md={4}>
            <TransparentSmallButton
              className={classes.button}
              onClick={handleClick}
            >
              <img
                className={classes.dynamicImage}
                src={new URL('./images/dynamic.svg', import.meta.url).href}
              />
              <Trans>Динамика показателей</Trans>
            </TransparentSmallButton>
          </Grid>
        </Grid>
      </BoxPlate>
    );
  };

  return (
    <Box className={classes.container}>
      <Onboarding lastChecks={props.lastChecks} />

      <StepBackdrop
        enabled={showO1S1 || showO2S1}
        highlight={showO1S1 || showO2S1}
      >
        <Box className={classes.boxPlateContainer}>
          {renderHeader()}
          <Box mt={5}>{renderBudgetScore()}</Box>
        </Box>
      </StepBackdrop>
    </Box>
  );
};

const useOnboardingButtonPlateRef = () => {
  const onboardingContext = useOnboardingContext();

  const refO1S1 = onboardingContext.anchorRefs[ONBOARDING_1_ID]['active.step1'];
  const refO2S1 = onboardingContext.anchorRefs[ONBOARDING_2_ID]['active.step1'];

  const boxPlateRef = miscHelpers.mergeRefs(refO1S1, refO2S1);
  return boxPlateRef;
};

type OnboardingProps = {
  lastChecks: Check[];
};

const Onboarding = (props: OnboardingProps) => {
  const hasErrors = checkHelpers.errorsCount(props.lastChecks) > 0;
  const hasWarnings = checkHelpers.warningsCount(props.lastChecks) > 0;

  return (
    <>
      <Onboarding1.Step1 showOnboarding={!hasErrors && !hasWarnings} />
      <Onboarding2.Step1 showOnboarding={hasErrors || hasWarnings} />
    </>
  );
};

export default Performance;
