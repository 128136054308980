/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import Autocomplete from '@mui/material/Autocomplete';
import {Box, Typography, Paper} from '@mui/material';
import {useTranslation} from 'react-i18next';

import MediumTextField from 'components/inputs/MediumTextField';

const PREFIX = 'AutocompletePicker';

const classes = {
  optionLabel: `${PREFIX}-optionLabel`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  backgroundColor: 'white',
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: '#F0F0F0',
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
    {
      backgroundColor: '#F0F0F0',
    },
}));

const StyledOption = styled(Box)(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  [`& .${classes.optionLabel}`]: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '33px',
  },
}));

const AutocompletePicker = props => {
  const [pendingValue, setPendingValue] = useState(props.value);

  const {t} = useTranslation();

  useEffect(() => {
    setPendingValue(props.value);
  }, [props.value]);

  const handleChange = (_, value) => {
    setPendingValue(value);
  };

  const handleClose = () => {
    props.onClose(pendingValue);
  };

  return (
    <Autocomplete
      disableClearable
      disableCloseOnSelect
      forcePopupIcon={false}
      getOptionLabel={option => option[props.labelField]}
      isOptionEqualToValue={(option, value) =>
        option[props.idField] === value[props.idField]
      }
      multiple
      noOptionsText={t('Ничего не найдено', 'Ничего не найдено')}
      options={props.options}
      renderTags={() => null}
      value={pendingValue}
      renderInput={params => (
        <MediumTextField
          {...params}
          myProps={{placeholderFocused: props.placeholderFocused}}
          placeholder={props.placeholder}
        />
      )}
      PaperComponent={props => <StyledPaper {...props} />}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <StyledOption>
            <Typography className={classes.optionLabel}>
              {option.name}
            </Typography>
            {selected && (
              <img
                alt='Check'
                height='14'
                src={new URL('./images/check.png', import.meta.url)}
                width='14'
              />
            )}
          </StyledOption>
        </li>
      )}
      onChange={handleChange}
      onClose={handleClose}
    />
  );
};

export default AutocompletePicker;
