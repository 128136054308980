/** @format */

import BaseStep from 'onboarding/BaseStep';
import {ONBOARDING_2_ID} from 'machines/onboarding/onboarding2Machine';

const STEP_ID = 'active.step1';

const Step1 = props => {
  const title =
    'Смотри на этот блок, чтобы понять, как у тебя в целом ' +
    'дела с настройкой рекламы';

  const body = 'Кликни на него и узнаешь, как считается показатель качества.';

  return (
    <BaseStep
      body={body}
      onboardingId={ONBOARDING_2_ID}
      showOnboarding={props.showOnboarding}
      stepId={STEP_ID}
      title={title}
    />
  );
};

export default Step1;
