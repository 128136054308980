/** @format */
import React from 'react';

import {AsYouType} from 'libphonenumber-js';
import {useTranslation} from 'react-i18next';

import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';

interface Props {
  value?: string;
  onChange: (value: string) => void;
}

const PhoneNumberField = (props: Props) => {
  const {t} = useTranslation();
  const {value} = props;
  const formattedValue = new AsYouType().input(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const formattedValue = new AsYouType().input(value);
    props.onChange(formattedValue);
  };

  return (
    <LegacyTextFieldFilled
      {...props}
      value={formattedValue}
      placeholder={t('Номер телефона', 'Номер телефона')}
      type='tel'
      myProps={{
        placeholderFocused: t(
          'Например, +7 995 880 40 320',
          'Например, +7 995 880 40 320',
        ),
      }}
      fullWidth
      onChange={handleChange}
    />
  );
};

export default PhoneNumberField;
