/** @format */

import {Box} from '@mui/material';

import imageHelpers from 'helpers/imageHelpers';
import type {Platform} from 'types';

type Props = {
  bw?: boolean;
  platforms: Platform[];
  size?: number;
};

const Platforms = (props: Props) => {
  const {bw = false, size = 40} = props;

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {props.platforms.map((v, i) => (
        <Box
          key={i}
          sx={{
            alignItems: 'center',
            display: 'flex',
            mr: 2,
          }}
        >
          <img
            alt='Icon'
            height={size}
            src={imageHelpers.platformIconSrc(v, size, bw)}
            width={size}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Platforms;
