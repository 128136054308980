/** @format */

import {useTranslation} from 'react-i18next';

import sensorsDataRu from './sensors_texts/ru.json';
import sensorsDataEs from './sensors_texts/es.json';
import sensorsDataEn from './sensors_texts/en.json';

export interface sensorText {
  info: string;
  short_info: {
    CONTEXT: string;
    TARGET: string;
    [adType: string]: string;
  }
  growth: string;
  how_it_works: {
    CONTEXT: string;
    TARGET: string;
    [adType: string]: string;
  }
}

interface sensorTexts {
  [name: string]: sensorText;
}

export const useTexts = ():sensorTexts => {
  const {i18n} = useTranslation();

  let sensorsData: sensorTexts = sensorsDataRu;
  if (i18n.language == 'es') {
    sensorsData = sensorsDataEs;
  }
  if (i18n.language == 'en') {
    sensorsData = sensorsDataEn;
  }
  return sensorsData;
};
