/** @format */

import * as R from 'ramda';

import numberHelpers from 'helpers/numberHelpers';

const REGION_SENSORS = ['as-geo'];

const groupedChecks = (allChecks, sensorExclusions, regions) => {
  const compareChecks = (check1, check2) => {
    return check1.sensor.id === check2.sensor.id;
  };

  const excludedChecks = allChecks.filter(check =>
    sensorExclusions.some(v => v.sensorName === check.sensor.name),
  );

  const includedChecks = R.differenceWith(
    compareChecks,
    allChecks,
    excludedChecks,
  );

  const needRegionsChecks =
    regions.length > 0
      ? []
      : includedChecks.filter(v => REGION_SENSORS.includes(v.sensor.name));

  const validChecks = R.differenceWith(
    compareChecks,
    includedChecks,
    needRegionsChecks,
  );

  return {validChecks, excludedChecks, needRegionsChecks};
};

const errorsCount = checks =>
  R.pipe(
    R.filter(v => v.sensor.isError),
    R.map(v => v.found),
    R.sum,
  )(checks);

const warningsCount = checks =>
  R.pipe(
    R.reject(v => v.sensor.isError),
    R.map(v => v.found),
    R.sum,
  )(checks);

const detailsPageLink = (name, humanName) => {
  return `/home/main/details?name=${name}&humanName=${humanName}`;
};

const groupChecksByName = checks => {
  return R.pipe(
    R.groupBy(R.path(['sensor', 'name'])),
    R.values,
    R.map(vs => sumChecks(vs)),
  )(checks);
};

const sumChecks = checks => {
  const totalCheck = checks.reduce(
    (acc, v) => {
      acc.checks = acc.checks + v.checks;
      acc.cost = acc.cost + v.cost;
      acc.found = acc.found + v.found;
      acc.new = acc.new + v.new;

      return acc;
    },
    {checks: 0, cost: 0, found: 0, new: 0},
  );

  totalCheck.cost = numberHelpers.round(totalCheck.cost, 2);

  // Don't set platform - we usually sum checks of different platforms
  totalCheck.sensor = {
    id: checks[0].sensor.id,
    name: checks[0].sensor.name,
    isPaused: checks[0].sensor.isPaused,
    isError: checks[0].sensor.isError,
  };

  return totalCheck;
};

const getChecksNames = checks => checks.map(v => v.sensor.name);

export default {
  groupedChecks,
  errorsCount,
  warningsCount,
  detailsPageLink,
  groupChecksByName,
  sumChecks,
  getChecksNames,
};
