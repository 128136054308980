/** @format */

import {isToday, parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import ClosableBanner from 'components/banners/ClosableBanner';
import {NO_DATA_BANNER, useBannerContext} from 'contexts/BannerContext';

const VALID_PATHS = ['/home/main/status'];

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        lastCheckCollectDate
      }
    }
  }
`;

export const NoDataBannerNew = () => {
  const {pathname} = useLocation();

  const {t} = useTranslation();

  const {isBannerViewed, setBannerViewed} = useBannerContext();

  const skip =
    !VALID_PATHS.includes(pathname) || isBannerViewed(NO_DATA_BANNER);

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {skip});
  if (loading || error) return null;

  if (skip) return null;

  // Say, when user deleted his last company
  if (!data.currentUser.currentCompany) return null;

  const {lastCheckCollectDate} = data.currentUser.currentCompany;
  if (lastCheckCollectDate && isToday(parseISO(lastCheckCollectDate))) {
    return null;
  }

  const handleClose = () => {
    setBannerViewed(NO_DATA_BANNER);
  };

  return (
    <ClosableBanner
      errorType='no_data'
      title={t('Пока еще нет свежих данных.', 'Пока еще нет свежих данных.')}
      text={t(
        'Кабинеты проверяются автоматически, каждый день в 10:00.',
        'Кабинеты проверяются автоматически, каждый день в 10:00.',
      )}
      onClose={handleClose}
    />
  );
};

export default NoDataBannerNew;
