/** @format */

import {styled} from '@mui/material/styles';

import BaseTextField from './BaseTextField';

const PREFIX = 'LegacyTextField';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBaseTextField = styled(BaseTextField)(({theme}) => ({
  width: '100%',
  '&:hover fieldset': {
    borderColor: theme.palette.action.hover,
  },
  '& fieldset': {
    borderColor: theme.palette.action.active,
    borderRadius: '16px',
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary,
  },
  '& .MuiInputBase-root': {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '16px',
  },
  '&.Mui-focused fieldset': {
    borderColor: theme.palette.action.selected,
  },
}));

const LegacyTextField = props => {
  return (
    <StyledBaseTextField
      className={classes.root}
      {...props}
      variant='outlined'
    />
  );
};

export default LegacyTextField;
