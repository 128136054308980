/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import BoxPlate from 'components/plates/BoxPlate';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {add, format, isYesterday} from 'date-fns';
import {gql, useQuery} from '@apollo/client';

import numberHelpers from 'helpers/numberHelpers';
import statsHelpers from 'helpers/statsHelpers';
import type {ExtendedPlatform, MetricName, Stat} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';

const PREFIX = 'PeriodStats';

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
  periodTitle: `${PREFIX}-periodTitle`,
  periodValue: `${PREFIX}-periodValue`,
  metricTitle: `${PREFIX}-metricTitle`,
  metricValue: `${PREFIX}-metricValue`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.loadingContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    height: 247,
    justifyContent: 'center',
    width: '100%',
  },

  [`& .${classes.periodTitle}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },

  [`& .${classes.periodValue}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },

  [`& .${classes.metricTitle}`]: {
    color: '#8F8F8F',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },

  [`& .${classes.metricValue}`]: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser($date: Date!) {
    currentUser {
      currentCompany {
        stats(date: $date) {
          currency
          platform
          extendedPlatform
          state
          monthSpent
          spent
          impressions
          clicks
          cpc
          ctr
        }
      }
    }
  }
`;

type Props = {
  extendedPlatform: ExtendedPlatform;
};

const PeriodStats = (props: Props) => {
  const {currentAdType} = useCompanyContext();

  const {t, i18n} = useTranslation();

  const yesterday = add(new Date(), {days: -1});

  const [date, setDate] = useState(yesterday);
  const [stat, setStat] = useState<Stat | null>(null);

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {date: format(date, 'yyyy-MM-dd')},
  });

  useEffect(() => {
    if (!data) return;

    const allStats = statsHelpers.filterStatsForDashboard(
      data.currentUser.currentCompany.stats,
      currentAdType,
    );

    const platformStat = allStats.find(
      v => v.extendedPlatform === props.extendedPlatform,
    ) as Stat;

    setStat(platformStat);
  }, [data, currentAdType, props.extendedPlatform]);

  // Show loading icon only on first fetch
  if (loading && !stat) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;
  if (!stat) return null;

  const renderHeader = () => {
    const handlePrevious = () => {
      const newDate = add(date, {days: -1});
      setDate(newDate);
    };

    const handleNext = () => {
      const newDate = add(date, {days: 1});
      setDate(newDate);
    };

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
      month: 'long',
      day: 'numeric',
    });

    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography className={classes.periodTitle}>
          <Trans>За день</Trans>
        </Typography>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IconButton onClick={handlePrevious} size='large'>
            <img
              alt='Previous'
              height='11'
              src={new URL('./images/previous.png', import.meta.url).href}
              width='6'
            />
          </IconButton>

          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Typography className={classes.periodValue}>
              {dateFormatter.format(date)}
            </Typography>
          )}

          {isYesterday(date) ? (
            <IconButton disabled size='large'>
              <img
                alt='Next'
                height='11'
                src={
                  new URL('./images/next_disabled.png', import.meta.url).href
                }
                width='6'
              />
            </IconButton>
          ) : (
            <IconButton onClick={handleNext} size='large'>
              <img
                alt='Next'
                height='11'
                src={new URL('./images/next.png', import.meta.url).href}
                width='6'
              />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  const renderRow = (
    title: string,
    name: MetricName | 'spent',
    isCurrency = false,
  ) => {
    const currencyFormatter = numberHelpers.buildFormatter(
      stat.currency,
      i18n.language,
    );

    return (
      <Grid item xs={12}>
        <Box
          sx={{
            alignItems: 'baseline',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography className={classes.metricTitle}>{title}</Typography>
          <Typography className={classes.metricValue}>
            {isCurrency
              ? currencyFormatter.format(stat[name])
              : stat[name].toLocaleString(i18n.language)}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <StyledBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderHeader()}
      <Box
        sx={{
          mt: 3,
        }}
      >
        <BoxPlate>
          <Grid container spacing={3}>
            {renderRow(t('Траты', 'Траты'), 'spent', true)}
            {renderRow(t('Показов', 'Показов'), 'impressions')}
            {renderRow(t('Кликов', 'Кликов'), 'clicks')}
            {renderRow(t('Цена клика', 'Цена клика'), 'cpc', true)}
            {renderRow('CTR, %', 'ctr')}
          </Grid>
        </BoxPlate>
      </Box>
    </StyledBox>
  );
};

export default PeriodStats;
