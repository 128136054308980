/** @format */

import BaseStep from 'onboarding/BaseStep';
import {ONBOARDING_1_ID} from 'machines/onboarding/onboarding1Machine';

const STEP_ID = 'active.step1';

const Step1 = props => {
  const title =
    'Смотри на этот блок, чтобы понять, как у тебя в целом дела ' +
    'с настройкой рекламы';

  const body =
    `Аееее! Вот это результат!${'\n'}Но не забывай, реклама — это процесс. ` +
    'И теперь он у нас под контролем. Как только ошибки появятся, я дам знать.';

  return (
    <BaseStep
      body={body}
      iconSrc={new URL('./images/step1.jpg', import.meta.url)}
      onboardingId={ONBOARDING_1_ID}
      showOnboarding={props.showOnboarding}
      stepId={STEP_ID}
      title={title}
    />
  );
};

export default Step1;
