/** @format */

import Alert from '@mui/material/Alert';
import {styled} from '@mui/material/styles';
import {Box, Grid, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import JuliaBot from 'components/managers/JuliaBot';
import Loading from 'components/loading/Loading';
import ModalPage from 'components/ModalPage';
import PaymentMethod from 'pages/Home/Settings/PaymentMethods/PaymentMethod';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import numberHelpers from 'helpers/numberHelpers';

const PREFIX = 'ConfirmPrepayBudget';

const classes = {
  priceText: `${PREFIX}-priceText`,
  budgetText: `${PREFIX}-budgetText`,
  body: `${PREFIX}-body`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.priceText}`]: {
    color: theme.palette.text.secondary,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '20px',
  },

  [`& .${classes.budgetText}`]: {
    color: '#4A4A4A',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  },

  [`& .${classes.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      budget
      budgetPriceInfo {
        amount
      }
      paymentMethods {
        id
        paymentSystem
        last4
        isExpired
      }
    }
  }
`;

const PREPAY_BUDGET = gql`
  mutation PrepayBudget($paymentMethodId: Int!) {
    prepayBudget(paymentMethodId: $paymentMethodId) {
      confirmationUrl
    }
  }
`;

const ConfirmPrepayBudget = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const {loading, error, data} = useQuery(GET_CURRENT_USER);
  const [prepayBudget, {loading: prepayBudgetLoading}] =
    useMutation(PREPAY_BUDGET);

  if (loading) return <Loading />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {amount} = data.currentUser.budgetPriceInfo;
  const {budget, paymentMethods} = data.currentUser;

  const activePaymentMethod = paymentMethods.find(
    (v: PaymentMethod) => !v.isExpired,
  );

  const formattedAmount = numberHelpers.formatRUB(amount);

  const renderButton = () => {
    const handlePay = async () => {
      const paymentMethodId = activePaymentMethod ? activePaymentMethod.id : -1;

      const {data: prepayBudgetData} = await prepayBudget({
        variables: {paymentMethodId},
      });

      const {confirmationUrl} = prepayBudgetData.prepayBudget;

      if (confirmationUrl) {
        window.location.href = confirmationUrl;
      } else {
        navigate('/home/main', {replace: true});
        navigate(0);
      }
    };

    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <PrimaryMediumButton
            disabled={prepayBudgetLoading}
            fullWidth
            onClick={handlePay}
          >
            <Trans>Оплатить {{formattedAmount}}</Trans>
          </PrimaryMediumButton>
        </Grid>
      </Grid>
    );
  };

  const formattedBudget = numberHelpers.formatRUB(budget);
  return (
    <Root>
      <ModalPage header={t('Оплата подписки', 'Оплата подписки')}>
        <Box
          sx={{
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography className={classes.priceText}>
              <Trans>Стоимость</Trans>
            </Typography>
            <Typography className={classes.priceText}>
              {formattedAmount}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Typography className={classes.budgetText}>
            <Trans>Ваш бюджет {{formattedBudget}}</Trans>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 10,
          }}
        >
          <Typography className={classes.body}>
            <Trans>
              Если возникли трудности с оплатой, свяжитесь с менеджером AdSensor
            </Trans>
          </Typography>
          <Box
            sx={{
              mt: 3,
            }}
          >
            <JuliaBot buttonVariant='SECONDARY' variant='LIGHT' />
          </Box>
        </Box>
        <Box
          sx={{
            mt: 10,
          }}
        >
          {activePaymentMethod && (
            <Box
              sx={{
                mb: 3,
              }}
            >
              <PaymentMethod
                paymentMethod={activePaymentMethod}
                variant='LIGHT'
              />
            </Box>
          )}

          {renderButton()}
        </Box>
      </ModalPage>
    </Root>
  );
};

export default ConfirmPrepayBudget;
