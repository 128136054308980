/** @format */

import {Backdrop, Box, IconButton, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'Modal';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  backdropNew: `${PREFIX}-backdropNew`,
  outerContainer: `${PREFIX}-outerContainer`,
  outerOuterContainer: `${PREFIX}-outerOuterContainer`,
  outerOuterContainerNew: `${PREFIX}-outerOuterContainerNew`,
  innerContainer: `${PREFIX}-innerContainer`,
  innerContainerNew: `${PREFIX}-innerContainerNew`,
  closeIcon: `${PREFIX}-closeIcon`,
  closeIconNew: `${PREFIX}-closeIconNew`,
  header: `${PREFIX}-header`,
  headerNew: `${PREFIX}-headerNew`,
  headerText: `${PREFIX}-headerText`,
  headerTextNew: `${PREFIX}-headerTextNew`,
  content: `${PREFIX}-content`,
  contentNew: `${PREFIX}-contentNew`,
};

const StyledBackdrop = styled(Backdrop)<{variant: ModalVariant}>(
  ({theme, variant}) => {
    const backgroundColor = variant === 'dark' ? '#202020' : '#FFFFFF';
    const textColor = variant === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary;

    return {
    [`&.${classes.backdrop}`]: {
      backgroundColor: 'rgba(20, 20, 20, 0.8)',
      zIndex: theme.zIndex.drawer + 1,
    },

    [`&.${classes.backdropNew}`]: {
      backgroundColor: 'rgba(17, 17, 17, 0.9)',
      backdropFilter: 'blur(2px)',
      zIndex: theme.zIndex.drawer + 1,
    },

    // https://front-back.com/how-to-make-absolute-positioned-elements-overlap-their-overflow-hidden-parent/
    //
    // Outer container is used as a relative parent for close icon.
    // Inner container is used to crop content (with border radius).
    //
    // Inner container can't be used as relative parent for close icon since it
    // would hide the icon because of `overflow: hidden` property.
    [`& .${classes.outerContainer}`]: {
      minWidth: 350,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        flexGrow: 1,
      },
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },

    [`& .${classes.outerOuterContainer}`]: {
      // Set max width for `overflow-x: scroll` to work
      maxWidth: '100vw',
      minWidth: 350,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: backgroundColor,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        flexGrow: 1,
        height: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
        borderRadius: 24,
      },
    },

    [`& .${classes.outerOuterContainerNew}`]: {
      // Set max width for `overflow-x: scroll` to work
      maxWidth: '100vw',
      minWidth: 350,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '16px',
      backgroundColor: backgroundColor,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        flexGrow: 1,
        height: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },

    [`& .${classes.innerContainer}`]: {
      backgroundColor: backgroundColor,
      overflow: 'auto',
      // Fix for Firefox on Windows (Chrome doesn't know this property at all)
      scrollbarWidth: 'none',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: 584,
        maxWidth: 650,
      },
    },

    [`& .${classes.innerContainerNew}`]: {
      backgroundColor: backgroundColor,
      overflow: 'auto',
      // Fix for Firefox on Windows (Chrome doesn't know this property at all)
      scrollbarWidth: 'none',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: 584,
        maxWidth: 650,
      },
    },

    [`& .${classes.closeIcon}`]: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },

    [`& .${classes.closeIconNew}`]: {
      color: textColor,
      position: 'absolute',
      right: theme.spacing(5),
      top: theme.spacing(5),
    },

    [`& .${classes.header}`]: {
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(7),
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(12),
        paddingTop: theme.spacing(5),
        maxWidth: 650,
      },
    },

    [`& .${classes.headerNew}`]: {
      padding: theme.spacing(8),
      paddingRight: theme.spacing(14),
    },

    [`& .${classes.headerText}`]: {
      color: theme.palette.text.secondary,
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '23px',
    },

    [`& .${classes.headerTextNew}`]: {
      color: textColor,
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 'normal',
    },

    [`& .${classes.content}`]: {
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        paddingTop: 0,
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(6),
        paddingBottom: theme.spacing(0),
        paddingTop: 0,
      },
    },

    [`& .${classes.contentNew}`]: {
      color: textColor,
      padding: theme.spacing(8),
      paddingTop: 0,
      paddingBottom: 0,
    },
  };
  },
);

export type ModalVariant = 'light' | 'dark';

interface Props {
  header: string | React.ReactNode;
  footer?: string | React.ReactNode;
  children: React.ReactNode;
  variant?: ModalVariant;
  onClose?: () => void;
  showCloseIcon?: boolean;
}

// https://reactrouter.com/web/example/modal-gallery
const Modal = (props: Props) => {
  const {isNewVersionEnabled} = useFlags();

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    if (props.onClose) {
      props.onClose();
    }
  };

  return isNewVersionEnabled ? (
    <StyledBackdrop
      className={classes.backdropNew}
      open
      transitionDuration={50}
      variant={props.variant || 'light'}
    >
      <Box className={classes.outerContainer}>
        <Box className={classes.outerOuterContainerNew}>
          <Box className={classes.headerNew}>
            <Typography className={classes.headerTextNew}>
              {props.header}
            </Typography>
          </Box>
          <Box className={classes.innerContainerNew}>
            {props.showCloseIcon && (
              <IconButton
                className={classes.closeIconNew}
                onClick={handleClose}
                size='large'
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            )}

            <Box className={classes.contentNew}>{props.children}</Box>
          </Box>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.footer}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledBackdrop>
  ) : (
    <StyledBackdrop
      className={classes.backdrop}
      open
      transitionDuration={50}
      variant={props.variant || 'light'}
    >
      <Box className={classes.outerContainer}>
        <Box className={classes.outerOuterContainer}>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.header}
            </Typography>
          </Box>
          <Box className={classes.innerContainer}>
            {props.showCloseIcon && (
              <IconButton
                className={classes.closeIcon}
                onClick={handleClose}
                size='large'
              >
                <img
                  alt='Close'
                  src={new URL(
                    './images/close.svg',
                    import.meta.url,
                  ).toString()}
                />
              </IconButton>
            )}

            <Box className={classes.content}>{props.children}</Box>
          </Box>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.footer}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledBackdrop>
  );
};

Modal.defaultProps = {
  variant: 'light',
  onClose: null,
  showCloseIcon: true,
};

export default Modal;
