/** @format */

import {useState} from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {IconButton, InputAdornment} from '@mui/material';
import {useTranslation} from 'react-i18next';

import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';

// https://material-ui.com/components/text-fields/#input-adornments
const CreatePasswordFieldFilled = props => {
  const [showPassword, setShowPassword] = useState(false);

  const {t} = useTranslation();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const placeholder = () => {
    if (props.placeholder == null) {
      return t('Пароль', 'Пароль');
    }

    return props.placeholder;
  };

  return (
    <LegacyTextFieldFilled
      myProps={{
        placeholderFocused: t('Не менее 3 знаков', 'Не менее 3 знаков'),
        ...props.myProps,
      }}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        // https://github.com/mui-org/material-ui/issues/18556
        autoComplete: 'new-password',
        endAdornment: props.value ? (
          <InputAdornment position='end'>
            <IconButton edge='end' onClick={handleShowPassword} size='large'>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...props}
      placeholder={placeholder()}
      fullWidth
    />
  );
};

export default CreatePasswordFieldFilled;
