/** @format */

import {
  Box,
  ButtonBase,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';

import exclamationImgUrl from 'images/exclamation.png';
import {sensorName} from 'helpers/sensor_names';

const PREFIX = 'NeedRegionsRow';

const classes = {
  row: `${PREFIX}-row`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  textWrapper: `${PREFIX}-textWrapper`,
  text: `${PREFIX}-text`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.row}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#202020',
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    position: 'relative',
    paddingRight: theme.spacing(4),
    borderRight: '2px solid #8B8B8B',
    borderLeft: '2px solid #8B8B8B',
  },

  [`& .${classes.icon}`]: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
    marginRight: theme.spacing(4),
  },

  [`& .${classes.title}`]: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.169px',
  },

  [`& .${classes.textWrapper}`]: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  [`& .${classes.text}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '17px',
  },
}));

const NeedRegionsRow = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const handleClickRegions = event => {
    event.stopPropagation();

    navigate('/home/main/regions', {
      state: {background: location},
    });
  };

  return (
    <StyledBox position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <ListItemText
          disableTypography
          primary={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                className={classes.icon}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmojiObjectsOutlinedIcon
                  fontSize='small'
                  htmlColor='#8B8B8B'
                />
              </Box>
              <Typography className={classes.title}>
                {t(sensorName(props.check.sensor.name))}
              </Typography>
            </Box>
          }
        />

        <ListItemText
          disableTypography
          primary={
            <Box
              className={classes.textWrapper}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonBase onClick={handleClickRegions}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography className={classes.text}>
                    <Trans>Дайте нам больше данных</Trans>
                  </Typography>

                  <Box
                    sx={{
                      ml: 3,
                    }}
                  >
                    <img
                      alt='Regions'
                      height='19'
                      src={exclamationImgUrl}
                      width='19'
                    />
                  </Box>
                </Box>
              </ButtonBase>
            </Box>
          }
        />
      </ListItem>
    </StyledBox>
  );
};

export default NeedRegionsRow;
