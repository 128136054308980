/** @format */

import {styled} from '@mui/material/styles';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const StyledPrimaryMediumButton = styled(PrimaryMediumButton)(({theme}) => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const TransparentMediumButton = props => (
  <StyledPrimaryMediumButton {...props} />
);

export default TransparentMediumButton;
