/** @format */

import {Avatar} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'ConnectedIcon';

const classes = {
  avatar: `${PREFIX}-avatar`,
};

const StyledAvatar = styled(Avatar)(() => ({
  [`&.${classes.avatar}`]: {
    backgroundColor: '#1E3513',
    height: 44,
    width: 44,
  },
}));

const ConnectedIcon = () => {
  return (
    <StyledAvatar className={classes.avatar}>
      <img
        alt='Connected'
        height='11'
        src={new URL('./images/checkmark.png', import.meta.url).href}
        width='14'
      />
    </StyledAvatar>
  );
};

export default ConnectedIcon;
