/** @format */

import {Tabs as MaterialTabs, TabsTypeMap} from '@mui/material';
import {styled} from '@mui/material/styles';
import {OverridableComponent} from '@mui/material/OverridableComponent';
const PREFIX = 'StyledTabs';

const classes = {
  root: `${PREFIX}-root`,
  flexContainer: `${PREFIX}-flexContainer`,
  indicator: `${PREFIX}-indicator`,
};

const Tabs = styled(MaterialTabs)(({theme}) => ({
  backgroundColor: '#2B2B2B',
  borderRadius: '6px 6px 0 0',
  height: 45,
  minHeight: 45,

  [`& .${classes.flexContainer}`]: {
    borderBottom: '1px solid #4D4D4D',
  },

  [`& .MuiTabs-indicator`]: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 1,
    '& > span': {
      backgroundColor: theme.palette.success.main,
      width: '100%',
    },
  },
}));

const StyledTabs = ((props: any) => (
  <Tabs TabIndicatorProps={{children: <span />}} {...props} />
)) as OverridableComponent<TabsTypeMap>;

export default StyledTabs;
