/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

import imageHelpers from 'helpers/imageHelpers';

const PREFIX = 'ConnectedProvidersNew';

const classes = {
  icon: `${PREFIX}-icon`,
  iconsContainer: `${PREFIX}-iconsContainer`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.icon}`]: {
    marginLeft: theme.spacing(4),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  [`&.${classes.iconsContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#2B2B2B',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

interface Props {
  adTypes: string[];
  tokens: {
    provider: string;
  }[];
}

const ConnectedProvidersNew = (props: Props) => {
  return (
    <StyledBox className={classes.iconsContainer}>
      {props.adTypes.includes('CONTEXT') && (
        <>
          {props.tokens.find(v => v.provider === 'YANDEX') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('YANDEX', 20)}
            />
          )}
          {props.tokens.find(v => v.provider === 'GOOGLE') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('GOOGLE', 20)}
            />
          )}
        </>
      )}
      {props.adTypes.includes('TARGET') && (
        <>
          {props.tokens.find(v => v.provider === 'VKONTAKTE') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('VKONTAKTE', 20)}
            />
          )}
          {props.tokens.find(v => v.provider === 'VKONTAKTE_ADS') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('VKONTAKTE_ADS', 20)}
            />
          )}
          {props.tokens.find(v => v.provider === 'FACEBOOK') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('FACEBOOK', 20)}
            />
          )}
        </>
      )}
      {props.adTypes.includes('STATS') && (
        <>
          {props.tokens.find(v => v.provider === 'METRIKA') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('METRIKA', 20)}
            />
          )}
          {props.tokens.find(v => v.provider === 'WEBDAV') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('WEBDAV', 20)}
            />
          )}
        </>
      )}
    </StyledBox>
  );
};

export default ConnectedProvidersNew;
