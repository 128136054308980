/** @format */

import {CSSProperties, forwardRef} from 'react';

import {styled} from '@mui/material/styles';

import clsx from 'clsx';
import {Box, ButtonBase} from '@mui/material';

const PREFIX = 'ButtonPlate';

const classes = {
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    display: 'flex',
    padding: theme.spacing(4),
  },
}));

type Props = {
  containerStyle?: CSSProperties;
  containerClassName?: string;
  onClick: () => void;
  children: React.ReactNode;
};

// https://github.com/facebook/react/issues/16653#issuecomment-564423981
const ButtonPlate = forwardRef<HTMLElement, Props>((props, ref) => {
  const {onClick = null} = props;

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <StyledBox {...{ref}}>
      <ButtonBase
        className={clsx(classes.container, props.containerClassName)}
        component='a'
        style={props.containerStyle}
        onClick={handleClick}
      >
        {props.children}
      </ButtonBase>
    </StyledBox>
  );
});

ButtonPlate.displayName = 'ButtonPlate';

export default ButtonPlate;
