/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';

import ButtonPlate from 'components/plates/ButtonPlate';
import GreenRadio from 'components/radios/GreenRadio';

const PREFIX = 'Row';

const classes = {
  text: `${PREFIX}-text`,
  accountIdText: `${PREFIX}-accountIdText`,
  disabled: `${PREFIX}-disabled`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.text}`]: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
  },

  [`& .${classes.accountIdText}`]: {
    color: '#A6A6A6',
  },

  [`&.${classes.disabled}`]: {
    opacity: 0.3,
  },
}));

type Props = {
  title: string;
  currency?: string;
  subtitle?: string;
  selected: boolean;
  disabled?: boolean;
  onSelect: () => void;
};

const Row = (props: Props) => {
  const theme = useTheme();

  const rowClick = () => {
    if (!props.disabled) {
      props.onSelect();
    }
  };

  return (
    <StyledBox mt={2} className={clsx(props.disabled && classes.disabled)}>
      <ButtonPlate
        containerStyle={{
          backgroundColor: theme.palette.secondary.main,
          border: '1px solid #E0E0E0',
        }}
        onClick={rowClick}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-start',
          }}
        >
          <GreenRadio
            checked={props.selected}
            iconVariant='check'
            disabled={props.disabled}
            variant='LIGHT'
          />
          <Box
            sx={{
              ml: 4,
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <Typography
              align='left'
              className={classes.text}
              sx={{
                display: 'inline',
              }}
            >
              {props.title}
            </Typography>
            {props.subtitle && props.currency && (
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  align='left'
                  className={clsx(classes.text, classes.accountIdText)}
                  sx={{
                    display: 'inline',
                  }}
                >
                  {props.subtitle}
                </Typography>
                <Typography
                  align='left'
                  className={clsx(classes.text, classes.accountIdText)}
                  sx={{
                    display: 'inline',
                  }}
                >
                  {props.currency}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ButtonPlate>
    </StyledBox>
  );
};

export default Row;
