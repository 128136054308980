/** @format */

import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import ClosableBanner from 'components/banners/ClosableBanner';
import {STRAT_LOW_CONV_BANNER, useBannerContext} from 'contexts/BannerContext';

const VALID_PATHS = ['/home/main/status'];

const StratLowConvIssueBanner = () => {
  const {pathname} = useLocation();

  const {t} = useTranslation();

  const {isBannerViewed, setBannerViewed} = useBannerContext();

  const skip =
    !VALID_PATHS.includes(pathname) || isBannerViewed(STRAT_LOW_CONV_BANNER);

  if (skip) return null;

  const handleClose = () => {
    setBannerViewed(STRAT_LOW_CONV_BANNER);
  };

  return (
    <ClosableBanner
      iconName='no_data'
      text={t(
        'Сенсор «Обучение автостратегии приостановлено» может работать некорректно, если у вас настроены пакетные стратегии в Яндекс Директе. Мы работаем над оптимизацией сенсора с учетом пакетных стратегий и скоро вернемся со сроками доработок.',
        'Сенсор «Обучение автостратегии приостановлено» может работать некорректно, если у вас настроены пакетные стратегии в Яндекс Директе. Мы работаем над оптимизацией сенсора с учетом пакетных стратегий и скоро вернемся со сроками доработок.',
      )}
      onClose={handleClose}
    />
  );
};

export default StratLowConvIssueBanner;
