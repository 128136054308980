/** @format */

import type {Platform, Provider} from 'types';

const platformIconSrc = (platform: Platform, size: number, bw = false) => {
  return providerIconSrc(platform, size, bw);
};

const providerIconSrc = (provider: Provider, size: number, bw = false) => {
  const suffix = bw ? `_${size}_bw` : `_${size}`;

  if (provider === 'YANDEX') {
    return new URL(`../images/yandex${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'GOOGLE') {
    return new URL(`../images/google${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'FACEBOOK') {
    return new URL(`../images/facebook${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'VKONTAKTE') {
    return new URL(`../images/vkontakte${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'VKONTAKTE_ADS') {
    return new URL(`../images/vkontakte_ads${suffix}.png`, import.meta.url)
      .href;
  }
  if (provider === 'METRIKA') {
    return new URL(`../images/metrika${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'WEBDAV') {
    return new URL(`../images/webdav${suffix}.png`, import.meta.url).href;
  }

  // src attribute of img requires either string or undefined
  return undefined;
};

const providerNewIconSrc = (provider: Provider, size?: number) => {
  const suffix = size ? `__${size}` : '';

  if (provider === 'YANDEX') {
    return new URL(`../images/yandex__new${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'GOOGLE') {
    return new URL(`../images/google__new${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'FACEBOOK') {
    return new URL(`../images/facebook__new${suffix}.png`, import.meta.url)
      .href;
  }
  if (provider === 'VKONTAKTE') {
    return new URL(`../images/vk__new${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'VKONTAKTE_ADS') {
    return new URL(`../images/vkr__new${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'METRIKA') {
    return new URL(`../images/metrika__new${suffix}.png`, import.meta.url).href;
  }
  if (provider === 'WEBDAV') {
    return new URL(`../images/webdav__new${suffix}.png`, import.meta.url).href;
  }

  // src attribute of img requires either string or undefined
  return undefined;
};

export default {
  platformIconSrc,
  providerIconSrc,
  providerNewIconSrc,
};
