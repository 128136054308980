/** @format */
import {useTranslation} from 'react-i18next';

import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';

// https://material-ui.com/components/text-fields/#input-adornments
const PasswordFieldFilled = props => {
  const {t} = useTranslation();

  const placeholder = () => {
    if (props.placeholder == null) {
      return t('Пароль', 'Пароль');
    }

    return props.placeholder;
  };

  return (
    <LegacyTextFieldFilled
      myProps={{
        placeholderFocused: t('Не менее 3 знаков', 'Не менее 3 знаков'),
        ...props.myProps,
      }}
      type='password'
      InputProps={{
        // https://github.com/mui-org/material-ui/issues/18556
        autoComplete: 'new-password',
      }}
      {...props}
      placeholder={placeholder()}
      fullWidth
    />
  );
};

export default PasswordFieldFilled;
