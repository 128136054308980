/** @format */
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  primary: string;
  secondary: string;
  onDelete?: () => void;
};

const UserListItem = (props: Props) => {
  return (
    (<ListItem>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText primary={props.primary} secondary={props.secondary} />
      {props.onDelete && (
        <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='delete' onClick={props.onDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>)
  );
};

export default UserListItem;
