/** @format */
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledTypography = styled(Typography)(() => ({
  color: '#8A877D',
  whiteSpace: 'pre-line',
}));

interface Props {
  children: React.ReactNode;
}

const BoxPlateText = (props: Props) => {
  return <StyledTypography variant='body1'>{props.children}</StyledTypography>;
};

export default BoxPlateText;
