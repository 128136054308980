/** @format */
import {useMemo} from 'react';

import {Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {Trans, useTranslation} from 'react-i18next';

import numberHelpers from 'helpers/numberHelpers';
import stringHelpers from 'helpers/stringHelpers';

import styles from './styles';
import CustomInfoRow from './CustomInfoRow';

const CUSTOM_INFO_SENSORS = [
  'as-account-limit-exceeded',
  'as-ad-high-bounce-rate',
  'as-ad-title-nokey',
  'as-bad-keys',
  'as-brand-traffic-anomaly-day',
  'as-brand-traffic-anomaly-week',
  'as-clicks-dropped',
  'as-conversion-dropped',
  'as-fade',
  'as-geo',
  'as-geo-card',
  'as-geo-domain',
  'as-geo-name-mismatch',
  'as-geo-view-link',
  'as-high-imp-ratio',
  'as-impression-dropped',
  'as-key-high-bounce-rate',
  'as-low-balance',
  'as-low-spend-cap',
  'as-lowbid',
  'as-place-low-goal-conv',
  'as-spend-key',
  'as-spent',
  'as-strat-low-conv',
  'as-typo'
];

interface CustomField {
  name: string;
  type:  'url' | 'text' | 'currency' | 'date' | 'num';
  value: string;
}

interface Objects {
  adText: CustomField
  adTitle: CustomField
  amountRemaining: CustomField
  analysisPeriod: CustomField
  averageSpend: CustomField
  badWord: CustomField
  bid: CustomField
  campaignLoc: CustomField
  cardLoc: CustomField
  clicks: CustomField
  clicksBefore: CustomField
  clientLoc: CustomField
  comparisonPeriod: CustomField
  conversions: CustomField
  conversionsBefore: CustomField
  cpc: CustomField
  ctr: CustomField
  ctrBefore: CustomField
  currencyCode: CustomField
  date: CustomField
  domainLoc: CustomField
  errwords: CustomField
  firstPageCpc: CustomField
  goalFullname: CustomField
  impressions: CustomField
  impressionsBefore: CustomField
  limit: CustomField
  link: CustomField
  linkLoc: CustomField
  linkType: CustomField
  metric: CustomField
  metricDiff: CustomField
  metricDiffAbs: CustomField
  metricPreviousValue: CustomField
  metricValue: CustomField
  placement: CustomField
  ratio: CustomField
  query: CustomField
  spent: CustomField
  spentBefore: CustomField
  targetLoc: CustomField
  utmParam: CustomField
  utmParamValue: CustomField
  vcard: CustomField
  views: CustomField
  viewsBefore: CustomField
  viewsPerUser: CustomField
}

interface Item {
  firstDate: string;
  keyword: string;
  objects: Objects;
}

interface Props {
  sensorName: string;
  reportItemGroup: Item[];
}

const CustomInfo = (props: Props) => {
  const {i18n} = useTranslation();

  const sample = props.reportItemGroup[0];
  const {firstDate, objects} = sample;

  const currency = objects.currencyCode?.value;
  const currencyFormatter = useMemo(() => {
    return numberHelpers.buildFormatter(currency || 'RUB', i18n.language);
  }, [currency, i18n.language]);

  if (!CUSTOM_INFO_SENSORS.includes(props.sensorName)) return null;

  const renderAsLowbid = () => {
    if (props.sensorName !== 'as-lowbid') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Текущая ставка</Trans>}
          Value={objects.bid.value}
        />

        <CustomInfoRow
          Name={<Trans>Ставка первой страницы</Trans>}
          Value={objects.firstPageCpc.value}
          mt={2}
        />
      </>
    );
  };

  const renderAsSpendKey = () => {
    if (props.sensorName !== 'as-spend-key') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>CPC</Trans>}
          Value={objects.cpc.value}
        />

        <CustomInfoRow
          Name={<Trans>% от общего расхода</Trans>}
          Value={objects.ratio.value}
          mt={2}
        />
      </>
    );
  };

  const renderAsSpent = () => {
    if (props.sensorName !== 'as-spent') return null;

    const formattedDate = objects.date.value;

    const spentText = currencyFormatter.format(
      Number.parseFloat(objects.spent.value),
    );
    const spentBeforeText = currencyFormatter.format(
      Number.parseFloat(objects.spentBefore.value),
    );

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Повышенный расход на {{formattedDate}}</Trans>}
          Value={spentText}
        />

        <CustomInfoRow
          Name={<Trans>Максимальный расход за предыдущие 7 дней</Trans>}
          Value={spentBeforeText}
          mt={2}
        />
      </>
    );
  };

  const renderAsTypo = () => {
    if (props.sensorName !== 'as-typo') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Слова с возможными опечатками</Trans>}
          Value={objects.errwords.value}
        />
      </>
    );
  };

  const renderAsBadKeys = () => {
    if (props.sensorName !== 'as-bad-keys') return null;

    return props.reportItemGroup.map((v, i) => (
      <Box key={i} mt={i === 0 ? 0 : 2}>
        <CustomInfoRow
          Name={<Trans>Поисковый запрос</Trans>}
          Value={v.objects.query.value}
        />

        <CustomInfoRow
          Name={<Trans>Опасное слово</Trans>}
          Value={v.objects.badWord.value}
          mt={2}
        />
      </Box>
    ));
  };

  const renderAsBrandTrafficAnomany = () => {
    if (props.sensorName !== 'as-brand-traffic-anomaly-day' &&
      props.sensorName !== 'as-brand-traffic-anomaly-week') return null;
    if (props.reportItemGroup.length === 0) return null;

    return (
      <Box>
        <CustomInfoRow
          Name={<Trans>Период сравнения</Trans>}
          Value={`${objects.analysisPeriod.value} — ${objects.comparisonPeriod.value}`}
        />
        {
          props.reportItemGroup.map((v, i) => {
            const previousValue = Number.parseFloat(v.objects.metricPreviousValue.value);
            const value = Number.parseFloat(v.objects.metricValue.value);
            const diffAbs = Number.parseFloat(v.objects.metricDiffAbs.value);
            const diffSign = value > previousValue ? '+' : '-';
            return (
              <CustomInfoRow
                Name={<Box>{stringHelpers.capitalize(v.objects.metric.value)}</Box>}
                Value={`${v.objects.metricValue.value} (${diffSign}${diffAbs})`}
                mt={2}
                key={i}
              />
            );
          })
        }
      </Box>
    );
  };

  const renderAsImpressionDropped = () => {
    if (props.sensorName !== 'as-impression-dropped') return null;

    const impressions = objects.impressions?.value;
    const impressionsBefore = objects.impressionsBefore?.value;
    const views = objects.views?.value;
    const viewsBefore = objects.viewsBefore?.value;

    const formattedDate = firstDate;

    if (impressions != null) {
      return (
        <>
          <CustomInfoRow
            Name={<Trans>Стало показов {{formattedDate}}</Trans>}
            Value={impressions}
            mt={2}
          />

          <CustomInfoRow
            Name={<Trans>Было минимум показов за предыдущие 4 дня</Trans>}
            Value={impressionsBefore}
          />
        </>
      );
    }

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Стало визитов {{formattedDate}}</Trans>}
          Value={views}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Было минимум визитов за предыдущие 4 дня</Trans>}
          Value={viewsBefore}
        />
      </>
    );
  };

  const renderAsFade = () => {
    if (props.sensorName !== 'as-fade') return null;

    const ctr = objects.ctr.value;
    const ctrBefore = objects.ctrBefore.value;

    const formattedDate = firstDate;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Был CTR раньше</Trans>}
          Value={ctrBefore}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Стал CTR с {{formattedDate}}</Trans>}
          Value={ctr}
        />
      </>
    );
  };

  const renderAsLowSpendCap = () => {
    if (props.sensorName !== 'as-low-spend-cap') return null;

    const amountRemaining = objects.amountRemaining.value;
    const averageSpend = objects.averageSpend.value;

    const amountRemainingText = currencyFormatter.format(Number.parseFloat(amountRemaining));
    const averageSpendText = currencyFormatter.format(Number.parseFloat(averageSpend));

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Средние траты за день</Trans>}
          Value={averageSpendText}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Остаток средств</Trans>}
          Value={amountRemainingText}
        />
      </>
    );
  };

  const renderAsLowBalance = () => {
    if (props.sensorName !== 'as-low-balance') return null;

    const amountRemaining = objects.amountRemaining.value;
    const averageSpend = objects.averageSpend.value;

    const amountRemainingText = currencyFormatter.format(Number.parseFloat(amountRemaining));
    const averageSpendText = currencyFormatter.format(Number.parseFloat(averageSpend));

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Средние траты за день</Trans>}
          Value={averageSpendText}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Остаток средств</Trans>}
          Value={amountRemainingText}
        />
      </>
    );
  };

  const renderAsHighImpRatio = () => {
    if (props.sensorName !== 'as-high-imp-ratio') return null;

    return (
      <CustomInfoRow
        Name={<Trans>Количество показов на человека</Trans>}
        Value={objects.viewsPerUser.value}
        mt={2}
      />
    );
  };

  const renderAsGeo = () => {
    if (props.sensorName !== 'as-geo') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Регионы показа</Trans>}
          Value={objects.targetLoc.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Регионы бизнеса</Trans>}
          Value={objects.clientLoc.value}
        />
      </>
    );
  };

  const renderAsGeoNameMismatch = () => {
    if (props.sensorName !== 'as-geo-name-mismatch') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Регионы показа</Trans>}
          Value={objects.targetLoc.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Регионы в названии</Trans>}
          Value={objects.campaignLoc.value}
        />
      </>
    );
  };

  const renderAsGeoCard = () => {
    if (props.sensorName !== 'as-geo-card') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Визитка</Trans>}
          Value={objects.vcard.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Регионы в визитке</Trans>}
          Value={objects.cardLoc.value}
        />

        <CustomInfoRow
          Name={<Trans>Регионы показа</Trans>}
          Value={objects.targetLoc.value}
        />
      </>
    );
  };

  const renderAsStratLowConv = () => {
    if (props.sensorName !== 'as-strat-low-conv') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Количество конверсий</Trans>}
          Value={objects.conversions.value}
          mt={2}
        />
        <CustomInfoRow
          Name={<Trans>Клики</Trans>}
          Value={objects.clicks.value}
        />
        <CustomInfoRow
          Name={<Trans>Период ретроспективного анализа</Trans>}
          Value={objects.analysisPeriod.value}
        />
      </>
    );
  };

  const renderAsAdTitleNokey = () => {
    if (props.sensorName !== 'as-ad-title-nokey') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Заголовок объявления</Trans>}
          Value={objects.adTitle.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Текст объявления</Trans>}
          Value={objects.adText.value}
        />

        <CustomInfoRow
          Name={<Trans>Ключевое слово</Trans>}
          Value={sample.keyword}
        />
      </>
    );
  };

  const renderAsGeoDomain = () => {
    if (props.sensorName !== 'as-geo-domain') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Регионы показа</Trans>}
          Value={objects.targetLoc.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Регионы в домене</Trans>}
          Value={objects.domainLoc.value}
        />
      </>
    );
  };

  const renderAsGeoViewLink = () => {
    if (props.sensorName !== 'as-geo-view-link') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Регионы показа</Trans>}
          Value={objects.targetLoc.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Регионы в ссылке</Trans>}
          Value={objects.linkLoc.value}
        />
      </>
    );
  };

  const renderAsClicksDropped = () => {
    if (props.sensorName !== 'as-clicks-dropped') return null;

    const formattedDate = objects.date.value;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Стало кликов {{formattedDate}}</Trans>}
          Value={objects.clicks.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Было кликов</Trans>}
          Value={objects.clicksBefore.value}
        />

        <CustomInfoRow
          Name={<Trans>Период ретроспективного анализа</Trans>}
          Value={objects.analysisPeriod.value}
        />
      </>
    );
  };

  const renderAsConversionDropped = () => {
    if (props.sensorName !== 'as-conversion-dropped') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Период ретроспективного анализа</Trans>}
          Value={objects.analysisPeriod.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Было конверсий</Trans>}
          Value={objects.conversionsBefore.value}
        />

        <CustomInfoRow
          Name={<Trans>Стало конверсий</Trans>}
          Value={objects.conversions.value}
        />
      </>
    );
  };

  const renderAsAccountLimitExceeded = () => {
    if (props.sensorName !== 'as-account-limit-exceeded') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Дневной бюджет</Trans>}
          Value={objects.limit.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Потрачено</Trans>}
          Value={objects.spent.value}
        />
      </>
    );
  };

  const renderAsPlaceLowGoalConv = () => {
    if (props.sensorName !== 'as-place-low-goal-conv') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Имя цели</Trans>}
          Value={objects.goalFullname.value}
          mt={2}
        />

        <CustomInfoRow
          Name={<Trans>Название площадки</Trans>}
          Value={objects.placement.value}
        />

        <CustomInfoRow
          Name={<Trans>Клики</Trans>}
          Value={objects.clicks.value}
        />

        <CustomInfoRow
          Name={<Trans>Конверсии</Trans>}
          Value={objects.conversions.value}
        />
      </>
    );
  };

  const renderAsAdHighBounceRate = () => {
    if (props.sensorName !== 'as-ad-high-bounce-rate') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Клики</Trans>}
          Value={objects.clicks.value}
          mt={2}
        />
      </>
    );
  };

  const renderAsKeyHighBounceRate = () => {
    if (props.sensorName !== 'as-key-high-bounce-rate') return null;

    return (
      <>
        <CustomInfoRow
          Name={<Trans>Клики</Trans>}
          Value={objects.clicks.value}
          mt={2}
        />
      </>
    );
  };

  return (
    <Box mt={2}>
      {renderAsAccountLimitExceeded()}
      {renderAsAdHighBounceRate()}
      {renderAsAdTitleNokey()}
      {renderAsBadKeys()}
      {renderAsBrandTrafficAnomany()}
      {renderAsClicksDropped()}
      {renderAsConversionDropped()}
      {renderAsFade()}
      {renderAsGeo()}
      {renderAsGeoCard()}
      {renderAsGeoDomain()}
      {renderAsGeoNameMismatch()}
      {renderAsGeoViewLink()}
      {renderAsHighImpRatio()}
      {renderAsImpressionDropped()}
      {renderAsKeyHighBounceRate()}
      {renderAsLowBalance()}
      {renderAsLowSpendCap()}
      {renderAsLowbid()}
      {renderAsPlaceLowGoalConv()}
      {renderAsSpendKey()}
      {renderAsSpent()}
      {renderAsStratLowConv()}
      {renderAsTypo()}
    </Box>
  );
};

export default withStyles(styles)(CustomInfo);
