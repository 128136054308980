/** @format */

import React, {useCallback, useContext, useMemo, useState} from 'react';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const CompanyContext = React.createContext();

export const COMPANY_KEY = 'COMPANY';
export const AD_TYPE_KEY = 'AD_TYPE';

export const CompanyProvider = props => {
  const {mixpanel} = useMixpanelContext();

  // JSON.parse('undefined') -> parsing error
  // JSON.parse('null') -> null
  const initialCompany = JSON.parse(localStorage.getItem(COMPANY_KEY));
  const initialAdType =
    JSON.parse(localStorage.getItem(AD_TYPE_KEY)) || 'CONTEXT';

  const [currentCompany, setCurrentCompany] = useState(initialCompany);
  const [currentAdType, setCurrentAdType] = useState(initialAdType);

  const switchCompany = useCallback(
    company => {
      localStorage.setItem(COMPANY_KEY, JSON.stringify(company));
      setCurrentCompany(company);

      if (company != null) {
        mixpanel.register({
          company_id: company.id,
          company_uuid: company.uuid,
          company_name: company.name,
        });
      }
    },
    [mixpanel],
  );

  const switchAdType = useCallback(
    adType => {
      localStorage.setItem(AD_TYPE_KEY, JSON.stringify(adType));
      setCurrentAdType(adType);

      mixpanel.register({ad_type: adType});
    },
    [mixpanel],
  );

  const contextValue = useMemo(
    () => ({
      currentCompany,
      switchCompany,
      currentAdType,
      switchAdType,
    }),
    [currentCompany, switchCompany, currentAdType, switchAdType],
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
