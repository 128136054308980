/** @format */

import clsx from 'clsx';
import {styled} from '@mui/material/styles';
import {Box, Link, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
const PREFIX = 'No2FA';

const classes = {
  body: `${PREFIX}-body`,
  underline: `${PREFIX}-underline`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.body}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },

  [`& .${classes.underline}`]: {
    textDecoration: 'underline',
  },
}));

const No2FA = () => {
  return (
    <StyledBox mt={5}>
      <Typography
        className={classes.body}
        sx={{
          display: 'inline',
        }}
      >
        <Trans>
          Мы не можем получить данные по вашему кабинету, пока вы не подключите
          двухфакторную аутентификацию.
        </Trans>
      </Typography>
      <Link
        className={clsx(classes.body, classes.underline)}
        href='/Инструкция_2FA.pdf'
        target='_blank'
        sx={{
          display: 'inline',
        }}
      >
        <Trans>Инструкция</Trans>
      </Link>
    </StyledBox>
  );
};

export default No2FA;
