/** @format */
import {Box} from '@mui/material';

import {styled} from '@mui/material/styles';

import TelegramIcon from '@mui/icons-material/Telegram';
import {Fab} from '@mui/material';

import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'TelegramFab';

const classes = {
  fab: `${PREFIX}-fab`,
  icon: `${PREFIX}-icon`,
};

const StyledFab = styled(Fab)(({theme}) => ({
  [`&.${classes.fab}`]: {
    backgroundColor: '#2E9ED6',
    bottom: theme.spacing(5),
    position: 'fixed',
    right: theme.spacing(5),
    '&:hover': {
      backgroundColor: '#1E8EC6',
    },
  },

  [`& .${classes.icon}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    fontSize: 28,
  },
}));

const TelegramFab = () => {
  const mixpanelContext = useMixpanelContext();

  if (!routerHelpers.IsRuVersion()) {
    return <Box></Box>;
  }

  const handleClick = () => {
    window.dataLayer?.push({event: 'app_click_telegram'});
    mixpanelContext?.mixpanel.track('click_fab_telegram');
  };

  return (
    <StyledFab
      className={classes.fab}
      href='https://t.me/adsensor_amocrm_bot'
      // @ts-ignore
      target='_blank'
      onClick={handleClick}
    >
      <TelegramIcon className={classes.icon} />
    </StyledFab>
  );
};

export default TelegramFab;
