/** @format */
import {useTranslation} from 'react-i18next';

import NativeTabs from 'components/tabs/NativeTabs';

type Tab = {
  path: string;
  name: string;
  text: string;
};

type Props = {
  activeTab: string;
  onChange: (tab: string) => void;
};

const IssueTabs = (props: Props) => {
  const {t} = useTranslation();

  const TABS: Tab[] = [
    {path: '#all', name: 'ALL', text: t('Все', 'Все')},
    {path: '#improvements', name: 'IMPROVEMENTS', text: t('Улучшения', 'Улучшения')},
    {path: '#recommendations', name: 'RECOMMENDATIONS', text: t('Рекомендации', 'Рекомендации')},
  ];
  return (
    <NativeTabs
      activeTabName={props.activeTab}
      tabs={TABS}
      variant='DARK'
      onChange={props.onChange}
    />
  );
};

export default IssueTabs;
