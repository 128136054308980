/** @format */

import {useCallback, useEffect, useState} from 'react';

import clsx from 'clsx';
import {
  Box,
  Grid,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import NextButton from 'onboarding/NextButton';
import {STEP_BACKDROP_CONTAINER_Z_INDEX} from 'onboarding/StepBackdrop';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useOnboardingContext} from 'contexts/OnboardingContext';

const SHOW_DELAY = 500;
const SCROLL_DURATION = 500;
const UPDATE_POSITION_DELAY = 100;

const useStyles = makeStyles(theme => ({
  popover: {
    // https://stackoverflow.com/a/63275655/3632318
    zIndex: `${STEP_BACKDROP_CONTAINER_Z_INDEX + 1} !important`,
  },
  paper: {
    borderRadius: 8,
    padding: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 312,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  text: {
    color: theme.palette.text.secondary,
  },
  stepInfoText: {
    fontSize: 10,
    fontWeight: '400',
    lineHeight: '16px',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: '22px',
    marginTop: theme.spacing(2),
  },
  body: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '18px',
    marginTop: theme.spacing(4),
    whiteSpace: 'pre-line',
  },
}));

// Algorithm to show onboarding step popover:
//
// - Wait for SHOW_DELAY till page is rendered
// - Send SHOW event to FSM to show current step
// - Scroll to anchor element
// - Wait for SCROLL_DURATION till scroll is complete
// - Render current step
const BaseStep = props => {
  const context = useOnboardingContext();
  const {current, send} = context.onboardings[props.onboardingId];
  const anchorRef = context.anchorRefs[props.onboardingId][props.stepId];

  const {mixpanel} = useMixpanelContext();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState(null);
  const [scrollComplete, setScrollComplete] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  // https://github.com/mui-org/material-ui/blob/v4.10.0/packages/material-ui/src/Popover/Popover.js#L356
  const actionRef = useCallback(node => {
    // node is actions object here
    if (node) setActions(node);
  }, []);

  const stepName = `o${props.onboardingId.slice(-1)}s${props.stepId.slice(-1)}`;
  const showStep = context.showStep(props.onboardingId, props.stepId);

  useEffect(() => {
    if (!props.showOnboarding) return;

    setTimeout(() => {
      setAnchorEl(anchorRef.current);

      const isPending = current.matches('active.pending');
      isPending ? send('START') : send('SHOW');
    }, SHOW_DELAY);

    // Just run once when step renders (same as componentDidMount)
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showStep) return;
    mixpanel.track(`view_${stepName}`);

    // See also https://stackoverflow.com/a/56391657/3632318
    // for more fine-grained control over scrolling
    const block = props.isFooterAnchor ? 'end' : 'start';
    anchorRef.current.scrollIntoView({behavior: 'smooth', block});

    setTimeout(() => {
      setScrollComplete(true);
    }, SCROLL_DURATION);
  }, [showStep, mixpanel, stepName, props.isFooterAnchor, anchorRef]);

  useEffect(() => {
    context.setShowOnboarding(props.onboardingId, props.showOnboarding);

    // Remove `context.setShowOnboarding` from dependencies -
    // it changes on every render
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onboardingId, props.showOnboarding]);

  // https://stackoverflow.com/a/62025228/3632318
  //
  // `actions` object is returned by popover when the latter becomes visible
  // => set `actions` and update popover position when current step is shown
  useEffect(() => {
    if (!actions) return;

    setTimeout(() => {
      actions.updatePosition();
    }, UPDATE_POSITION_DELAY);
  }, [actions]);

  if (!anchorEl) return null;
  if (!showStep) return null;
  if (!scrollComplete) return null;

  const handleClose = () => {
    mixpanel.track(`close_${stepName}`);
    send('CLOSE');
  };

  const handleNext = () => {
    if (isLastStep) {
      mixpanel.track(`finish_${stepName}`);
    } else {
      mixpanel.track(`next_${stepName}`);
    }

    send('NEXT');
  };

  const anchorOrigin = isMobile
    ? props.isFooterAnchor
      ? {vertical: 'top', horizontal: 'left'}
      : {vertical: 'bottom', horizontal: 'left'}
    : props.isFooterAnchor
    ? {vertical: 'bottom', horizontal: 'right'}
    : {vertical: 'top', horizontal: 'right'};

  const transformOrigin = isMobile
    ? props.isFooterAnchor
      ? {vertical: 'bottom', horizontal: 'left'}
      : {vertical: 'top', horizontal: 'left'}
    : props.isFooterAnchor
    ? {vertical: 'bottom', horizontal: 'left'}
    : {vertical: 'top', horizontal: 'left'};

  const paperStyle = isMobile
    ? props.isFooterAnchor
      ? {marginTop: -theme.spacing(2)}
      : {marginTop: theme.spacing(2)}
    : {marginLeft: theme.spacing(2)};

  const {currentStep, totalSteps} = current.context;
  const isLastStep = currentStep === totalSteps;

  const iconSrc =
    props.iconSrc || new URL('./images/alarm.jpg', import.meta.url);

  const renderButton = () => {
    if (props.renderButton) {
      return props.renderButton(handleNext);
    }

    if (isLastStep) {
      return (
        <Grid item xs={6}>
          <NextButton onClick={handleNext}>Понятно</NextButton>
        </Grid>
      );
    }

    return (
      <Grid item xs={6}>
        <NextButton onClick={handleNext}>Дальше</NextButton>
      </Grid>
    );
  };

  // I removed `onClose` property so that user can close popover by clicking
  // close icon only
  //
  // https://stackoverflow.com/a/54500717/3632318
  // Set `container={anchorEl.parentNode}` to allow scrolling
  return (
    (<Popover
      action={actionRef}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      className={classes.popover}
      marginThreshold={8}
      open
      transformOrigin={transformOrigin}
      PaperProps={{className: classes.paper, style: paperStyle}}
    >
      <Box alignItems='flex-start' display='flex' flexDirection='column'>
        <img alt='Icon' height='100' src={iconSrc} width='100' />
        <IconButton className={classes.closeIcon} onClick={handleClose} size="large">
          <img
            alt='Close'
            src={new URL('./images/close.svg', import.meta.url)}
          />
        </IconButton>

        <Box mt={3}>
          <Typography className={clsx(classes.text, classes.stepInfoText)}>
            {`${currentStep} из ${totalSteps}`}
          </Typography>
          <Typography className={clsx(classes.text, classes.title)}>
            {props.title}
          </Typography>
          {props.body && (
            <Typography className={clsx(classes.text, classes.body)}>
              {props.body}
            </Typography>
          )}

          <Box mt={7}>
            <Grid container spacing={2}>
              {renderButton()}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Popover>)
  );
};

BaseStep.defaultProps = {
  body: null,
  iconSrc: null,
  isFooterAnchor: false,
  renderButton: null,
};

export default BaseStep;
