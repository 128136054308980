/** @format */
import {useTranslation} from 'react-i18next';

import type {Platform, WarningsType} from 'types';
import {snakeToCamel} from 'helpers/stringHelpers';

import NewNativeTabs from './NewNativeTabs';

type Tab = {
  path: string;
  name: Platform;
  text: string;
  mobileText: string;
  hasWarning?: boolean;
};

type Props = {
  activePlatform: Platform;
  allPlatforms: Platform[];
  onChange: (platform: Platform) => void;

  warnings?: WarningsType;
};

const PlatformTabsNew = (props: Props) => {
  const {t} = useTranslation();

  const rawTabs: Tab[] = [
    {
      path: '#',
      name: 'ALL',
      text: t('Все системы', 'Все системы'),
      mobileText: t('Все', 'Все'),
    },
    {
      path: '#',
      name: 'YANDEX',
      text: t('Яндекс Директ', 'Яндекс Директ'),
      mobileText: t('Директ', 'Директ'),
    },
    {
      path: '#',
      name: 'GOOGLE',
      text: t('Google Ads', 'Google Ads'),
      mobileText: t('GAds', 'GAds'),
    },
    {
      path: '#',
      name: 'VKONTAKTE',
      text: t('ВКонтакте', 'ВКонтакте'),
      mobileText: t('VKAds', 'VKAds'),
    },
    {
      path: '#',
      name: 'VKONTAKTE_ADS',
      text: t('ВК Реклама', 'ВК Реклама'),
      mobileText: t('ВКР', 'ВКР'),
    },
  ];

  const tabs = props.warnings
    ? rawTabs
        .filter(v => props.allPlatforms.includes(v.name))
        .map(item => {
          const warningItem =
            props.warnings?.[snakeToCamel(item.name.toLowerCase())];
          const hasWarning = !!warningItem?.hasWarnings;

          return {
            ...item,
            hasWarning,
          };
        })
    : rawTabs.filter(v => props.allPlatforms.includes(v.name));

  return (
    <NewNativeTabs
      activeTabName={props.activePlatform}
      tabs={tabs}
      onChange={props.onChange}
      variant='DARK'
    />
  );
};

export default PlatformTabsNew;
