/** @format */

import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {styled} from '@mui/material/styles';

const PREFIX = 'Finish';

const classes = {
  text: `${PREFIX}-text`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.text}`]: {
    color: theme.palette.text.secondary,
  },
}));

const Finish = () => {
  return (
    <Root>
      <Typography variant='h1' className={classes.text}>
        <Trans>Готово!</Trans>
      </Typography>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Typography variant='body1'>
          <Trans>Спасибо, что справились так быстро.</Trans>
          <br />
          <br />
          <Trans>
            Мы уже запустилии проверки, через пару часов отчёт будет готов.
          </Trans>
        </Typography>
      </Box>
    </Root>
  );
};

export default Finish;
