/** @format */

import {Box, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans} from 'react-i18next';

import BoxPlate from 'components/plates/BoxPlate';

type PaymentSystem =
  | 'MIR'
  | 'AMERICAN_EXPRESS'
  | 'VISA'
  | 'MASTER_CARD'
  | 'UNKNOWN';

type PaymentMethod = {
  id: number;
  paymentSystem: PaymentSystem;
  last4: string;
  isExpired: boolean;
};

const PAYMENT_SYSTEM_HUMAN_NAMES = {
  MIR: 'Мир',
  AMERICAN_EXPRESS: 'American Express',
  VISA: 'Visa',
  MASTER_CARD: 'Master Card',
  UNKNOWN: 'Неизвестно',
};

type Props = {
  paymentMethod: PaymentMethod;
  variant: 'LIGHT' | 'DARK';
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    expiredText: {
      color: '#DD4B4B',
    },
  }),
);

const PaymentMethod = (props: Props) => {
  const classes = useStyles();

  const iconSrc = (() => {
    if (props.paymentMethod.paymentSystem === 'VISA') {
      return new URL('./images/visa.png', import.meta.url).href;
    }

    if (props.paymentMethod.paymentSystem === 'MASTER_CARD') {
      return new URL('./images/master_card.png', import.meta.url).href;
    }

    return new URL('./images/unknown.png', import.meta.url).href;
  })();

  return (
    <BoxPlate
      backgroundColor={props.variant === 'LIGHT' ? '#E1E1E1' : '#202020'}
    >
      <Box alignItems='center' display='flex'>
        {iconSrc && (
          <Box mr={4}>
            <img alt='Logo' height='48' src={iconSrc} width='48' />
          </Box>
        )}

        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Typography variant='h2'>
            {PAYMENT_SYSTEM_HUMAN_NAMES[props.paymentMethod.paymentSystem]}
          </Typography>

          <Box mt={1}>
            <Typography variant='body2'>
              {`•••• ${props.paymentMethod.last4}`}
            </Typography>
          </Box>

          {props.paymentMethod.isExpired && (
            <Box mt={1}>
              <Typography className={classes.expiredText} variant='body2'>
                <Trans>Истёк срок действия карты</Trans>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BoxPlate>
  );
};

// https://stackoverflow.com/a/60882895/3632318
// PaymentMethod is an alias for both component and type
export default PaymentMethod;
