/** @format */

import {Box, LinearProgress, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

import Modal from 'components/Modal';

const PREFIX = 'Loading';

const classes = {
  body: `${PREFIX}-body`,
  progress: `${PREFIX}-progress`,
};

const StyledModal = styled(Modal)(({theme}) => ({
  [`& .${classes.body}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.progress}`]: {
    backgroundColor: theme.palette.success.main,
    flex: 1,
  },
}));

const Loading = props => {
  return (
    <StyledModal header={props.title} showCloseIcon={false}>
      {props.body && (
        <Box
          sx={{
            mb: 10,
          }}
        >
          <Typography className={classes.body} variant='body1'>
            {props.body}
          </Typography>
        </Box>
      )}
      <LinearProgress className={classes.progress} />
    </StyledModal>
  );
};

export default Loading;
