/** @format */

import MenuIcon from '@mui/icons-material/Menu';
import {Box, Hidden, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {useLocation} from 'react-router-dom';

import NativeMenu from 'components/menus/NativeMenu';

const TopMenu = props => {
  const {pathname} = useLocation();

  const {t} = useTranslation();

  const leftItems = [
    {path: '/home/main', name: 'MAIN', text: t('Главная', 'Главная')},
  ];
  const rightItems = [
    {
      path: '/home/settings',
      name: 'SETTINGS',
      text: t('Настройки', 'Настройки'),
    },
  ];

  const allItems = leftItems.concat(rightItems);
  const activeItem = allItems.find(v => pathname.startsWith(v.path));

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {props.onToggleDrawer && (
        <Hidden mdUp>
          <Box
            sx={{
              mr: 2,
            }}
          >
            <IconButton
              color='inherit'
              edge='start'
              onClick={props.onToggleDrawer}
              size='large'
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Hidden>
      )}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <NativeMenu
          activeItem={activeItem}
          leftItems={leftItems}
          rightItems={rightItems}
        />
      </Box>
    </Box>
  );
};

export default TopMenu;
