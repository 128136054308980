/** @format */

import ButtonPlate from 'components/plates/ButtonPlate';

interface Props {
  selected: boolean
  children: React.ReactChild
  onClick: () => void
};

const ConnectedPlate = (props: Props) => {
  return (
    <ButtonPlate
      containerStyle={{backgroundColor: props.selected ? '#FFFFFF' : '#616161'}}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonPlate>
  );
};

export default ConnectedPlate;
