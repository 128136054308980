/** @format */

import {Backdrop, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';

import ActionBanner, {
  ACTION_BANNER_Z_INDEX,
} from 'components/banners/ActionBanner';
import paymentHelpers from 'helpers/paymentHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'PaymentBanner';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBox = styled(Box)(({theme}) => ({
  width: '100%',

  [`& .${classes.backdrop}`]: {
    backgroundColor: theme.backdrop.main,
    zIndex: ACTION_BANNER_Z_INDEX - 1,
  },
}));

const VALID_PATHS = ['/home/main/wait', '/home/main/status'];

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      type
      isNew
      isFreemium
      subscription {
        activeSubscription
        beginDate
        endDate
        planId
        hasUnpaidPostpaidBills
      }
    }
  }
`;

const PaymentBanner = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const {mixpanel} = useMixpanelContext();

  const {t} = useTranslation();

  const skip = !VALID_PATHS.includes(location.pathname);
  const {loading, error, data} = useQuery(GET_CURRENT_USER, {skip});

  if (loading || error) return null;
  if (skip) return null;

  const {type: userType, isNew, isFreemium, subscription} = data.currentUser;
  const isTrial = !subscription.planId;

  const handlePay = () => {
    mixpanel.track('click_payment_banner_pay_button');

    // NOTE: Temporary disable any payment pages
    // if (isNew) {
    // navigate({
    // pathname: '/home/settings/confirm-prepay-budget',
    // state: {background: location},
    // });
    // return;
    // }

    // if (userType === 'BUSINESS') {
    // navigate({
    // pathname: '/home/settings/confirm-pay-accounts',
    // state: {background: location},
    // });
    // } else {
    // navigate('/home/settings/manager');
    // }
    navigate('/home/settings/manager');
  };

  const renderPostpayBanner = () => {
    const text = (
      <Trans>
        Закончились 30 дней использования сервиса.
        <br />
        Внесите оплату за завершенный период использования
      </Trans>
    );
    const handlePostpay = () => {
      mixpanel.track('click_payment_banner_postpay_button');

      navigate('/home/settings/manager');
      // NOTE: Temporary disable any payment pages
      // navigate({
      // pathname: '/home/settings/confirm-postpay-budget',
      // state: {background: location},
      // });
    };

    return (
      <StyledBox>
        <ActionBanner
          buttonText={t('Оплатить', 'Оплатить')}
          iconName='pay_soon'
          text={text}
          onClick={handlePostpay}
        />
      </StyledBox>
    );
  };

  const renderPayNowBanner = () => {
    const text = isTrial ? (
      <Trans>Тестовый период закончился, оплатите подписку AdSensor</Trans>
    ) : (
      <Trans>Оплатите подписку, чтобы продолжить пользоваться AdSensor</Trans>
    );

    return (
      <StyledBox>
        <ActionBanner
          iconName='pay_now'
          text={text}
          buttonText={t('Оплатить', 'Оплатить')}
          onClick={handlePay}
        />
        <Backdrop className={classes.backdrop} open />
      </StyledBox>
    );
  };

  const renderPaySoonBanner = () => {
    const daysLeft = paymentHelpers.daysLeft(subscription);
    const daysLeftText = t('plurals.days_left', {count: daysLeft});

    const text = () => {
      if (isTrial) {
        return <Trans>{{daysLeftText}} до окончания тестового периода</Trans>;
      }

      if (isNew) {
        return (
          <Trans>
            {{daysLeftText}} до окончания подписки. Сделай предоплату!
          </Trans>
        );
      }

      return <Trans>{{daysLeftText}} до окончания подписки</Trans>;
    };

    return (
      <StyledBox>
        <ActionBanner
          buttonText={t('Оплатить', 'Оплатить')}
          iconName='pay_soon'
          text={text()}
          onClick={handlePay}
        />
      </StyledBox>
    );
  };

  if (subscription.hasUnpaidPostpaidBills) {
    return renderPostpayBanner();
  }

  if (isNew && isFreemium) return null;

  if (paymentHelpers.payNow(subscription)) {
    return renderPayNowBanner();
  }

  if (paymentHelpers.paySoon(subscription)) {
    return renderPaySoonBanner();
  }

  return null;
};

export default PaymentBanner;
