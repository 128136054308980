/** @format */

import {Container, CssBaseline} from '@mui/material';
import {styled} from '@mui/material/styles';
const PREFIX = 'BaseLayout';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.container}`]: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      maxWidth: 700,
    },
  },
}));

type Props = {
  children: React.ReactChild;
};

const BaseLayout = (props: Props) => {
  return (
    <Root className={classes.root}>
      <CssBaseline />
      <Container className={classes.container} disableGutters maxWidth='xs'>
        {props.children}
      </Container>
    </Root>
  );
};

export default BaseLayout;
