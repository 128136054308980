/** @format */
import {Box, Link, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {isToday, parseISO} from 'date-fns';
import {Trans, useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {gql, useMutation} from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import TransparentSmallButton from 'components/buttons/TransparentSmallButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerTitle: {
      color: '#F1F1F1',
      fontSize: '28px',
      fontWeight: 700,
    },
    lastCheckTitle: {
      marginBottom: theme.spacing(1),
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
    },
    lastCheckText: {
      color: '#F1F1F1',
      fontSize: '14px',
      fontWeight: 400,
    },
    editButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 48,
      width: 48,
      height: 48,
      borderRadius: '8px',
      background: '#202020',
      border: 'none',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      padding: 0,
    },
    recheckButton: {
      width: '120px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      background: '#202020',
      border: 'none',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      padding: theme.spacing(4),
      '& .MuiCircularProgress-svg circle': {
        stroke: '#677C41',
      },
    },
    controlsContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(4),
      },
    },
  }),
);

const LAUNCH = gql`
  mutation Launch {
    launch
  }
`;

interface Props {
  companyId: number;
  name: string;
  lastCheckCreateDate: string;
  isLaunched: boolean;

  isLaunchEnabled: boolean;
  onLaunch: () => void;
}

const HeaderNew = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [launch, {loading}] = useMutation(LAUNCH);
  const date = parseISO(props.lastCheckCreateDate);

  const lastCheckText = () => {
    if (isToday(date)) {
      return t('Сегодня в {{formattedTime, datetime}}', {
        formatParams: {
          formattedTime: {hour: 'numeric', minute: 'numeric'},
        },
      });
    }
    return t('{{formattedDate, datetime}} в {{formattedTime, datetime}}', {
      formattedDate: date,
      formattedTime: date,
      formatParams: {
        formattedDate: {day: 'numeric', month: 'long'},
        formattedTime: {hour: 'numeric', minute: 'numeric'},
      },
    });
  };

  const handleEditButtonClick = () => {
    navigate(`/home/settings/connect/company?companyId=${props.companyId}`);
  };

  const renderLaunchText = () => {
    const handleLaunch = async () => {
      await launch();
      props.onLaunch();
    };

    return (
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        className={classes.recheckButton}
        mr={4}
      >
        <img
          alt='Recheck'
          height='14'
          src={new URL('./images/recheck-new.png', import.meta.url).href}
          width='13'
        />
        <Box ml={2}>
          {loading ? (
            <Typography>
              <Trans>Обновить</Trans>
            </Typography>
          ) : (
            <Link component='button' onClick={handleLaunch}>
              <Typography>
                <Trans>Обновить</Trans>
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        display='flex'
        alignItems={'center'}
        justifyContent='space-between'
        flexWrap={'wrap'}
        mb={6}
      >
        <Typography className={classes.headerTitle}>{props.name}</Typography>

        <Box
          display={'flex'}
          alignItems={'center'}
          className={classes.controlsContainer}
        >
          <Box
            display='flex'
            flexDirection={'column'}
            alignItems={'flex-end'}
            mr={4}
          >
            <Typography className={classes.lastCheckTitle}>
              <Trans>Обновлено:</Trans>
            </Typography>
            <Typography className={classes.lastCheckText}>
              {lastCheckText()}
            </Typography>
          </Box>

          {props.isLaunchEnabled && (
            <Box>
              {props.isLaunched ? (
                <Box className={classes.recheckButton} mr={4}>
                  <CircularProgress size={14} />
                </Box>
              ) : (
                renderLaunchText()
              )}
            </Box>
          )}
          <TransparentSmallButton
            className={classes.editButton}
            onClick={handleEditButtonClick}
          >
            <EditOutlinedIcon fontSize='small' htmlColor='#fff' />
          </TransparentSmallButton>
        </Box>
      </Box>
    </>
  );
};

export default HeaderNew;
