/** @format */

import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {parsePhoneNumber} from 'libphonenumber-js';

import BoxPlate from 'components/plates/BoxPlate';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'BaseManager';

const classes = {
  list: `${PREFIX}-list`,
  listItemAvatar: `${PREFIX}-listItemAvatar`,
  image: `${PREFIX}-image`,
  listPrimaryText: `${PREFIX}-listPrimaryText`,
  listSecondaryText: `${PREFIX}-listSecondaryText`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledBoxPlate = styled(BoxPlate)(({theme}) => ({
  [`& .${classes.list}`]: {
    padding: 0,
  },

  [`& .${classes.listItemAvatar}`]: {
    minWidth: theme.spacing(20),
  },

  [`& .${classes.image}`]: {
    borderRadius: '50%',
  },

  [`& .${classes.listPrimaryText}`]: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
  },

  [`& .${classes.listSecondaryText}`]: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    marginTop: theme.spacing(1),
    color: '#fff',
  },

  [`& .${classes.buttonContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface Props {
  phone: string;
  variant: 'DARK' | 'LIGHT';
  buttonVariant?: 'PRIMARY' | 'SECONDARY';
  imageSrc: string;
  name: string;
  email?: string;
  telegram?: string;
}

const BaseManager = (props: Props) => {
  const {mixpanel} = useMixpanelContext();

  const theme = useTheme();

  const color = () => {
    if (props.variant === 'DARK') {
      return '#fff';
    }

    if (props.variant === 'LIGHT') {
      return theme.palette.text.secondary;
    }

    return undefined;
  };

  const backgroundColor = () => {
    if (props.variant === 'DARK') {
      return theme.palette.action.disabledBackground;
    }

    if (props.variant === 'LIGHT') {
      return theme.palette.secondary.main;
    }

    return undefined;
  };

  const phoneNumber = parsePhoneNumber(props.phone);

  const handleClickTelegram = () => {
    mixpanel.track('click_manager_telegram_button', {name: props.name});
  };

  const handleClickEmail = () => {
    mixpanel.track('click_manager_email_button', {name: props.name});
  };

  return (
    <StyledBoxPlate backgroundColor={backgroundColor()}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <List classes={{root: classes.list}}>
            <ListItem disableGutters>
              <ListItemAvatar classes={{root: classes.listItemAvatar}}>
                <img
                  alt='Manager'
                  className={classes.image}
                  height={60}
                  src={props.imageSrc}
                  width={60}
                />
              </ListItemAvatar>
              <ListItemText
                primary={props.name}
                secondary={
                  <Link
                    href={phoneNumber.getURI()}
                    style={{color: color()}}
                    underline='none'
                  >
                    {phoneNumber.formatNational()}
                  </Link>
                }
                primaryTypographyProps={{
                  className: classes.listPrimaryText,
                  style: {color: color()},
                }}
                secondaryTypographyProps={{
                  className: classes.listSecondaryText,
                }}
              />
            </ListItem>
          </List>
        </Grid>

        {props.telegram && (
          <Grid item className={classes.buttonContainer} xs={12} sm={6}>
            {props.buttonVariant === 'PRIMARY' ? (
              <PrimaryMediumButton
                href={props.telegram}
                target='_blank'
                onClick={handleClickTelegram}
              >
                <Trans>Написать в Телеграм</Trans>
              </PrimaryMediumButton>
            ) : (
              <SecondaryMediumButton
                href={props.telegram}
                target='_blank'
                onClick={handleClickTelegram}
              >
                <Trans>Написать в Телеграм</Trans>
              </SecondaryMediumButton>
            )}
          </Grid>
        )}
        {props.email && (
          <Grid item className={classes.buttonContainer} xs={12} sm={6}>
            {props.buttonVariant === 'PRIMARY' ? (
              <PrimaryMediumButton
                href={props.email}
                target='_blank'
                onClick={handleClickEmail}
              >
                <Trans>Написать в почту</Trans>
              </PrimaryMediumButton>
            ) : (
              <SecondaryMediumButton
                href={props.email}
                target='_blank'
                onClick={handleClickEmail}
              >
                <Trans>Написать в почту</Trans>
              </SecondaryMediumButton>
            )}
          </Grid>
        )}
      </Grid>
    </StyledBoxPlate>
  );
};

export default BaseManager;
