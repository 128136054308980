/** @format */
import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';

import ShareLink from 'components/ShareLink';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const PREFIX = 'InviteGuest';

const classes = {
  text: `${PREFIX}-text`,
};

const Root = styled('div')(() => ({
  [`& .${classes.text}`]: {
    color: '#A4A4A4',
  },
}));

interface mutationCreateGuest {
  id: number;
  uuid: string;
}

const CREATE_GUEST = gql`
  mutation CreateGuest($companyId: ID!) {
    createGuest(companyId: $companyId) {
      id
      uuid
    }
  }
`;

const InviteGuest = () => {
  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const {mixpanel} = useMixpanelContext();

  const [guestUUID, setGuestUUID] = useState('');
  const [createGuestError, setCreateGuestError] = useState('');

  const [createGuest, {data, loading}] = useMutation<{
    createGuest: mutationCreateGuest;
  }>(CREATE_GUEST, {variables: {companyId}});

  useEffect(() => {
    createGuest().catch(e => setCreateGuestError((e as Error).message));
  }, []);

  useEffect(() => {
    if (data != null) {
      setGuestUUID(data.createGuest.uuid);
    }
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (createGuestError) {
    return <Alert severity='error'>{createGuestError}</Alert>;
  }

  const handleCopy = () => {
    mixpanel.track('click_copy_invite_guest_url_button');
  };

  const url = () => {
    return `${location.origin}/guest/welcome/${guestUUID}?companyId=${companyId}`;
  };

  return (
    <Root>
      <Box
        sx={{
          mb: 8,
        }}
      >
        <Typography variant='h1'>
          <Trans>Если у вас нет доступов от кабинетов</Trans>
        </Typography>
      </Box>
      <Typography variant='body1' className={classes.text}>
        <Trans>
          Скопируйте и отправьте ссылку тому, кто может подключить кабинеты для
          вас. Это может быть ваш специалист, кто ведет рекламные кампании или
          представитель агентства, с кем вы работаете.
        </Trans>
      </Typography>
      <Box
        sx={{
          my: 5,
        }}
      >
        <ShareLink url={url()} variant='DARK' onCopy={handleCopy} />
      </Box>
      <Typography variant='body1' className={classes.text}>
        <Trans>
          По ссылке специалист увидит интерфейс подключения кабинетов, отчёт он
          сможет получить только если вы настроите отправку уведомлений на
          email.
        </Trans>
      </Typography>
    </Root>
  );
};

export default InviteGuest;
