/** @format */

import {Tab, TabProps} from '@mui/material';
import {styled} from '@mui/material/styles';

interface Props extends TabProps {
  variant: string;
}

const StyledTab = styled(Tab)<Props>(({theme, variant}) => ({
  color: '#DFDFDF',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  minWidth: 'unset',
  opacity: 1,
  padding: 0,
  paddingRight: 0,
  maxWidth: '100%',
  flexGrow: 1,
  textTransform: 'none',
  background: '#202020',
  '&.Mui-selected': {
    backgroundColor: '#363636',
    borderColor: 'transparent',
    color: variant === 'DARK' ? theme.palette.text.primary : '#000000',
  },
}));

const NewV2StyledTab = (props: Props) => {
  return <StyledTab {...props} />;
};

export default NewV2StyledTab;
