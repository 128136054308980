/** @format */

import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {useFlags} from 'launchdarkly-react-client-sdk';

const PREFIX = 'DisconnectButton';

const classes = {
  button: `${PREFIX}-button`,
};

const StyledIconButton = styled(IconButton)(() => ({
  [`& .${classes.button}`]: {
    backgroundColor: '#332323',
    height: 44,
    width: 44,
    '&:hover': {
      backgroundColor: '#231313',
    },
  },
}));

const DisconnectButton = props => {
  const {isNewVersionEnabled} = useFlags();

  if (isNewVersionEnabled) {
    return (
      <StyledIconButton
        className={props.className}
        onClick={props.onDisconnect}
        size='large'
      >
        <RemoveCircleIcon fontSize='small' htmlColor='#AE5658' />
      </StyledIconButton>
    );
  }

  return (
    <StyledIconButton
      className={classes.button}
      onClick={props.onDisconnect}
      size='large'
    >
      <img
        alt='Disconnect'
        height='2'
        src={new URL('./images/minus.png', import.meta.url)}
        width='19'
      />
    </StyledIconButton>
  );
};

export default DisconnectButton;
