/** @format */

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';

import BackHeader from 'components/BackHeader';
import PaymentMethod from './PaymentMethod';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      paymentMethods {
        id
        paymentSystem
        last4
        isExpired
      }
    }
  }
`;

const PaymentMethods = () => {
  const {loading, error, data} = useQuery(GET_CURRENT_USER);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {paymentMethods} = data.currentUser;

  return (
    <>
      <Box mb={10}>
        <BackHeader />
      </Box>
      <Typography variant='h1'>
        <Trans>Платёжные данные</Trans>
      </Typography>

      <Box mt={7}>
        {paymentMethods.length === 0 ? (
          <Typography variant='body2'>
            <Trans>У вас нет активных карт.</Trans>
          </Typography>
        ) : (
          paymentMethods.map((v: PaymentMethod, i: number) => (
            <Box key={i} mb={3}>
              <PaymentMethod paymentMethod={v} variant='DARK' />
            </Box>
          ))
        )}
      </Box>
    </>
  );
};

export default PaymentMethods;
