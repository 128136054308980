/** @format */

import {styled} from '@mui/material/styles';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const StyledPrimaryMediumButton = styled(PrimaryMediumButton)(({theme}) => ({
  backgroundColor: '#2D2D2D',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: '#2D2D2D',
  },
}));

const TertiaryMediumButton = props => <StyledPrimaryMediumButton {...props} />;

export default TertiaryMediumButton;
