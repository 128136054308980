/** @format */

import {useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Link, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {add, format} from 'date-fns';
import {gql, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

import BoxPlate from 'components/plates/BoxPlate';
import BoxPlateTitle from 'components/Dashboard/BoxPlateTitle';
import BoxPlateText from 'components/Dashboard/BoxPlateText';
import PlatformTabs from 'components/tabs/PlatformTabs';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import numberHelpers from 'helpers/numberHelpers';
import routerHelpers from 'helpers/routerHelpers';
import paymentHelpers from 'helpers/paymentHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useSidebarContext} from 'contexts/SidebarContext';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($date: Date!) {
    currentUser {
      currentCompany {
        stats(date: $date) {
          currency
          platform
          monthSpent
          state
        }
        lastCheckCollectDate
      }
      subscription {
        planId
      }
    }
  }
`;

const REFETCH_INTERVAL = 20000;

const useStyles = makeStyles(theme => ({
  companyUrl: {
    color: '#999A9A',
  },
  metricTitle: {
    color: '#8F8F8F',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  metricValue: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 'normal',
    lineHeight: '23px',
  },
  infoList: {
    color: '#8A877D',
  },
  infoLink: {
    color: '#8A877D',
    whiteSpace: 'pre-line',
    textDecoration: 'underline',
  },
  footerText: {
    color: theme.palette.action.hover,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  footerLink: {
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
}));

const Wait = () => {
  const navigate = useNavigate();

  const {currentCompany} = useCompanyContext();
  const {companies} = useSidebarContext();

  const classes = useStyles();
  const [activePlatform, setActivePlatform] = useState(null);

  const {i18n} = useTranslation();

  const yesterday = add(new Date(), {days: -1});

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {date: format(yesterday, 'yyyy-MM-dd')},
  });

  useEffect(() => {
    const interval = setInterval(refetch, REFETCH_INTERVAL);
    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (!data) return;

    const {stats} = data.currentUser.currentCompany;
    const platform = stats.map(v => v.platform)[0];

    // Platform can be null again
    setActivePlatform(platform);
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {stats, lastCheckCollectDate} = data.currentUser.currentCompany;
  const {subscription} = data.currentUser;

  const isPlanExceeded = paymentHelpers.isPlanExceeded(
    currentCompany.id,
    companies,
    subscription.planId,
  );

  if (lastCheckCollectDate && !isPlanExceeded) {
    navigate('/home/main/status', {replace: true});
    return null;
  }

  const renderDashboard = () => {
    const platformStats = stats
      .filter(v => v.state === 'READY')
      .find(v => v.platform === activePlatform);

    if (!platformStats) return null;

    const allPlatforms = stats.map(v => v.platform);

    const handleChangePlatform = platform => {
      setActivePlatform(platform);
    };

    const currencyFormatter = numberHelpers.buildFormatter(
      platformStats.currency,
      i18n.language,
    );

    return (
      <Box mt={8}>
        <BoxPlate>
          <Box mt={-2}>
            <PlatformTabs
              activePlatform={activePlatform}
              allPlatforms={allPlatforms}
              onChange={handleChangePlatform}
            />
          </Box>

          <Box mt={6}>
            {platformStats.currency === 'mixed' && (
              <Typography>
                <Trans>
                  Подключенны аккаунты с разными валютами.
                  <br />
                  Для того чтобы увидеть статистику оставьте аккаунты только в
                  одной валюте.
                </Trans>
              </Typography>
            )}
            {platformStats.currency !== 'mixed' && (
              <>
                <Typography className={classes.metricTitle}>
                  <Trans>Траты за месяц</Trans>
                </Typography>
                <Box mt={2}>
                  <Typography className={classes.metricValue}>
                    {currencyFormatter.format(platformStats.monthSpent)}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </BoxPlate>

        {routerHelpers.IsRuVersion() && (
          <Box mt={4}>
            <Typography className={classes.footerText}>
              <Trans>Cуммы с учётом НДС</Trans>
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderInfo = () => {
    if (isPlanExceeded) {
      const handleChangePlan = () => {
        navigate('/home/settings/payment');
      };

      return (
        <Box mt={8}>
          <BoxPlate>
            <Box>
              <BoxPlateTitle>
                <Trans>Отчёт не доступен</Trans>
              </BoxPlateTitle>
            </Box>

            <Box mt={5}>
              <BoxPlateText>
                <Trans>
                  Отчёт и подробное описание рекомендаций сейчас не доступны,
                  необходимо повысить тариф.
                </Trans>
              </BoxPlateText>
            </Box>

            <Box mt={6}>
              <PrimaryMediumButton onClick={handleChangePlan}>
                <Trans>Выбрать тариф</Trans>
              </PrimaryMediumButton>
            </Box>
          </BoxPlate>
        </Box>
      );
    }

    return (
      <Box mt={8}>
        <BoxPlate>
          <Box display='flex'>
            <img
              alt='Search'
              height='24'
              src={new URL('./images/search.png', import.meta.url)}
              width='24'
            />

            <Box ml={3}>
              <BoxPlateTitle>
                <Trans>Мы уже начали проверку ваших рекламных кампаний</Trans>
              </BoxPlateTitle>
            </Box>
          </Box>

          <Box mt={5}>
            <BoxPlateText>
              <Trans>
                Первая проверка AdSensor самая длительная — она занимает от
                получаса до нескольких часов. Все последующие разы аудит будет
                обновляться в личном кабинете к 10 утра ежедневно.
              </Trans>
            </BoxPlateText>
          </Box>
          <Box mt={5}>
            <BoxPlateText>
              <Trans>
                О готовности первого отчета мы сообщим вам смс-оповещением.
              </Trans>
            </BoxPlateText>
          </Box>
        </BoxPlate>

        <Box mt={4}>
          <BoxPlate>
            <Box display='flex'>
              <Box>
                <BoxPlateTitle>
                  <Trans>А пока вы ждете...</Trans>
                </BoxPlateTitle>
              </Box>
            </Box>
            <Box mt={5}>
              <BoxPlateText>
                <Trans>
                  Пока собирается отчёт, можете узнать про AdSensor: что
                  проверяет, как им пользоваться, истории успеха наших клиентов.
                </Trans>
              </BoxPlateText>
            </Box>
            <Box>
              <ul className={classes.infoList}>
                <li>
                  <Link
                    href='https://adsensor.ru/cases?utm_source=ad_sensor&utm_content=wait'
                    target='_blank'
                    className={classes.infoLink}
                  >
                    <Trans>Истории успеха наших клиентов</Trans>
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://adsensor.ru/reviews?utm_source=ad_sensor&utm_content=wait'
                    target='_blank'
                    className={classes.infoLink}
                  >
                    <Trans>Отзывы об AdSensor</Trans>
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://adsensor.ru/sensors?utm_source=ad_sensor&utm_content=wait'
                    target='_blank'
                    className={classes.infoLink}
                  >
                    <Trans>О проверках AdSensor</Trans>
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://adsensor.ru/media?utm_source=ad_sensor&utm_content=wait'
                    target='_blank'
                    className={classes.infoLink}
                  >
                    <Trans>Наши публикации в прессе</Trans>
                  </Link>
                </li>
              </ul>
            </Box>
          </BoxPlate>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Typography variant='h1'>{currentCompany.name}</Typography>

      {renderDashboard()}
      {renderInfo()}
    </>
  );
};

export default Wait;
