/** @format */

import * as R from 'ramda';

export const capitalize = R.compose(R.join(''), R.over(R.lensIndex(0), R.toUpper));

export const snakeToCamel = value => {
  return value.replace(/_([a-z])/g, (_, letter) => {
    return letter.toUpperCase();
  });
};

export default {
  capitalize,
  snakeToCamel,
};
