/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import constants from 'helpers/constants';
import styles from './styles';

const IdText = props => {
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex'>
        <Typography className={clsx(props.classes.text, props.classes.title)}>
          ID
        </Typography>
        {constants.NBSP}
        <Typography className={clsx(props.classes.text, props.classes.value)}>
          {props.id}
        </Typography>
      </Box>

      {props.text && (
        <Typography
          className={clsx(props.classes.smallText, props.classes.value)}
        >
          {props.text}
        </Typography>
      )}
    </Box>
  );
};

export default withStyles(styles)(IdText);
