/** @format */

import {styled} from '@mui/material/styles';

import BaseTextField from './BaseTextField';

const PREFIX = 'MediumTextField';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBaseTextField = styled(BaseTextField)(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 8,
  width: '100%',
  '&:hover fieldset': {
    borderColor: theme.palette.action.hover,
  },
  '& fieldset': {
    borderColor: '#DADADA',
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary,
    height: 'unset',
  },
  '& .MuiInputBase-root': {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  '& input.MuiOutlinedInput-input': {
    padding: '14px 16px',
  },
}));

const MediumTextField = props => {
  return <StyledBaseTextField className={classes.root} {...props} />;
};

export default MediumTextField;
