/** @format */

import * as R from 'ramda';

// Elama uses same account and campaign links - it's the best we can do
const YANDEX_DIRECT_URL = 'https://direct.yandex.ru/dna/grid';
const GOOGLE_ADS_URL = 'https://ads.google.com/aw';

export const ACCOUNT_FIELD = 'ACCOUNT';
export const CAMPAIGN_FIELD = 'CAMPAIGN';
export const AD_GROUP_FIELD = 'AD_GROUP';
export const AD_FIELD = 'AD';
export const SITELINK_FIELD = 'SITELINK';
export const KEYWORD_FIELD = 'KEYWORD';
export const OBJECT_LINK_FIELD = 'OBJECT_LINK';
export const OBJECT_HOST_FIELD = 'OBJECT_HOST';

export const CAMPAIGNS_SECTION = 'CAMPAIGNS';
export const AD_GROUPS_SECTION = 'AD_GROUPS';
export const ADS_SECTION = 'ADS';
export const SITELINKS_SECTION = 'SITELINK';
export const KEYWORDS_SECTION = 'KEYWORDS';

const accountLink = (platform, accountId) => {
  if (platform === 'YANDEX') {
    // Opens account even if user is not logged in yet
    return `${YANDEX_DIRECT_URL}/campaigns?ulogin=${accountId}`;
  }

  if (platform === 'GOOGLE') {
    // Opens not managed account
    // Open manager account for managed account
    return `${GOOGLE_ADS_URL}/overview?__e=${accountId}`;
  }

  return null;
};

const campaignLink = (platform, accountId, campaignId) => {
  if (platform === 'YANDEX') {
    const query = `campaigns-ids=${campaignId}&ulogin=${accountId}`;
    // Opens campaign only when user is already logged in
    return `${YANDEX_DIRECT_URL}/groups?${query}`;
  }

  if (platform === 'GOOGLE') {
    // Opens campaign of not managed account
    // Opens manager account for campaign of managed account
    return `${GOOGLE_ADS_URL}/express/dashboard?campaignId=${campaignId}`;
  }

  return null;
};

const groupField = sensorName => {
  const mapping = {
    'as-404': OBJECT_LINK_FIELD,
    'as-account-limit': ACCOUNT_FIELD,
    'as-account-limit-exceeded': ACCOUNT_FIELD,
    'as-ad-title-nokey': AD_GROUP_FIELD,
    'as-conversion-dropped': ACCOUNT_FIELD,
    'as-counters': ACCOUNT_FIELD,
    'as-different-domains': ACCOUNT_FIELD,
    'as-high-imp-ratio': ACCOUNT_FIELD,
    'as-low-balance': ACCOUNT_FIELD,
    'as-low-imp': AD_GROUP_FIELD,
    'as-low-spend-cap': ACCOUNT_FIELD,
    'as-pixel': OBJECT_HOST_FIELD,
    'as-spent': ACCOUNT_FIELD,
    'as-url-struct': OBJECT_LINK_FIELD,
    'as-use-utm-adwords': OBJECT_LINK_FIELD,
    'as-utm': OBJECT_LINK_FIELD,
    'as-utm-in-link': OBJECT_LINK_FIELD,
    // 'as-utm-param-double': OBJECT_LINK_FIELD,
    'as-utm-presence': OBJECT_LINK_FIELD,
    'as-utm-settings': OBJECT_LINK_FIELD,
  };

  return mapping[sensorName] || CAMPAIGN_FIELD;
};

const groupFieldPath = sensorName => {
  const mapping = {
    [ACCOUNT_FIELD]: null,
    [CAMPAIGN_FIELD]: ['campaignId'],
    [AD_FIELD]: ['adId'],
    [KEYWORD_FIELD]: ['keywordId'],
    [OBJECT_LINK_FIELD]: ['objects', 'link', 'value'],
  };

  return mapping[groupField(sensorName)];
};

const locationSections = sensorName => {
  const mapping = {
    'as-404': [ADS_SECTION],
    'as-ad-double': [ADS_SECTION],
    'as-ad-has-image': [ADS_SECTION],
    'as-ad-header': [ADS_SECTION],
    'as-ad-high-bounce-rate': [ADS_SECTION],
    'as-ad-lim-links': [ADS_SECTION],
    'as-ad-lim-title': [ADS_SECTION],
    'as-ad-second-title': [ADS_SECTION],
    'as-ad-title-copy': [ADS_SECTION],
    'as-ad-title-nokey': [ADS_SECTION],
    'as-ad-vcard': [ADS_SECTION],
    'as-bad-keys': [KEYWORDS_SECTION],
    'as-clicks-dropped': [ADS_SECTION],
    'as-conversion-dropped': [ADS_SECTION],
    'as-fade': [ADS_SECTION],
    'as-geo': [AD_GROUPS_SECTION],
    'as-geo-card': [ADS_SECTION],
    'as-geo-domain': [ADS_SECTION, SITELINKS_SECTION],
    'as-geo-name-mismatch': [AD_GROUPS_SECTION],
    'as-geo-text': [ADS_SECTION, SITELINKS_SECTION],
    'as-geo-view-link': [ADS_SECTION],
    'as-high-imp-ratio': [CAMPAIGNS_SECTION],
    'as-impression-dropped': [ADS_SECTION],
    'as-key-high-bounce-rate': [KEYWORDS_SECTION],
    'as-low-imp': [AD_GROUPS_SECTION],
    'as-lowbid': [KEYWORDS_SECTION],
    'as-mod': [ADS_SECTION],
    'as-sale': [ADS_SECTION],
    'as-spend-key': [KEYWORDS_SECTION],
    'as-spent': [CAMPAIGNS_SECTION],
    'as-typo': [ADS_SECTION],
    'as-url-struct': [CAMPAIGNS_SECTION],
    'as-use-utm-adwords': [ADS_SECTION],
    'as-utm': [CAMPAIGNS_SECTION],
    'as-utm-in-link': [CAMPAIGNS_SECTION],
    'as-utm-param-double': [ADS_SECTION],
    'as-utm-presence': [CAMPAIGNS_SECTION],
    'as-utm-settings': [CAMPAIGNS_SECTION],
  };

  return mapping[sensorName] || [];
};

const groupReportItems = (sensorName, reportItems) => {
  const commonPaths = [['platform'], ['accountId']];
  const paths = [...commonPaths, groupFieldPath(sensorName)];

  return R.pipe(
    R.groupBy(R.paths(R.reject(R.isNil, paths))),
    R.values,
  )(reportItems);
};

const reportLink = (
  userUUID,
  uuid,
  lastCheckCollectDate,
  sensors,
  platforms,
  format,
  lang,
) => {
  return R.join('')([
    'https://yalper.adsensor.ru/as-api',
    `/user/${userUUID}`,
    `/client/${uuid}`,
    `/report/${lastCheckCollectDate}`,
    `?sensors=${sensors}&platforms=${platforms}&format=${format}&lang=${lang}&source=web_app`,
  ]);
};

export default {
  accountLink,
  campaignLink,
  groupField,
  locationSections,
  groupReportItems,
  reportLink,
};
