/** @format */
import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, LinearProgress, List, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useQuery, useMutation} from '@apollo/client';

import ModalPage from 'components/ModalPage';
import ShareLink from 'components/ShareLink';
import UserListItem from './UserListItem';
import {UserCompanyAccess} from 'types';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface usersCompany {
  access: UserCompanyAccess;
  user: {
    id: number;
    phone: string;
    profile: {
      name: string;
    };
  }
};

interface getCurrentUser {
  currentUser: {
    currentCompany: {
      usersCompanies: usersCompany[];
      id: number;
      name: string;
      uuid: string;
    };
    id: number;
    phone: string;
    profile: {
      name: string;
    };
  };
};

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        id
        name
        uuid
        usersCompanies {
          access
          user {
            id
            phone
            profile {
              name
            }
          }
        }
      }
      id
      phone
      profile {
        name
      }
    }
  }
`;

const REMOVE_USER_COMPANY = gql`
  mutation RemoveUserCompany($userId: ID!, $companyId: ID!) {
    RemoveUserCompany(userId: $userId, companyId: $companyId)
  }
`;

export const Share = () => {
  const {mixpanel} = useMixpanelContext();
  const {t} = useTranslation();

  const {loading, error, data, refetch} =
    useQuery<getCurrentUser>(GET_CURRENT_USER);
  const [removeUserCompany] = useMutation(REMOVE_USER_COMPANY);

  if (loading || !data) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {id: companyId, name, uuid, usersCompanies} = data.currentUser.currentCompany;

  const url = () => {
    return `${location.origin}/share/${uuid}`;
  };

  const handleCopy = () => {
    mixpanel.track('click_copy_share_url_button');
  };

  const {phone, profile} = data.currentUser;
  const {name: userName} = profile;

  const handleDelete = async (userId: number) => {
    await removeUserCompany({variables: {userId, companyId}});
    await refetch();
  };

  const otherCompanyUsers = R.pipe(
    R.filter<usersCompany>(v => v.user.id != data.currentUser.id),
    R.sortBy<usersCompany>(v => v.access)
  )(usersCompanies);

  return (
    <ModalPage
      header={t(
        'Предоставление доступа к отчету',
        'Предоставление доступа к отчету',
      )}
    >
      <Typography variant='body2'>
        <Trans>
          Вы можете предоставить доступ на просмотр отчёта в проекте
        </Trans>
      </Typography>
      <Box mt={5}>
        <ShareLink url={url()} variant='DARK' onCopy={handleCopy} />
      </Box>
      <Box mt={5}>
        <Typography variant='body2'>
          <Trans>Доступ к проекту "{{name}}"</Trans>
        </Typography>
      </Box>
      <Box mt={5}>
        <List>
          <UserListItem primary={`${userName} (Вы)`} secondary={phone} />
          {otherCompanyUsers &&
            otherCompanyUsers.map((userCompany, i) => (
              <UserListItem
                key={i}
                primary={userCompany.user.profile.name}
                secondary={userCompany.user.phone}
                onDelete={() => handleDelete(userCompany.user.id)}
              />
            ))}
        </List>
      </Box>
    </ModalPage>
  );
};
