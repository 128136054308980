/** @format */

import {useState} from 'react';

import {styled} from '@mui/material/styles';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {add, format} from 'date-fns';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GetAppIcon from '@mui/icons-material/GetApp';

import IssueTabsNew from 'components/tabs/IssueTabsNew';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import Breadcrumbs from 'components/Breadcrumbs';
import CheckGraph from 'components/graphs/CheckGraph';

import categoriesData from 'pages/Home/Main/Status/Issues/categories.json';

import checkHelpers from 'helpers/checkHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {sensorName} from 'helpers/sensor_names';

import type {Check, GraphCheck, SensorExclusion} from 'types';

import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useCompanyContext} from 'contexts/CompanyContext';

import {ROUTES} from 'constants/ROUTES';

import ExcludedRow from './ExcludedRow';
import NeedRegionsRow from './NeedRegionsRow';
import Row from './Row';
import {sortChecks} from './helper';

const PREFIX = 'CategoryNew';

const classes = {
  infoTitle: `${PREFIX}-infoTitle`,
  infoValue: `${PREFIX}-infoValue`,
  categoryIcon: `${PREFIX}-categoryIcon`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  downloadReportButton: `${PREFIX}-downloadReportButton`,
  categoryDescription: `${PREFIX}-categoryDescription`,
  categorySubtitle: `${PREFIX}-categorySubtitle`,
  infoPlate: `${PREFIX}-infoPlate`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.infoTitle}`]: {
    color: '#8F8F8F',
    fontSize: 14,
    fontWeight: 400,
  },

  [`& .${classes.infoValue}`]: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.categoryIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#202020',
  },

  [`& .${classes.categoryTitle}`]: {
    fontSize: '24px',
    fontWeight: 700,
  },

  [`& .${classes.downloadReportButton}`]: {
    padding: theme.spacing(4, 8),
    borderRadius: '8px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    '&:hover': {
      background: '#202020',
    },
  },

  [`& .${classes.categoryDescription}`]: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#202020',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },

  [`& .${classes.categorySubtitle}`]: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.225px',
  },

  [`& .${classes.infoPlate}`]: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    padding: theme.spacing(4, 0),
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginRight: 0,
      ['&:first-of-type']: {
        marginTop: 0,
      },
    },
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      currentCompany {
        sensorExclusions {
          id
          sensorName
        }
        graphSensorChecks(startDate: $startDate, endDate: $endDate) {
          new
          old
          fixed
          collectedOn
          sensorName
        }
        lastChecks {
          id
          checks
          cost
          found
          new
          fixed
          sensor {
            id
            name
            platform
            isError
          }
        }
        regions {
          id
          name
        }
      }
    }
  }
`;

const DELETE_SENSOR_EXCLUSION = gql`
  mutation DeleteSensorExclusion($id: ID!) {
    deleteSensorExclusion(id: $id)
  }
`;

type Tab = 'ALL' | 'IMPROVEMENTS' | 'RECOMMENDATIONS';

const combineByCollectedOn = (data: GraphCheck[]) => {
  const combinedData: {
    [key: string]: GraphCheck;
  } = {};

  data.forEach((item: GraphCheck) => {
    const {collectedOn, fixed, new: newValue, old} = item;

    if (!combinedData[collectedOn]) {
      combinedData[collectedOn] = {collectedOn, fixed: 0, new: 0, old: 0};
    }

    combinedData[collectedOn].fixed += fixed;
    combinedData[collectedOn].new += newValue;
    combinedData[collectedOn].old += old;
  });

  return Object.values(combinedData);
};

interface Category {
  name: string;
  title: string;
  description: string;
  sensors: string[];
}

const CategoryNew = () => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const categoryName = searchParams.get('name');

  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();
  const {currentCompany} = useCompanyContext();
  const [activeTab, setActiveTab] = useState<Tab>('ALL');

  const now = new Date();
  const startDate = add(now, {days: -6});
  const endDate = now;

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });
  const [deleteSensorExclusion, {loading: deleteSensorExclusionLoading}] =
    useMutation(DELETE_SENSOR_EXCLUSION);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const category = categoriesData.find(
    v => v.name === categoryName,
  ) as Category;

  const {sensorExclusions, lastChecks, regions, graphSensorChecks} =
    data.currentUser.currentCompany;

  const allChecks = R.pipe(
    checkHelpers.groupChecksByName,
    R.filter<Check>(v => category.sensors.includes(v.sensor.name)),
    R.filter<Check>(v => {
      if (activeTab === 'ALL') return true;
      return activeTab === 'IMPROVEMENTS'
        ? v.sensor.isError
        : !v.sensor.isError;
    }),
  )(lastChecks);

  const {
    validChecks,
    excludedChecks,
    needRegionsChecks,
  }: {
    validChecks: Check[];
    excludedChecks: Check[];
    needRegionsChecks: Check[];
  } = checkHelpers.groupedChecks(allChecks, sensorExclusions, regions);

  const renderInfoPlate = () => {
    const checksCount = R.sum(validChecks.map(v => v.checks));
    const foundCount = R.sum(validChecks.map(v => v.found));
    const newCount = R.sum(validChecks.map(v => v.new));

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          className={classes.infoPlate}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.infoTitle}>
            <Trans>Проверено объектов</Trans>
          </Typography>
          <Typography className={classes.infoValue}>{checksCount}</Typography>
        </Box>
        <Box
          className={classes.infoPlate}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.infoTitle}>
            <Trans>Рекомендации</Trans>
          </Typography>
          <Typography className={classes.infoValue}>{foundCount}</Typography>
        </Box>
        <Box
          className={classes.infoPlate}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.infoTitle}>
            <Trans>Новые</Trans>
          </Typography>
          <Typography className={classes.infoValue}>{newCount}</Typography>
        </Box>
      </Box>
    );
  };

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab as Tab);
  };

  const handleClickRow = (check: Check) => {
    // replaceAll is not available in Safari
    const snakeCaseName = check.sensor.name.replace(/-/g, '_');
    mixpanel.people.increment(`open_${snakeCaseName}_page_count`);
    mixpanel.track(`open_${snakeCaseName}_page`);
    mixpanel.track('open_sensor_page', {sensor_name: snakeCaseName});

    const link = checkHelpers.detailsPageLink(
      check.sensor.name,
      t(sensorName(check.sensor.name)),
    );
    navigate(link);
  };

  const handleClickDownload = () => {
    mixpanel.people.increment('open_partial_report_page_count');
    mixpanel.track('open_partial_report_page', {sensor: name});
  };

  const breadcrumbs = [
    {
      href: ROUTES.INDEX,
      title: <Trans>Проекты</Trans>,
    },
    {
      href: `/${ROUTES.HOME}`,
      title: <Trans>{currentCompany.name}</Trans>,
    },
    {
      href: '',
      title: <Trans>{t(category.title)}</Trans>,
    },
  ];

  const sensorsSearchParam = checkHelpers.getChecksNames(validChecks).join(',');

  const handleStartSensor = async (e: React.MouseEvent, v: Check) => {
    e.stopPropagation();

    const exclusionsId = sensorExclusions.find(
      (exclusion: SensorExclusion) => exclusion.sensorName === v.sensor.name,
    ).id;

    if (exclusionsId) {
      await deleteSensorExclusion({variables: {id: exclusionsId}});

      refetch();
    }
  };

  const combinedGraphChecksByCollectedOn =
    combineByCollectedOn(graphSensorChecks);

  return (
    <Root>
      <Box
        sx={{
          mt: 12,
          mb: 10,
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </Box>
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box className={classes.categoryIcon}>
            <AccountBalanceIcon fontSize='small' htmlColor='#fff' />
          </Box>
          <Typography className={classes.categoryTitle}>
            {t(category.title)}
          </Typography>
        </Box>
        <SecondaryMediumButton
          className={classes.downloadReportButton}
          component={RouterLink}
          to={{
            pathname: '/home/main/report',
            search: `?sensors=${sensorsSearchParam}`,
            state: {background: '/home/main/category'},
          }}
          onClick={handleClickDownload}
        >
          <GetAppIcon fontSize='small' htmlColor='#fff' />
          <Trans>Отчет</Trans>
        </SecondaryMediumButton>
      </Box>
      <Box
        sx={{
          mt: 6,
        }}
      >
        <Typography className={classes.categoryDescription}>
          {t(category.description)}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 14,
        }}
      >
        <Box
          className={classes.categorySubtitle}
          sx={{
            mb: 6,
          }}
        >
          <Trans>Статистика по категории</Trans>
        </Box>

        {renderInfoPlate()}

        <Box
          sx={{
            mt: 2,
          }}
        >
          <CheckGraph graphChecks={combinedGraphChecksByCollectedOn} />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 14,
        }}
      >
        <Box
          className={classes.categorySubtitle}
          sx={{
            mb: 6,
          }}
        >
          <Trans>Активные сенсоры</Trans>
        </Box>

        <IssueTabsNew activeTab={activeTab} onChange={handleChangeTab} />

        <Box
          sx={{
            mt: 6,
            mb: 6,
          }}
        >
          {sortChecks(validChecks, t).map((v, i) => (
            <Row key={i} check={v} onClick={handleClickRow} />
          ))}

          {sortChecks(needRegionsChecks, t).map((v, i) => (
            <NeedRegionsRow key={i} check={v} onClick={handleClickRow} />
          ))}
        </Box>
      </Box>
      {excludedChecks.length ? (
        <Box
          sx={{
            mt: 14,
          }}
        >
          <Box
            className={classes.categorySubtitle}
            sx={{
              mb: 6,
            }}
          >
            <Trans>Остановленные сенсоры</Trans>
          </Box>

          <Box
            sx={{
              mt: 6,
              mb: 6,
            }}
          >
            {sortChecks(excludedChecks, t).map((v, i) => (
              <ExcludedRow
                key={i}
                check={v}
                onClick={handleClickRow}
                handleStartSensor={(e: React.MouseEvent) =>
                  handleStartSensor(e, v)
                }
                deleteSensorExclusionLoading={deleteSensorExclusionLoading}
              />
            ))}
          </Box>
        </Box>
      ) : null}
    </Root>
  );
};

export default CategoryNew;
