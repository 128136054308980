/** @format */

import clsx from 'clsx';
import {Box, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import CustomInfo from './CustomInfo';
import reportHelpers from 'helpers/reportHelpers';
import styles from './styles';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  comment: {
    color: '#C4C4C4',
    marginLeft: theme.spacing(2),
  },
}));

const Campaign = props => {
  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const sample = props.reportItemGroup[0];
  const showCustomInfo = sample.maxLocationField === 'campaignId';

  const handleClick = () => {
    mixpanel.track('click_campaign_link', {sensor: props.sensorName});
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex'>
        <Link
          href={reportHelpers.campaignLink(
            sample.platform,
            sample.accountId,
            sample.campaignId,
          )}
          target='_blank'
          onClick={handleClick}
        >
          <Typography
            className={clsx(
              props.classes.text,
              props.classes.value,
              props.classes.underline,
            )}
            display='inline'
          >
            {sample.campaign || sample.campaignId}
          </Typography>
        </Link>

        {props.comment && (
          <Typography className={clsx(props.classes.text, classes.comment)}>
            {props.comment}
          </Typography>
        )}
      </Box>

      {showCustomInfo && (
        <CustomInfo
          reportItemGroup={props.reportItemGroup}
          sensorName={props.sensorName}
        />
      )}
    </Box>
  );
};

Campaign.defaultProps = {
  comment: null,
};

export default withStyles(styles)(Campaign);
