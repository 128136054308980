/** @format */

import {useState} from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

import ArrowBack from 'components/navigation/ArrowBack';
import EmailFieldFilled from 'components/inputs/EmailFieldFilled';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';

const RESET_PASSWORD = gql`
  mutation ResetPasswordEmail($email: String!) {
    resetPasswordEmail(email: $email)
  }
`;

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      color: theme.palette.text.primary,
    },
    subtitle: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    link: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      textDecoration: 'underline',
    },
    sendButton: {
      color: '#fff',
      backgroundColor: 'rgb(12, 12, 12)',
      '&:hover': {
        backgroundColor: 'rgb(12, 12, 12)',
      },
    },
  }),
);

const ResetPasswordEmail = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');

  const [resetPassword, {loading}] = useMutation(RESET_PASSWORD);

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = async () => {
    try {
      // NumberFormat adds trailing spaces
      await resetPassword({variables: {email: email.trim()}});
      navigate('/auth/reset-password/sent');
    } catch (e: any) {
      setResetPasswordError(e.message);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box mb={4}>
        <ArrowBack onClick={handleClickBack}>
          <Trans>Авторизация</Trans>
        </ArrowBack>
      </Box>
      <Box mt={4}>
        <Typography variant='h1' className={classes.text}>
          <Trans>Восстановление пароля</Trans>
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography className={classes.subtitle}>
          <Trans>
            Чтобы восстановить пароль, введите адрес электронной почты ниже и
            следуйте инструкциям по сбросу.
          </Trans>
        </Typography>
      </Box>

      <Box mt={5}>
        <EmailFieldFilled
          error={Boolean(resetPasswordError)}
          helperText={resetPasswordError}
          value={email}
          fullWidth
          onChange={handleChangeEmail}
        />
      </Box>

      <Box mt={4}>
        <PrimaryLargeButton
          color='primary'
          disabled={loading || !email}
          variant='contained'
          fullWidth
          endIcon={<ArrowForwardIcon />}
          className={classes.sendButton}
          onClick={handleResetPassword}
        >
          <Trans>Отправить</Trans>
        </PrimaryLargeButton>
      </Box>
    </>
  );
};

export default ResetPasswordEmail;
